import {requests} from '../../services';
import {BLOG_URL} from '../../utils/Endpoints';
import {BaseModel} from '../../model/shared/BaseModel';
import {Blog} from '../../model/blog/Blog';

export const BlogService = {
    getBlogs: (params: any): Promise<BaseModel<Blog>> => requests.get(`${BLOG_URL}`,params),
    editBlog: (id: number, blog: Blog): Promise<Blog> => requests.put(`${BLOG_URL}/${id}`, blog),
    getBlog: (id: number): Promise<Blog> => requests.get(`${BLOG_URL}/${id}`),
    createBlog: (blog: any): Promise<Blog> => requests.post(BLOG_URL, blog),
    deleteBlog: (id: number) => requests.delete(`${BLOG_URL}/${id}`)
}
