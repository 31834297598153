import React, {useEffect, useState} from 'react';
import {UserPlatformLogsFilter} from './UserPlatformLogsFilter';
import {UserService} from '../UserService';
import {UserPlatformsLogs} from '../../../model/user/UserPlatformsLogs';
import {Card, Table} from 'react-bootstrap';
import {formatDate} from '../../../utils/Functions';
import {Pagination} from '../../../shared/components/Pagination';
import {ImpersonateEye} from "../../../shared/components/ImpersonateEye";

export function UserPlatformLogsList() {
    const [change, setChange] = useState(false);
    const [logs, setLogs] = useState<UserPlatformsLogs[]>([]);
    const [totalPages, setTotalPages] = useState(0);
    const [state, setState] = useState({
        page: 1,
        perPage: 15,
        userId: '',
        platformId: '',
        from: '',
        to: '',
        handle: ''
    })
    useEffect(() => {
        UserService.getUserPlatformLogs(state)
            .then((data) => {
                setLogs(data.data);
                setTotalPages(Math.ceil(data.count / state.perPage))

            })
    }, [change])

    function handleClick() {
        setState({...state, page: 1})
        setChange(!change);
    }

    function handleChange(evt: React.ChangeEvent<HTMLInputElement>) {
        const {name, value} = evt.target;
        setState({
            ...state,
            [name]: value.replace(/^@/, '') // remove @ on first place
        });
    }

    const handlePages = (updatePage: number) => {
        setState({...state, page: updatePage});
        setChange(!change);
    };

    return (
        <>
            <Card className="mb-5 p-4">
                <UserPlatformLogsFilter
                    handleChanges={handleChange}
                    handleClick={handleClick}
                />
            </Card>

            <Card className="mb-5 p-4">
                <Table responsive className="table align-middle table-row-dashed fs-7 gy-5 dataTable no-footer">
                    <thead>
                    <tr className="even">
                        <th>UserID</th>
                        <th>Handle</th>
                        <th>Email</th>
                        <th>Followers</th>
                        <th>Platform</th>
                        <th>is Manual?</th>
                        <th className="text-right ">Date</th>
                    </tr>
                    </thead>
                    <tbody>
                    {logs?.map((item) => (
                        <tr key={item.id}>
                            <td>{item?.user?.id}</td>
                            <td>{item.title ?? '-'}</td>
                            <td> <ImpersonateEye id={item?.user?.id ?? 0}/>{item?.user?.email}</td>
                            <td>{item?.followers}</td>
                            <td>
                                <div className="d-flex align-items-center">
                                    <img width="30" height="30" src={item?.mainPlatform?.path} alt='main platform'/>
                                    <h6 className="mb-0 ml-2">
                                        {item?.mainPlatform?.name}
                                    </h6>
                                </div>
                            </td>
                            <td>{item?.manualUserInput.toString()}</td>
                            <td>
                                {formatDate(item.audienceDataUpdatedAt as string) ?? '-'}
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <div className="w-100">
                    {totalPages <= 1 ? '' :
                        <Pagination
                            page={state.page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    }
                </div>
            </Card>

        </>
    );
}
