import React from 'react'
import Autocomplete from 'react-autocomplete';
import { Button } from 'react-bootstrap'
import { FaTimes } from 'react-icons/fa';

interface IAutocompleteInput {
    placeholder?: string;
    label?: string;
    value: string;
    items: any;
    renderItems: string[];
    hasClearBtn?: boolean;
    setFilter: (...args: any) => void;
    onSelect: (...args: any) => void;
    clearFunc?: (...args: any) => void;
    style?: string;
    keyState: any;
    keyItemSelect: any;
    notRememberFilter?: boolean;
}

export default function AutocompleteInput({
                                            placeholder,
                                            label,
                                            value, 
                                            items, 
                                            hasClearBtn,
                                            keyState, 
                                            keyItemSelect,
                                            notRememberFilter,
                                            setFilter, 
                                            onSelect, 
                                            clearFunc, 
                                            renderItems,
                                            style
                                        }: IAutocompleteInput) {
  return (
     <div className={`autocomplete-input position-relative ${style}`}>
         {label && <label htmlFor={`autocomplete${label}`} className='d-block font-size-h6'>{label}</label>}
        <div className="row">
            <div className='col' id={`autocomplete${label}`}>
                <Autocomplete
                    getItemValue={(item) => item?.title ?? '-'}
                    value={value ?? ''}
                    items={items}
                    inputProps={{placeholder:placeholder ?? ''}}
                    renderItem={(item) =>
                        <div key={item.id} className='autocomplete-item'>
                            {renderItems.map((key: string) => {
                                return item[key] ? item[key]+ ' ' : "-" 
                                }
                            )}
                        </div>
                    }
                    onChange={(e) => setFilter(e.target.value)}
                    onSelect={(_, item) => {
                        onSelect((prev: any) => ({...prev, [keyState]: item[keyItemSelect]}))
                        if(!notRememberFilter) {
                            let name = renderItems.map((key: string) => {
                                    return !!item?.[key] ? item[key]+ ' ' : "-" 
                                }
                            )
                            setFilter(name.join(''))
                        }
                    }}
                />
            </div>
            {!!hasClearBtn && value && (
                <Button variant="" className='py-2 px-3 clearBtn btn-hover-bg-secondary' onClick={() => clearFunc?.()}>
                    <FaTimes fontSize={14}/>
                </Button>
            )}
        </div>
    </div>
  )
}
