import {requests} from '../../services';
import {
    CAMPAIGN_BUDGET_RANGE_URL,
    CAMPAIGN_COMPLETE_OFFERS,
    CAMPAIGN_GOAL_TOGGLE_URL,
    CAMPAIGN_GOALS_URL, CAMPAIGN_IS_PICKED_URL, CAMPAIGN_TYPE_TOGGLE_URL, CAMPAIGN_TYPES_URL,
    LIST_CAMPAIGN_URL, LIST_OFFER_URL,
    SUPERVISOR_URL,
} from '../../utils/Endpoints';
import {Campaign} from '../../model/campaign/Campaign';
import {BaseModel} from '../../model/shared/BaseModel';
import {ICampaignsGoals, ICreateCampaignGoal} from '../../model/campaign/ICampaignsGoals';
import {ICampaignsTypes, ICampaignType} from '../../model/campaign/ICampaignType';
import {ICampaignBudget, ICampaignsBudgetRanges} from '../../model/campaign/ICampaignsBudgetRanges';

export const CampaignsService = {
    getCampaigns: (params: any): Promise<BaseModel<Campaign>> => requests.get(`${LIST_CAMPAIGN_URL}`, params),
    editCharity: (id: number) => requests.put(`${LIST_CAMPAIGN_URL}/${id}`, {}),
    exportCampaigns: (id: number) => requests.get(`${LIST_CAMPAIGN_URL}/${id}/export`),
    getCampaignsGoals: (params: any): Promise<BaseModel<ICampaignsGoals>> => requests.get(`${CAMPAIGN_GOALS_URL}`, params),
    getCampaignGoal: (id: number): Promise<ICreateCampaignGoal> => requests.get(`${CAMPAIGN_GOALS_URL}/${id}`),
    campaignGoalActive: (id: number) => requests.put(`${CAMPAIGN_GOAL_TOGGLE_URL}/${id}`, {}),
    getCampaignsTypes: (params: any): Promise<BaseModel<ICampaignsTypes>> => requests.get(`${CAMPAIGN_TYPES_URL}`, params),
    getCampaignType: (id: number): Promise<ICampaignType> => requests.get(`${CAMPAIGN_TYPES_URL}/${id}`),
    campaignTypeActive: (id: number) => requests.put(`${CAMPAIGN_TYPE_TOGGLE_URL}/${id}`, {}),
    getBudgetsRanges: (params: any): Promise<BaseModel<ICampaignsBudgetRanges>> => requests.get(`${CAMPAIGN_BUDGET_RANGE_URL}`, params),
    getBudgetRange: (id: number): Promise<ICampaignBudget> => requests.get(`${CAMPAIGN_BUDGET_RANGE_URL}/${id}`, {}),
    createBudgetRange: (body: ICampaignBudget) => requests.post(CAMPAIGN_BUDGET_RANGE_URL, body),
    updateBudgetRange: (id: number, body: ICampaignBudget): Promise<ICampaignBudget> => requests.put(`${CAMPAIGN_BUDGET_RANGE_URL}/${id}`, body),
    deleteBudgetRange: (id: number) => requests.delete(`${CAMPAIGN_BUDGET_RANGE_URL}/${id}`),
    setIsPicked: (id: number) => requests.put(`${CAMPAIGN_IS_PICKED_URL}/${id}`, {}),
    rollbackExpiredOffers: (id: number) => requests.put(`${LIST_OFFER_URL}/command/negative/offers/rollback/${id}`, {}),
    transferCampaign: (campaignId: number, companyId: number) => requests.put(`${LIST_CAMPAIGN_URL}/transfer/${campaignId}/${companyId}`, {
        campaignId,
        companyId
    }),
    editCampaign: (campaignId: number, campaign: Partial<Campaign>) => requests.put(`${LIST_CAMPAIGN_URL}/edit/${campaignId}`, campaign),
    completeCampaign: (campaignId: number): Promise<any> => requests.put(`${CAMPAIGN_COMPLETE_OFFERS}/${campaignId}`, {}),
    getSupervisor: (params:any): Promise<any> => requests.get(`${SUPERVISOR_URL}`, params),
    assignSupervisor: (userId: number, campaignId: number): Promise<any> => requests.put(`${SUPERVISOR_URL}/${userId}/campaign/${campaignId}`, {}),
    deleteSupervisor: (userId: number, campaignId: number): Promise<any> => requests.delete(`${SUPERVISOR_URL}/${userId}/campaign/${campaignId}`),
    deleteClient: (userId: number, campaignId: number): Promise<any> => requests.delete(`${SUPERVISOR_URL}/client/${userId}/campaign/${campaignId}`),
}
