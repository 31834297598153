import React from 'react';
import {FaEye} from 'react-icons/all';
import {impersonationUsers} from '../../utils/Functions';
import {useSelector} from 'react-redux';
import {useHistory} from 'react-router-dom';

interface ImpersonateEyeProps {
    id: number;
    style?: string;
}
export function ImpersonateEye({id, style = 'btn-icon'}: ImpersonateEyeProps) {
    const {authToken} = useSelector((reduxState: any) => reduxState.auth);
    const history = useHistory()
    const currentRoute = history.location.pathname
    return(
        <button className={`btn ${style}`} onClick={() => impersonationUsers(authToken, id, currentRoute)}>
            <FaEye className={`cursor-pointer mx-2 `}/>
        </button>
    )
}
