import React from "react";
import {Route, Switch} from "react-router-dom";
import {ListAgency} from './components/ListAgency';
import {FormAgency} from "./components/FormAgency";
import {InfluencersAgency} from "./components/InfluencersAgency";
import {ListPendingEmails} from "./components/ListPendingEmails";
import {AgencyManagers} from "./components/AgencyManagers";
import {AdminAclEnum} from "../../enum/AdminAclEnum";
import {hasAccessRight} from "../../utils/Functions";

export function AgencyRoutes() {
    return (
        <Switch>
            {hasAccessRight(AdminAclEnum.AGENCY) &&
                <>
                    <Route exact path="/agency" component={ListAgency}/>
                    <Route path="/agency/edit" component={FormAgency}/>
                    <Route path="/agency/create" component={FormAgency}/>
                    <Route path="/agency/influencers/:id" component={InfluencersAgency}/>
                    <Route path="/agency/managers/:id" component={AgencyManagers}/>
                    <Route path="/agency/pending-emails" component={ListPendingEmails}/>
                </>
            }
        </Switch>
    );
}
