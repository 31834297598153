import {ErrorToast, SuccessToast} from "../utils/toasters";

export async function copyToClipboard(text: string) {
    try {
        await navigator.clipboard.writeText(text);
        SuccessToast('Successfully copied');
    } catch (e) {
        /* ❌ Failed to copy (insufficient permissions) */
        ErrorToast('Failed to copy');
    }
}
