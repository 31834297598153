import React, {useEffect, useState} from 'react';
import {Button, Form, Row} from 'react-bootstrap';
import {PlatformService} from '../../platform/PlatformService';
import {Platform} from '../../../model/platform/Platform';
interface IUserPlatformLogsFilterProps {
    handleChanges: (evt: React.ChangeEvent<HTMLInputElement>) => void;
    handleClick: () => void
}
export function UserPlatformLogsFilter({handleChanges, handleClick}: IUserPlatformLogsFilterProps) {
    const [platforms, setPlatform] = useState<Platform[]>([])
    useEffect(() => {
        PlatformService.getPlatforms()
            .then((data) => {
                setPlatform(data.data);
            })
    }, [])

    return (
        <Form>
            <Row style={{rowGap: '18px'}}>
                <div className="col-md-2 col-6">
                    <Form.Label>User id</Form.Label>
                    <Form.Control placeholder="Type id..." className="form-control-solid" name="userId" onChange={handleChanges}/>
                </div>
                <div className="col-md-2 col-6">
                    <Form.Label>Handle</Form.Label>
                    <Form.Control placeholder="Type handle..." className="form-control-solid" name="handle" onChange={handleChanges}/>
                </div>
                <div className="col-md-2 col-6">
                    <Form.Label>Platforms</Form.Label>
                    <Form.Control placeholder="Platforms" className="form-control-solid" name="platformId" onChange={handleChanges} as="select">
                        <option value="">All platforms</option>
                        {platforms.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                    </Form.Control>
                </div>
                <div className="col-md-2 col-6">
                    <Form.Label>Updated from</Form.Label>
                    <Form.Control type="date" placeholder="Updated from" className="form-control-solid" name="from" onChange={handleChanges}/>
                </div>
                <div className="col-md-2 col-6">
                    <Form.Label>Updated to</Form.Label>
                    <Form.Control type="date" placeholder="Updated to" className="form-control-solid" name="to" onChange={handleChanges}/>
                </div>
                <div className="col-md-2 col-6">
                    <Form.Label>Manual</Form.Label>
                    <Form.Control placeholder="Platforms" className="form-control-solid" name="manualUserInput" onChange={handleChanges} as="select">
                        <option value="undefined">All</option>
                        <option value="true">Manual</option>
                        <option value="false">Not Manual</option>
                    </Form.Control>
                </div>
                <div className="col-md-2 col-6">
                    <Form.Label>Modash failed</Form.Label>
                    <Form.Control placeholder="Modash" className="form-control-solid" name="hasModashFailed" onChange={handleChanges} as="select">
                        <option value="undefined">All</option>
                        <option value="true">Failed</option>
                        <option value="false">Succeed</option>
                    </Form.Control>
                </div>
            </Row>
            <Button className="mt-4" variant="primary" onClick={()=> handleClick()}>
                Search
            </Button>
        </Form>
    );
}
