import React, {useEffect, useState} from 'react';
import {Button, Col, Form, FormControl, FormLabel, Row} from 'react-bootstrap';
import {User} from '../../../model/user/User';
import {Campaign} from '../../../model/campaign/Campaign';
import {UserService} from '../../user/UserService';
import {toast} from 'react-toastify';
import {CampaignsService} from '../../campaigns/CampaignsService';
import {AmbassadorService} from '../AmbassadorService';
import {SUCCESSFULLY} from '../../../enum/Messages';
import AutocompleteSelect from '../../../shared/components/AutocompleteSelect';

export function FormReferredCampaign() {

    const [state, setState] = useState({
        page:1,
        perPage: 100000,
        title: "",
        displayName: "",
    })

    const [users,setUsers] = useState<User[]>([])
    const [campaign, setCampaign] = useState<Campaign[]>([])

    const [textUser,setTextUser] = useState("")
    const [textCampaign,setTextCampaign] = useState("")
    const [fee,setFee] = useState(0)

    useEffect(() => {
        UserService.getUsers(state)
            .then((data) => {
                setUsers(data.data);
            })
            .catch((err) => {
                toast.error(`${err.response.data.message}`)
            });
    }, []);

    useEffect(() => {
        CampaignsService.getCampaigns(state)
            .then((data) => {
                setCampaign(data.data);
            })
            .catch((err) => {
                toast.error(`${err.response.data.message}`)
            });
    }, []);

    function handleChange(evt: any) {
        const value = +evt.target.value;
        setFee(value)
    }

    function createCampaign(env: any) {
        env.preventDefault()
        AmbassadorService.addRefCampaign({user: +textUser, campaign: +textCampaign,fee: fee})
            .then((data)=>{
                toast(SUCCESSFULLY)
            }).catch((err) => {
                toast.error(`${err.response.data.message}`)
        });
    }

    return (
        <>
            <div className="card card-custom p-4">
                <Form.Group>
                    <Row>
                        <Col>
                            <AutocompleteSelect list={users} listKey={"firstName"} updateState={setTextUser} labelName={"User"}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <AutocompleteSelect list={campaign} listKey={"title"} updateState={setTextCampaign} labelName={"Campaign"}/>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormLabel>Fee</FormLabel>
                            <FormControl onChange={handleChange} name="fee"/>
                        </Col>
                    </Row>
                    
                    <Button className="mt-4" variant="primary" type="submit" onClick={createCampaign} >
                        Save
                    </Button>
                </Form.Group>
            </div>
        </>
    )
}