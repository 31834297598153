import React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";

interface ISupervisorFilter {
  handleChange: (...args: any) => void;
  setTriggerSupervisor: (...args: any) => void;
  setParams: (...args: any) => void;
}

export default function SupervisorFilter({ handleChange, setTriggerSupervisor, setParams }: ISupervisorFilter) {
  return (
    <Card onKeyDown={(e: any) => {
        if (e.key === "Enter") {
            setTriggerSupervisor((prev: boolean) => !prev);
            setParams((prev: any) => ({...prev, page: 1}))
        }
      }}>
      <Card.Body className="p-4">
        <Row>
          <Form.Group as={Col}>
            <Form.Control
              className="form-control form-control-solid mb-lg-0"
              placeholder="Firstname"
              type="text"
              name="name"
              onChange={(e) => handleChange(e)}
            />
          </Form.Group>
        </Row>

        <div>
          <Button onClick={() => {
                setTriggerSupervisor((prev: boolean) => !prev)
                setParams((prev: any) => ({...prev, page: 1}))
            }}>Search</Button>
        </div>
      </Card.Body>
    </Card>
  );
}
