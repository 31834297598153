import React, {useEffect, useState} from 'react';
import {Button, Table} from 'react-bootstrap';
import {useLocation} from 'react-router';
import {AgencyService} from '../AgencyService';
import {toast} from 'react-toastify';
import {IPendingEmails} from '../dto/IPandingEmails';
import {formatDate} from "../../../utils/Functions";
import {FaCheck, FaTimes} from "react-icons/fa";
import { useDispatch } from 'react-redux';
import generalSlice from '../../../store/general/GeneralSlice';

export function ListPendingEmails() {
    const [emails, setEmails] = useState<IPendingEmails[]>([]);
    const {state: {id}}: any = useLocation();
    const dispatch = useDispatch();

    useEffect(()=>{
        AgencyService.getPendingEmails(id).then(data=>{
            setEmails(data)
        }).catch((err) => {
            toast.error(`${err.response.data.message}`)
        });
    }, [])

    const deleteEmailRequest = (emailId: number) => {
        AgencyService.deletePendingEmails(emailId).then(data=>{
            setEmails(data);
            dispatch(generalSlice.actions.setConfirmModal({
                show: false,
            }));
            toast.success('Successfully deleted email');
        }).catch((err) => {
            toast.error(`${err.response.data.message}`)
        });
    }

    return(
        <>
            <div className="card card-custom mb-5 p-4">
                <Table hover responsive>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Requested date</th>
                        <th>Email</th>
                        <th>Exists</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {emails?.map((e)=>(
                        <tr key={e.id}>
                            <td>{e.id}</td>
                            <td>{formatDate(e.createdAt)}</td>
                            <td>{e.email}</td>
                            <td>{e?.isExisting ? <span className="word-break text-primary"><FaCheck/></span> :
                                <span className="word-break text-danger"><FaTimes/></span>}</td>
                            <td className='text-left'>
                                <Button onClick={() => {
                                     dispatch(generalSlice.actions.setConfirmModal({
                                        show: true,
                                        title: 'Agency communication',
                                        text: <div>Are you sure you want to remove this email?</div>,
                                        action: () => deleteEmailRequest(e.id),
                                    }));
                                }} variant="danger" size="sm">
                                    Delete email
                                </Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>
        </>
    );
}
