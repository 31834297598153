import React, {useState} from 'react';
import {useLocation} from 'react-router';
import {useParams, useHistory} from 'react-router-dom';
import {Button, Card, Col, Form, Row} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import {CampaignsService} from '../CampaignsService';
import {toast} from 'react-toastify';
import {UPDATED_CAMPAIGN} from '../../../enum/Messages';
import { getCorrectEnums } from '../../../utils/Functions';
import { Campaign } from '../../../model/campaign/Campaign';
import {calculateFee} from "../../../helpers/FeeCaluculation";

export function CampaignUpdateForm() {
    const history = useHistory();
    const {state}: any = useLocation();
    const {id} = useParams<any>();
    const [campaignData, setCampaignData] = useState<Partial<Campaign>>({
        approvalStatus: state?.approvalStatus,
        status: state?.status,
        feePercentage: +state?.feePercentage,
        isTransparentPricing: state?.isTransparentPricing
    });
    const {campaignStatus, CampaignValidationStatusEnum} = useSelector((state: any) => state.enums)

    function handleChange(evt: React.ChangeEvent<HTMLInputElement>) {
        const {name, value, checked} = evt.target;
        setCampaignData({
            ...campaignData,
            [name]: name === 'feePercentage' && (+value > 1 || +value < 0 )  ? 1 : value,
            isTransparentPricing: name === 'isTransparentPricing' ? checked : campaignData.isTransparentPricing
        });
    }

    const editCampaign = (e:  React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        CampaignsService.editCampaign(id, campaignData)
            .then((response) => {
                toast(UPDATED_CAMPAIGN);
                history.push('/campaign/list')   
            }).catch(err=>{
            toast.error(`${err.response.data.message}`)
        })
    }

    return (
        <Row>
            <Col xs={12} className="mb-3">
                <h3>Edit campaign: {state?.title}</h3>
            </Col>
            <Col>
                <Card>
                    <Card.Body>
                        <Form onSubmit={ (e) => editCampaign(e)}>
                            <Form.Row>
                                <Form.Group as={Col} className='col-4'>
                                    <Form.Label>Campaign status</Form.Label>

                                    <Form.Control placeholder="Campaign status" className="form-control-solid"
                                                    value={campaignData?.status}
                                                    name="status" onChange={handleChange} as="select">
                                        {/*<option value="">Campaign status</option>*/}
                                        {campaignStatus.map((item: string) => <option
                                            key={item}
                                            value={item}>{getCorrectEnums(item)}</option>)}

                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} className='col-4'>
                                    <Form.Label>Campaign approval status</Form.Label>
                                    <Form.Control placeholder="Campaign approval status"
                                                    className="form-control-solid"
                                                    value={campaignData?.approvalStatus}
                                                    name="approvalStatus" onChange={handleChange} as="select">
                                        {CampaignValidationStatusEnum.map((item: string) => <option
                                            key={item}
                                            value={item}>{getCorrectEnums(item)}</option>)}
                                    </Form.Control>
                                </Form.Group>
                                <Form.Group as={Col} className='col-4'>
                                    <Form.Label>Fee (0 - 1) Currently: {calculateFee(campaignData.feePercentage)}</Form.Label>
                                    <Form.Control type="number" step="0.01" min={0} max={1} name="feePercentage" onChange={handleChange} value={campaignData?.feePercentage ?? ""} />
                                </Form.Group>
                                <Form.Group as={Col} className='col-auto'>
                                    <Form.Check
                                        label='Transparent Pricing'
                                        type="checkbox"
                                        name='isTransparentPricing'
                                        defaultChecked={campaignData?.isTransparentPricing}
                                        data-toggle="toggle"
                                        onChange={handleChange}
                                    />
                                </Form.Group>
                            </Form.Row>
                            <Form.Row className="justify-content-end">
                                <Button size='lg' variant="primary" type="submit">Save</Button>
                            </Form.Row>
                        </Form>
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    )
}
