import React, {useState, useEffect} from 'react';
import {Button, Form, Row, Card} from 'react-bootstrap';
import AutocompleteSelect from '../../../shared/components/AutocompleteSelect';
import {toast} from 'react-toastify';
import { CampaignsService } from '../../campaigns/CampaignsService';
import { Campaign } from '../../../model/campaign/Campaign';
import { useSelector } from 'react-redux';
import { downloadFileRequest } from '../../../services';
import { LIST_OFFER_URL } from '../../../utils/Endpoints';
import { getCorrectEnums } from '../../../utils/Functions';

export interface IExportParams {
    campaignId: number | undefined,
    status: string,
}

export default function ExportOffers() {
    const [campaignId, setCampaignId] = useState(undefined);
    const [campaign, setCampaign] = useState<Campaign[]>([]);
    const [status, setStatus] = useState('');
    const {offerStatus} = useSelector((state:any)=> state.enums);

    useEffect(() => {
      const getCampaigns = async () => {
        try {
            const {data} = await CampaignsService.getCampaigns({perPage: 10000, isLongTerm: false});
            setCampaign(data);
        } catch (error) {
            toast.error(`${error}`);
        }
      }
      getCampaigns();
    }, [])
    
    function handleChanges(ev: any, ) {
        const {value} = ev.target;
        setStatus(value);
    }

    const doExport = async () => {
        try {
            downloadFileRequest(`${LIST_OFFER_URL}/export/period`,'xlsx', null, {campaignId: campaignId, status: status});
        } catch (error) {
            toast.error(`${error}`);
        }
    }

  return (
    <div>
         <Card className='mb-5'>
            <Card.Body className='p-4'>
                <Form>
                    <Row>
                        <div className="form-group col-md col-sm-12">
                            <AutocompleteSelect className='form-control-solid' list={campaign} listKey={"title"} updateState={setCampaignId} placeholder={"Campaign"}/>
                        </div>
                        <div  className="form-group col-md col-sm-12">
                            <Form.Control placeholder='Status' className='form-control-solid required' name="status" onChange={handleChanges} as="select" >
                                <option value="">All statuses</option>
                                {offerStatus.map((status: string) => <option key={status}>{getCorrectEnums(status)}</option>)}
                            </Form.Control>
                        </div>
                    </Row>
                    <Button className="mt-4" variant="primary" onClick={() => doExport()}>
                        Export
                    </Button>
                </Form>
            </Card.Body>       
        </Card>
    </div>
  )
}
