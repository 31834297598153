import React, {useEffect, useMemo, useState} from 'react';
import {Button, Card, Col, Form, OverlayTrigger, Table, Tooltip} from 'react-bootstrap';
import {Pagination} from '../../../shared/components/Pagination';
import {toast} from 'react-toastify';
import {Campaign} from '../../../model/campaign/Campaign';
import {User} from "../../../model/user/User";
import {ReportsService} from "../ReportsService";
import {IReportItem} from "../../../model/report/Report-item";
import {FaCheck, FaTimes} from "react-icons/fa";
import {FiInfo} from "react-icons/all";
import {formatDate} from "../../../utils/Functions";
import {ImpersonateEye} from "../../../shared/components/ImpersonateEye";
import {CampaignsService} from "../../campaigns/CampaignsService";
import {UserService} from "../../user/UserService";
import {NavLink} from "react-router-dom";
import { debounce } from 'lodash';
import Autocomplete from 'react-autocomplete';
import TableLayout from '../../../shared/layout/table/TableLayout';

export default function ReportsList() {
	const [pagination, setPagination] = useState({
		page: 1,
		perPage: 15,
		totalPages: 1,
	});
	const [reportList, setReportList] = useState<IReportItem[]>([]);
	const [triggerSearch, setTriggerSearch] = useState<boolean>(false);

	const [campaigns, setCampaigns] = useState<Campaign[]>([]);
	const [campaignId, setCampaignId] = useState('');

	const [users, setUsers] = useState<User[]>([]);
	const [userId, setUserId] = useState('');
    const [campaignsParams, setCampaignsParams] = useState('');
    const [usersParams, setUsersParams] = useState('');

	const getCampaigns = (title: string) => {
		CampaignsService.getCampaigns({ perPage: 500, title: title, isLongTerm: false })
			.then((data) => {
				setCampaigns(data.data);
			})
			.catch((err) => {
				toast.error(`${err.response.data.message}`);
			});
	}
	
	const getUsers = (displayName: string) => {		
		UserService.getUsers({ perPage: 500, userType: 'influencer', displayName: displayName })
			.then((data) => {
				setUsers(data.data);
			})
			.catch((err) => {
				toast.error(`${err.response.data.message}`);
			});
	}

	const campaignsDebounce = useMemo(() => debounce((value: string) => {
		getCampaigns(value);
	}, 300), []);

	const usersDebounce = useMemo(() => debounce((value: string) => {
        getUsers(value);
	}, 300), []);

	useEffect(() => {
		getCampaigns(campaignsParams);
		getUsers(usersParams);
	}, []);

	useEffect(() => {
		ReportsService.getReports({ ...pagination, campaignId: campaignId, userId: userId})
			.then((data) => {
				setReportList(data.data);
				setPagination((prev: any) => ({...prev, totalPages: Math.ceil(data.count / pagination.perPage)}));
			})
			.catch((err) => {
				toast.error(`${err?.response?.data?.message}`);
			});
	}, [pagination.page, triggerSearch, userId, campaignId]);

	// function handleCheckbox(id: number) {
	// 	ReportsService.toggleVerified(id);
	// 	const data = reportList.map((e) => {
	// 		if(e.id === id) return {...e, isVerified: !e.isVerified}
	// 		return e;
	// 	});
	// 	setReportList(data);
	// }

	const handlePages = (updatePage: number) => {
		setPagination({ ...pagination, page: updatePage });
	};

	function handleChange(evt: any) {
		const value = evt.target.value;
		setPagination({
			...pagination,
			[evt.target.name]: value,
		});
	}

	return (
		<div>
			<div className="card card-custom mb-5 p-4 mt-3" 
				 onKeyDown={(e) => {
					if (e.key === "Enter") setTriggerSearch(!triggerSearch);
				 }}
			>
				<Form.Row>
					<Form.Group as={Col} className='col-4 mb-0 autocomplete-input form-control-solid zindex-5 position-relative'>
						<Autocomplete
							items={campaigns}
							inputProps={{placeholder: 'Campaign title'}}
							getItemValue={(item) => item?.title ?? '-'}
							value={campaignsParams ?? ''}
							renderItem={(item) =>
								<div className='autocomplete-item' key={item.id}>
									{item?.title ?? '-'}
								</div>
							}
							onChange={(e) => {
								campaignsDebounce(e.target.value);
								setCampaignsParams(e.target.value)
								!e.target.value && setCampaignId('');
							}}
							onSelect={(_, campaign) => {
								setCampaignId(campaign?.id);
								setCampaignsParams(_);
								usersDebounce(_);
							}}
						/>
					</Form.Group>
					<Form.Group as={Col} className='mb-0 autocomplete-input form-control-solid zindex-5 position-relative'>
						<Autocomplete
							items={users}
							inputProps={{placeholder: 'Displayname'}}
							getItemValue={(item) => item?.displayName ?? '-'}
							value={usersParams ?? ''}
							renderItem={(item) =>
								<div className='autocomplete-item' key={item.id}>
									{item?.displayName ?? '-'}
								</div>
							}
							onChange={(e) => {
								usersDebounce(e.target.value);
								setUsersParams(e.target.value)
								!e.target.value && setUserId('');
							}}
							onSelect={(_, campaign) => {
								setUserId(campaign?.id);
								setUsersParams(_);
								usersDebounce(_);
							}}
						/>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Control placeholder="Additional" className="form-control-solid" name="isAdditional" onChange={handleChange} as="select">
							<option value="undefined">is Additional?</option>
							<option value="true">Additional</option>
							<option value="false">Not Additional</option>
						</Form.Control>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Control placeholder="Additional" className="form-control-solid" name="isVerified" onChange={handleChange} as="select">
							<option value="undefined">is Verified?</option>
							<option value="true">Verified</option>
							<option value="false">Not Verified</option>
						</Form.Control>
					</Form.Group>
				</Form.Row>
				<div>
					<Button
						type="submit"
						onClick={() => setTriggerSearch(!triggerSearch)}
					>
						Search
					</Button>
				</div>
			</div>
			<TableLayout pagination={pagination} handlePages={handlePages} listLength={reportList.length}>
				<Table hover responsive>
					<thead>
						<tr>
							<th>Report ID</th>
							<th>Date</th>
							<th>Campaign ID</th>
							<th>Offer ID</th>
							<th>Influencer ID</th>
							<th>Platform</th>
							<th>Screenshot</th>
							<th>is Verified?</th>
							<th>is Additional?</th>
							<th>Action</th>
						</tr>
					</thead>
					<tbody>
					{reportList.map((item) => (
						<tr key={item.id}>
							<td>{item?.id}</td>
							<td>{formatDate(item?.createdAt)}</td>
							<td>{item?.offer?.campaign?.id}
								<OverlayTrigger placement="top"
												overlay={<Tooltip
													id="campaign-tooltip">
													{item?.offer?.campaign?.title}
												</Tooltip>}>
									<FiInfo size={14} className="cursor-pointer ml-1"/>
								</OverlayTrigger>
							</td>
							<td>{item?.offer?.id}</td>
							<td><ImpersonateEye id={item?.offer?.user?.id}/>{item?.offer?.user?.displayName}</td>
							<td>                                {item?.socialPlatform ?
								<img alt='' src={item?.socialPlatform?.path} style={{width: 40, height: 40}}
									 className="rounded"/>
								: <img alt='' src="/images/placeholder.png" style={{width: 40, height: 40}}
									   className="rounded"/>}</td>
							<td>                                {item?.reportImages?.length ?
								<img alt='' src={item?.reportImages[0]?.reportPath} style={{width: 100, height: 60}}
									 className="rounded object-cover"/>
								: <img alt='' src="/images/placeholder.png" style={{width: 70, height: 60}}
									   className="rounded"/>}</td>
							<td>{item?.isVerified ?
								<span className="word-break text-primary"><FaCheck/></span> :
								<span className="word-break text-danger"><FaTimes/></span>}
							</td>
							<td>{item?.additionalPost ?
								<span className="word-break text-primary"><FaCheck/></span> :
								<span className="word-break text-danger"><FaTimes/></span>}
							</td>
							<td>
								<NavLink className="btn btn-primary" to={{
									pathname: '/reported/edit',
									state: {id: item.id}
								}}>Edit</NavLink>
							</td>
						</tr>
					))}
					</tbody>
				</Table>
			</TableLayout>
		</div>
	);
}
