import React from "react";
import {FaArrowUp} from "react-icons/all";

export function ScrollTop() {
  return (
      <div id="kt_scrolltop" className="scrolltop">
        <span className="svg-icon">
         <FaArrowUp className='text-white' />
        </span>{" "}
      </div>
  );
}
