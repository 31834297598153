import React, { useEffect, useState } from 'react';
import {
	ErrorToast,
	SuccessToast,
} from '../../../utils/toasters';
import { PackagesService } from '../PackagesService';
import { Button, Card, Col, Form, Modal } from 'react-bootstrap';
import { IPackages } from '../../../model/package/IPackage';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {calculateFee} from "../../../helpers/FeeCaluculation";

export default function OverrideForm() {
	const { id }: any = useParams();
	const history = useHistory();
	const {
		state: { package: companyPackage, packageOverride },
	}: any = useLocation();

	const [packageData, setPackageData] = useState<Partial<IPackages>>({});
	const [show, setShow] = useState<boolean>(false);


	useEffect(() => {
		if (!!companyPackage && !!packageOverride) {
			PackagesService.getOverride(packageOverride?.id)
				.then((response) => {
					setPackageData(response);
				})
				.catch((err) => ErrorToast(err));
		}
	}, []);

	useEffect(() => {
		if (!!companyPackage && !packageOverride) {
			setPackageData({fee: companyPackage.fee, monthlyPrice: companyPackage.monthlyPrice,
				annualPrice: companyPackage.annualPrice, nbEmployees: companyPackage.nbEmployees, allowBarterDeals:companyPackage.allowBarterDeals})
		}
	}, []);

	function handleChange(event: any) {
		const { value, name } = event.target;
		setPackageData({
			...packageData,
			[name]: name === 'fee' && (+value > 1 || +value < 0) ? 1 : value,
		});
	}

	const updateOverride = () => {
		PackagesService.putOverride(packageOverride?.id, packageData)
			.then((response) => {
				setPackageData(response);
				history.push('/company');
				SuccessToast('Successfully updated package');
			})
			.catch((err) => ErrorToast(err));
	};

	const createOverride = () => {
		PackagesService.postOverride(id, packageData)
			.then((response) => {
				setPackageData(response);
				history.push('/company');
				SuccessToast('Successfully added package');
			})
			.catch((err) => ErrorToast(err));
	};

	const deleteOverride = () => {
		PackagesService.deleteOverride(packageOverride?.id)
			.then((response) => {
				history.push('/company');
				SuccessToast('Successfully deleted package');
			})
			.catch((err) => ErrorToast(err));
	};

	return (
		<div>
			<Card>
				<Form as={Card.Body}>
					<h3>
						{!!companyPackage && !!packageOverride
							? 'Update override'
							: 'Create override'}
					</h3>
					<hr/>
					<Form.Row>
						<Col>
							<Form.Row>
								<Form.Group className="col-6">
									<Form.Label>
										Monthly Price (€)<span className="text-danger">*</span>
									</Form.Label>
									<Form.Control
										type="number"
										name="monthlyPrice"
										onChange={handleChange}
										defaultValue={packageData.monthlyPrice}
									/>
								</Form.Group>
								<Form.Group className="col-6">
									<Form.Label>
										Annual Price (€)<span className="text-danger">*</span>
									</Form.Label>
									<Form.Control
										type="number"
										name="annualPrice"
										onChange={handleChange}
										defaultValue={packageData.annualPrice}
									/>
								</Form.Group>
								<Form.Group className="col-6">
									<Form.Label>
										Employees<span className="text-danger">*</span>
									</Form.Label>
									<Form.Control
										type="number"
										name="nbEmployees"
										onChange={handleChange}
										defaultValue={packageData.nbEmployees}
									/>
								</Form.Group>
								<Form.Group className="col-6">
									<Form.Label>Fee<span className="text-danger">*</span> Currently: {calculateFee(packageData.fee)}</Form.Label>
									<Form.Control
										type="number"
										step="0.01"
										min={0}
										max={1}
										name="fee"
										onChange={handleChange}
										value={packageData.fee}
									/>
								</Form.Group>
								<Form.Group className='col-6'>
									<Form.Check label="Allow Barter Deals" type="checkbox" name="allowBarterDeals"
												onChange={(e:any) => setPackageData({...packageData, allowBarterDeals: e.target.checked})}
												checked={packageData.allowBarterDeals}/>
								</Form.Group>
								<Form.Group className='col-6'>
									<Form.Check
											label='Transparent Pricing'
											type="checkbox"
											name='isTransparentPricing'
											checked={packageData?.isTransparentPricing}
											data-toggle="toggle"
											onChange={(e:any) => setPackageData({...packageData, isTransparentPricing: e.target.checked}) }
										/>
								</Form.Group>
								<Form.Group className='col-6'>
									<Form.Check
										label='Team Collaboration'
										type="checkbox"
										name='teamCollaboration'
										checked={packageData?.teamCollaboration}
										data-toggle="toggle"
										onChange={(e:any) => setPackageData({...packageData, teamCollaboration: e.target.checked}) }
									/>

								</Form.Group>
								<Form.Group className='col-6'>
									<Form.Check
									label='Can Do Long-term'
									type="checkbox"
									name='canDoLongterm'
									checked={packageData?.canDoLongTerm}
									data-toggle="toggle"
									onChange={(e:any) => setPackageData({...packageData, canDoLongTerm: e.target.checked}) }
								/></Form.Group>
							</Form.Row>
						</Col>
					</Form.Row>
					<div>
						<Button
							onClick={
								!!companyPackage && !!packageOverride
									? updateOverride
									: createOverride
							}
							className="col-md-1 col-sm-12 "
							size="lg"
							variant="primary"
						>
							Save
						</Button>
						{!!companyPackage && !!packageOverride && (
							<Button
								onClick={() => setShow(true)}
								className="col-md-1 col-sm-12 float-right"
								size="lg"
								variant="danger"
							>
								Delete
							</Button>
						)}
					</div>
				</Form>
			</Card>

			<Modal
				show={show}
				onHide={() => setShow(false)}
				size="lg"
				aria-labelledby="contained-modal-title-vcenter"
				centered
			>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter">
						Are you sure you want to delete this override?
					</Modal.Title>
				</Modal.Header>
				<Modal.Footer>
					<Button onClick={deleteOverride}>Yes</Button>
					<Button variant="danger" onClick={() => setShow(false)}>
						Decline
					</Button>
				</Modal.Footer>
			</Modal>
		</div>
	);
}
