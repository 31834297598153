import React from "react";
import {Route, Switch} from "react-router-dom";
import AccessRight from "./components/AccessRight";
import FormAccessRight from "./components/FormAccessRight";
import {hasAccessRight} from "../../utils/Functions";
import {AdminAclEnum} from "../../enum/AdminAclEnum";

export function AccessRightRoutes() {
    return (
        <Switch>
            {hasAccessRight(AdminAclEnum.ACCESS_RIGHTS) &&
                <>
                    <Route exact path="/access-right" component={AccessRight}/>
                    <Route exact path="/access-right/form" component={FormAccessRight}/>
                    <Route exact path="/access-right/form/:id" component={FormAccessRight}/>
                </>
            }
        </Switch>
    );
}
