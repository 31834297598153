/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/Routes`).
 */

import React from "react";
import {Switch, Route} from "react-router-dom";
import {Layout} from "../shared/layout";
import Routes from "./routes";
import Logout from "../authentication/Logout";
import {ProtectedRoute} from "../authentication/ProtectedRoute";

export default function Index() {

    return (
        <Switch>
            <ProtectedRoute>
                <Layout>
                    <Routes/>
                </Layout>
            </ProtectedRoute>
            <Route path="/logout" component={Logout}/>
        </Switch>
    );
}

