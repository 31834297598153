import { IAccessRights } from '../../model/access-right/IAccessRight';
import {requests} from '../../services';
import {ACCESS_RIGHT_URL} from '../../utils/Endpoints';

export const AccessRightService = {
    getAccessRights: (params: {page:number, perPage: number}): Promise<{data:IAccessRights[], count: number}> => requests.get(`${ACCESS_RIGHT_URL}`, params),
    getAccessRight: (id: number) => requests.get(`${ACCESS_RIGHT_URL}/${id}`),
    putAccessRight: (id: number, params: Partial<IAccessRights>) => requests.put(`${ACCESS_RIGHT_URL}/${id}`, params),
    postAccessRight: (params: Partial<IAccessRights>) => requests.post(`${ACCESS_RIGHT_URL}`, params),
    deleteAccessRight: (id: number) => requests.delete(`${ACCESS_RIGHT_URL}/${id}`),
}
