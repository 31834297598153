import React, { useEffect, useState } from 'react'
import { Button, Modal } from "react-bootstrap";
import { BsTrash } from 'react-icons/bs';
import AutocompleteInput from '../../../shared/components/AutocompleteInput';
import { CompanyService } from '../CompanyService';
import { toast } from 'react-toastify';
import {Company} from "../../../model/company/Company";
import {getCorrectEnums} from "../../../utils/Functions";
import {ErrorToast, SuccessToast} from "../../../utils/toasters";
import { useDispatch } from 'react-redux';
import generalSlice from '../../../store/general/GeneralSlice';

interface IModalAdd{
    showModal: boolean,
    isClient: boolean | undefined,
    title: string,
    setModalData: (args: any) => void,
    companyId: number,
}
export default function ModalAdd({showModal, title, isClient, setModalData, companyId}: IModalAdd) {
    const dispatch = useDispatch();
    const [user, setUser] = useState({
        clientId: 0,
        supplierId: 0,
    });
    const [users, setUsers] = useState<{assignedCompanies: Company[], notAssignedCompanies: Company[]}>({
        assignedCompanies: [],
        notAssignedCompanies: []
    });
    const [filterUser, setFilterUser] = useState('');
    const [triggerUsers, setTriggerUser] = useState(false);

    useEffect(() => {
        CompanyService.getCompanyPartners({
            companyId: companyId,
            excludeClients: isClient,
            excludeSuppliers: !isClient,
            isSupplier: !isClient,
            search: filterUser,
            perPage: 15
        })
            .then((data) => {
                setUsers((prev: any) => ({...prev, notAssignedCompanies: data.data}));
            })
            .catch((err) => {
                toast.error(`${err?.response?.data?.message}`);
            });
    }, [showModal, companyId, filterUser, triggerUsers])

    useEffect(() => {
        CompanyService.getCompanyPartnersAssigned({
            companyId: companyId,
            type: !!isClient ? 'supplier' : 'client',
            perPage: 20
        })
            .then((data) => {
                setUsers((prev: any) => ({...prev, assignedCompanies: data.data}));
            })
            .catch((err) => {
                toast.error(`${err?.response?.data?.message}`);
            });
    }, [showModal, companyId, triggerUsers])
    
    useEffect(() => {
      if (!!user.clientId || !!user?.supplierId){
        dispatch(generalSlice.actions.setConfirmModal({
            show: true,
            text: `Are you sure you want to add ${isClient ? 'client' : 'supplier'}?`,
            action: postCompanyPartner,
        }));
      }
    }, [user])
    
    const postCompanyPartner = () => {
        if (!!user.clientId || !!user?.supplierId) {
            CompanyService.postCompanyPartner({
                clientId: !!isClient ? user.clientId : companyId, 
                supplierId: !!isClient ? companyId : user?.supplierId 
            })
            .then((data) => {
                setFilterUser('');
                setTriggerUser(!triggerUsers);
                dispatch(generalSlice.actions.setConfirmModal({
                    show: false,
                }));
            })
            .catch((err) => {
                toast.error(`${err?.response?.data?.message}`);
            });
        }
    };

    const removePartner = (clientId: number, supplierId: number) => {
        CompanyService.deleteCompanyPartner(clientId, supplierId)
        .then((data) => {
            setTriggerUser(!triggerUsers);
            dispatch(generalSlice.actions.setConfirmModal({
                show: false,
            }));
        })
        .catch((err) => {
            toast.error(`${err?.response?.data?.message}`);
        });
    }

    const toggleClientLight = (relationId: number) => {
        CompanyService.toggleClientLight(relationId)
            .then((data) => {
                SuccessToast('Successfully action')
            })
            .catch((err) => ErrorToast(err));
    }

  return (
    <Modal
        animation={false}
        show={showModal}
        onHide={() => {
            setModalData((prev: any) => ({ ...prev, showModal: false }))
            setUsers({assignedCompanies: [], notAssignedCompanies: []})
        }}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Add {!!isClient ? 'Client' : 'Supplier'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Choose {!!isClient ? 'client' : 'supplier'} for {title} company?</div>
          <div className="form-group col-md-6 col-sm-12 p-0 mt-2">
            <div className="autocomplete-input zindex-5 position-relative">
              <AutocompleteInput
                  placeholder={'Company display name or master email'}
                  onSelect={setUser}
                  setFilter={setFilterUser} 
                  keyState={!!isClient ? 'clientId' : 'supplierId'} 
                  keyItemSelect={'id'}
                  notRememberFilter 
                  value={filterUser} 
                  items={users?.notAssignedCompanies}
                  renderItems={['id','displayName']}
                  style='zindex-5'
              />
            </div>
          </div>
          <div className="scrollable-div">
            {users?.assignedCompanies?.map((company: Company) => {
                const brandMaster = company?.companyUsers?.find((u) => u.userType === 'brand_master');
                return (
                <div
                  key={company?.email}
                  className="d-flex border border-primary p-2 rounded mb-4"
                >
                  <div className="d-flex align-items-center">
                    <div className="mr-2">
                        {company?.profilePhoto?.thumbnail ? <img alt='' src={company?.profilePhoto?.thumbnail} style={{width: 35, height: 35}}/>
                            : <img alt='' src="/images/placeholder.png" style={{width: 35, height: 35}}/>}
                    </div>
                      <div className="d-flex flex-column">
                          <p className="mb-0 font-14 fw-bolder">
                              {company?.displayName}
                          </p>
                          <p className="mb-0 font-12">
                              <b>{brandMaster?.firstName} {brandMaster?.lastName}</b> {brandMaster?.email}
                          </p>
                      </div>
                  </div>
                    <div className="col d-flex align-items-center justify-content-end">
                        {!!isClient && <p className="mb-0 font-12 mr-2">
                            {company?.clientRelations?.[0].clientType ? getCorrectEnums(company?.clientRelations?.[0].clientType): 'light?'}
                        </p>}
                        {!!isClient && <input type="checkbox" className='form-check-input ml-2'
                               defaultChecked={!!company?.clientRelations?.[0]?.clientType}
                               data-toggle="toggle"
                               onChange={(event) => {
                                   toggleClientLight(company?.clientRelations?.[0]?.id)
                               }}
                        />}
                        <Button
                            className="ml-2"
                            variant='danger'
                            onClick={() => {
                                dispatch(generalSlice.actions.setConfirmModal({
                                    show: true,
                                    text: `Are you sure you want to delete ${isClient ? 'client' : 'supplier'}?`,
                                    action: () => removePartner(!!isClient ? company.id! : companyId, !!isClient ? companyId : company.id!)
                                }));                            
                            }}
                        >
                            <BsTrash/>
                        </Button>
                    </div>
                </div>
                );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
            <Button
                onClick={() => {
                    setModalData((prev: any) => ({...prev, showModal: false}))
                    setUsers({assignedCompanies: [], notAssignedCompanies: []})
                }}>
                Close
            </Button>
        </Modal.Footer>
      </Modal>
  )
}
