import React, { useEffect, useState } from 'react'
import { Button, Card, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { User } from '../../../model/user/User';
import { SupervisorsService } from '../SupervisorService';
import { toast } from 'react-toastify';
import { Pagination } from '../../../shared/components/Pagination';
import SupervisorFilter from './SupervisorFilter';
import {ImpersonateEye} from "../../../shared/components/ImpersonateEye";
import { SuccessToast } from '../../../utils/toasters';

export default function SupervisorsList() {
    const [params, setParams] = useState({
        page: 1, 
        perPage: 15,
    });
    const [totalPages, setTotalPages] = useState<number>(0);
    const [supervisors, setSupervisors] = useState<User[]>([]);
    const [triggerSupervisor, setTriggerSupervisor] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<{show: boolean, user: Partial<User>}>({
        show: false,
        user: {}
    });
    const [editData, setEditData] = useState({
        supervisorLink:''
    });
    
    useEffect(() => {
        SupervisorsService.getSupervisors(params)
            .then((data) => {
                setSupervisors(data.data);
                setTotalPages(Math.ceil(data.count / params.perPage));
            })
            .catch((err) => {
                toast.error(`${err?.response?.data?.message}`);
            });
    }, [params.page,triggerSupervisor])

    const handlePages = (updatePage: number) => {
		setParams({ ...params, page: updatePage });
	};

    const handleChange = (e: {target: HTMLInputElement}, isEditCalendly?: boolean) => {
        const {name, value} = e.target;
        if(!isEditCalendly) {
            setParams((prev) => ({...prev, [name]: value}))
        } else {
            setEditData((prev) => ({...prev, [name]: value}))
        }
    }

    const saveEditCalendly = () => {
        SupervisorsService.putCalendly(showModal?.user?.id!, editData)
        .then((data) => {
            SuccessToast('Successfully action');
            setTriggerSupervisor(!triggerSupervisor);
        })
        .catch((err) => {
            toast.error(`${err?.response?.data?.message}`);
        });
    }

  return (
    <div>
        <Modal
            show={showModal.show}
            onHide={() => setShowModal({ ...showModal, show: false })}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
                Edit Calendly
            </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row>
                    <Form.Group as={Col}>
                        <Form.Control
                        className="form-control form-control-solid mb-lg-0"
                        placeholder="Calendly"
                        type="text"
                        value={editData.supervisorLink}
                        name="supervisorLink"
                        onChange={(e: any) => handleChange(e, true)}
                        />
                    </Form.Group>
                </Row>
            </Modal.Body>
            <Modal.Footer>
            <Button onClick={() => setShowModal({ ...showModal, show: false })}>
                Close
            </Button>
            <Button onClick={() => {saveEditCalendly(); setShowModal({ ...showModal, show: false })}}>
                Save
            </Button>
            </Modal.Footer>
        </Modal>

        <SupervisorFilter handleChange={handleChange} setTriggerSupervisor={setTriggerSupervisor} setParams={setParams}/>
        
        <Card className="mb-5 p-4 mt-5">
            <Table hover responsive>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>User</th>
                        <th>Email</th>
                        <th>Calendly</th>
                        <th className='text-center'>Campaigns</th>
                    </tr>
                </thead>
                <tbody>
                    {supervisors?.map((item) => (
                        <tr key={item.id}>
                            <td>{item?.id}</td>
                            <td>
                                {item.firstName} {item.lastName}
                            </td>
                            <td><ImpersonateEye id={item.id ?? 0}/>{item.email}</td>
                            <td>
                                <Button className='mr-2' onClick={() => {setEditData({supervisorLink: item.supervisorLink || ''});setShowModal({show:true, user: item})}}>
                                    Edit
                                </Button>
                                {!!item.supervisorLink && (
                                    <a target='_blank' href={item.supervisorLink}>Calendly link</a>
                                )}
                            </td>
                            <td className='text-center'>{item.nbSupervisedCampaigns}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <div className="w-100">
                {totalPages > 1 && (
                    <Pagination
                        page={params.page}
                        totalPages={totalPages}
                        handlePagination={handlePages}
                    />
                )}
            </div>
        </Card>
    </div>
  )
}
