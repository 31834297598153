import React, {useEffect, useState} from 'react';
import {NavLink} from 'react-router-dom';
import {Button, Col, Dropdown, DropdownButton, Form, Table} from 'react-bootstrap';
import {FaCheck, FaTimes, FaUser} from 'react-icons/fa';
import {Pagination} from '../../../shared/components/Pagination';
import {IPlatformFilteringParams} from '../dto/IPlatformFilteringParams';
import {useHistory} from 'react-router';
import {PlatformItem} from '../../../model/platform/PlatformItem';
import {PlatformService} from '../PlatformService';
import {useSelector} from 'react-redux';
import {ErrorToast} from '../../../utils/toasters';
import {getCorrectEnums} from "../../../utils/Functions";

export function PlatformItemList() {

    let history = useHistory()

    const [change, setChange] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [platforms, setPlatform] = useState<PlatformItem[]>([])
    const [state, setState] = useState<IPlatformFilteringParams>({
        name: '',
        page: 1,
        perPage: 15,
        platform: '',
        type: ''
    })
    const {platformType} = useSelector((state: any) => state.enums)

    useEffect(() => {
        PlatformService.getPlatformItems(state)
            .then(data => {
                setPlatform(data.data)
                setTotalPages(Math.ceil(data.count / state.perPage))
            })
    }, [change, page])

    function disablePlatform(id: number) {
        PlatformService.disablePlatformItem(id)
            .then(data => {
                let allPlatforms = [...platforms]
                let found = allPlatforms.find((platfrom) => platfrom.id === id)
                const index = found ? allPlatforms.indexOf(found) : 0;
                allPlatforms[index] = data
                setPlatform(allPlatforms)
            }).catch((err) => ErrorToast(err));
    }

    function passData(route: string, data: any) {
        history.push({
            pathname: route,
            state: data
        })
    }

    const handlePages = (updatePage: number) => {
        setState({...state, page: updatePage})
        setPage(updatePage)
    };

    function handleChange(evt: any) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
    }

    function handleClick(env: any) {
        env.preventDefault()
        setChange(!change)
    }

    return (
        <>
            <NavLink className="w-100" to={{
                pathname: '/platform/item/form',
                state: {id: undefined, isEdit: false}
            }}>
                <Button variant="outline-primary">+ Add platform item</Button>
            </NavLink>

            <div className="card card-custom mb-5 p-4 mt-3">
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Control placeholder='Platform' className='form-control-solid' name="platform" onChange={handleChange}/>
                    </Form.Group>

                    <Form.Group as={Col}>
                        <Form.Control placeholder='Name' className='form-control-solid' name="name" onChange={handleChange}/>
                    </Form.Group>

                    <Form.Group as={Col}>
                        <Form.Control className='form-control-solid' name="type" onChange={handleChange} as="select">
                            <option value="">All types</option>
                            {platformType?.map((type: string) => <option key={type} value={type}>{getCorrectEnums(type)}</option>)}
                        </Form.Control>
                    </Form.Group>
                </Form.Row>

                <div>
                    <Button type="submit" onClick={handleClick}>
                        Search
                    </Button>
                </div>
            </div>

            <div className="card card-custom mb-5 p-4">
                <Table hover responsive>
                    <thead>
                        <tr>
                            <th>Platform</th>
                            <th>Icon</th>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Enabled</th>
                            <th>Long Term</th>
                            <th>Live</th>
                            <th>Likes</th>
                            <th>Dislikes</th>
                            <th>Comments</th>
                            <th>Shares</th>
                        </tr>
                    </thead>
                    <tbody>
                    {platforms.map((platform) => (
                        <tr key={platform.id}>
                            <td>{platform.platform}</td>
                            <td> {platform.path ? <img alt='' src={platform.path} style={{width: 35, height: 35}}/>
                                : <img alt='' src="/images/placeholder.png" style={{width: 35, height: 35}}/>
                            }</td>
                            <td>{platform.name}</td>
                            <td>{getCorrectEnums(platform.type)}</td>
                            <td>{platform.isEnabled ? <span className="word-break text-primary"><FaCheck/></span> : <span className="word-break text-danger"><FaTimes/></span>}</td>
                            <td>{platform.isLongTerm ? <span className="word-break text-primary"><FaCheck/></span> : <span className="word-break text-danger"><FaTimes/></span>}</td>
                            <td>{platform.isLive ? <span className="word-break text-primary"><FaCheck/></span> : <span className="word-break text-danger"><FaTimes/></span>}</td>
                            <td>{platform.likes ? <span className="word-break text-primary"><FaCheck/></span> : <span className="word-break text-danger"><FaTimes/></span>}</td>
                            <td>{platform.dislikes ? <span className="word-break text-primary"><FaCheck/></span> : <span className="word-break text-danger"><FaTimes/></span>}</td>
                            <td>{platform.comments ? <span className="word-break text-primary"><FaCheck/></span> : <span className="word-break text-danger"><FaTimes/></span>}</td>
                            <td>{platform.shares ? <span className="word-break text-primary"><FaCheck/></span> : <span className="word-break text-danger"><FaTimes/></span>}</td>
                            <td>
                                <DropdownButton alignRight title={<FaUser/>} id="dropdown-menu-align-right">
                                    <Dropdown.Item eventKey="1" onClick={() => passData('/platform/item/form', {
                                        id: platform.id,
                                        isEdit: true
                                    })}>
                                        Edit
                                    </Dropdown.Item>
                                    <Dropdown.Divider/>
                                    <Dropdown.Item onClick={() => disablePlatform(platform.id ?? 0)}
                                                   eventKey="3">{platform.isEnabled ? 'Disable' : 'Enable'}</Dropdown.Item>
                                </DropdownButton>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <div className="w-100">
                    {totalPages > 1 &&
                        <Pagination
                            page={page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    }
                </div>
            </div>
        </>
    )
}
