import { createSlice } from '@reduxjs/toolkit'

const enumsSlice = createSlice({

    name: 'enums',
    initialState: {
        ambassadorInvoiceStatus: [],
        ambassadorStatus: [],
        audienceDimension: [],
        audienceMetricAge: [],
        audienceMetricGender: [],
        blogCategory: [],
        brandType: [],
        campaignAudience: [],
        campaignCategory: [],
        campaignPackage: [],
        campaignStatus: [],
        CampaignValidationStatusEnum: [],
        campaignType: [],
        cookieCategory: [],
        cookieName: [],
        cookieTheme: [],
        country: [],
        creditRequestStatus: [],
        demoStatus: [],
        externalSearchPlatform: [],
        gender: [],
        imagePurpose: [],
        invoicingDeliveryStatus: [],
        invoicingPaymentStatus: [],
        invoicingPaymentType: [],
        locale: [],
        offerStatus: [],
        percentage: [],
        platform: [],
        roles: [],
        superBlogCategory: [],
        useOfContent: [],
        userType: [],
        AdminAclEnum: []
    },

    reducers: {
        setEnums: (state, action) => {
            state = action.payload
            return state
        },
    },
});

export default enumsSlice
