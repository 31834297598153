import React from "react";
import {Route, Switch} from "react-router-dom";
import {hasAccessRight} from "../../utils/Functions";
import {AdminAclEnum} from "../../enum/AdminAclEnum";
import ReportsList from "./components/ReportsList";
import {ReportsForm} from "./components/ReportsForm";

export function ReportsRoutes() {
    return (
        <Switch>
            {hasAccessRight(AdminAclEnum.REPORT_ITEMS) &&<>
                <Route exact path="/reported" component={ReportsList}/>
                <Route exact path="/reported/edit" component={ReportsForm}/>
            </>
            }
        </Switch>
    );
}
