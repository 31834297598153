import React, {useEffect, useState} from 'react';
import {Button, Card, Table} from 'react-bootstrap';
import {Pagination} from '../../../shared/components/Pagination';
import {IReferredCampaign} from '../../../model/campaign/IReferredCampaign';
import {AmbassadorService} from '../AmbassadorService';
import {toast} from 'react-toastify';
import {FaEye} from 'react-icons/all';
import {useHistory} from 'react-router';

export function ReferredCampaign() {
    const history = useHistory();
    const [state, setState] = useState({
        page: 1,
        perPage: 15
    })

    const [campaign, setCampaign] = useState<IReferredCampaign[]>([])
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        AmbassadorService.getRefCampaigns(state)
            .then((data) => {
                setCampaign(data.data)
                setTotalPages(Math.ceil(data.count / state.perPage))
            }).catch((err) => {
            toast.error(`${err.response.data.message}`)
        });
    }, [state.page])

    const handlePages = (updatePage: number) => {
        setState({...state, page: updatePage})
    };

    return (
        <Card className="card card-custom mb-5 mt-4">
            <Card.Body className='p-4'>
                <Button variant="outline-primary" className="mb-3 col-sm-3 col-4" 
                        onClick={() => history.push(`/ambassador/campaigns/create`)}>
                    + Add referred campaign
                </Button>

                <div className="table-responsive">
                    <Table hover>
                        <thead>
                            <tr>
                                <th>Id</th>
                                <th>Title</th>
                                <th>Brand</th>
                                <th>Ambassador</th>
                                <th>Fee</th>
                                <th>Date</th>
                            </tr>
                        </thead>
                        <tbody>
                        {campaign?.map((e) => (
                            <tr key={e.id}>
                                <td>{e.id}</td>
                                <td>{e.campaign.title}</td>
                                <td>{e.campaign.brand.name}</td>
                                <td><FaEye color="lightblue"/> {e.user.displayName}</td>
                                <td>{e.fee}</td>
                                <td>{e.createdAt}</td>
                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </div>
                <div className="w-100">
                    {totalPages > 1 &&
                        <Pagination
                            page={state.page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    }
                </div>
            </Card.Body>
        </Card>
    )
}
