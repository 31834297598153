import React from "react";
import {Route, Switch} from "react-router-dom";
import InvoicesList from "./components/InvoicesList";
import {hasAccessRight} from "../../utils/Functions";
import {AdminAclEnum} from "../../enum/AdminAclEnum";

export function InvoiceRoutes() {
    return (
        <Switch>
            {hasAccessRight(AdminAclEnum.INVOICES) &&
                <Route exact path="/invoices" component={InvoicesList}/>
            }
        </Switch>
    );
}
