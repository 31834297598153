import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {IPlatformFilteringParams} from '../dto/IPlatformFilteringParams';
import {PlatformService} from '../PlatformService';
import {ErrorToast} from '../../../utils/toasters';
import {NavLink} from 'react-router-dom';
import {Button, Col, Form, Table} from 'react-bootstrap';
import {Pagination} from '../../../shared/components/Pagination';
import {Platform} from '../../../model/platform/Platform';

export function PlatformList() {
    let history = useHistory()
    const [change, setChange] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [platforms, setPlatform] = useState<Platform[]>([])
    const [state, setState] = useState<IPlatformFilteringParams>({
        name: '',
        page: 1,
        perPage: 15,
    })

    useEffect(() => {
        PlatformService.getPlatforms(state)
            .then((data) => {
                setPlatform(data.data);
                setTotalPages(Math.ceil(data.count / state.perPage));
            })
    }, [change, page])

    function disablePlatform(id: number) {
        PlatformService.disablePlatform(id)
            .then(data => {
                const updatedPlatforms = platforms.map((prev: Platform) => {
                    if (prev?.id === data.id) return data;        
                    return prev;        
                });
               setPlatform(updatedPlatforms);
            }).catch((err) => ErrorToast(err));
    }

    function passData(route: string, data: any) {
        history.push({
            pathname: route,
            state: data
        })
    }

    const handlePages = (updatePage: number) => {
        setState({...state, page: updatePage})
        setPage(updatePage)
    };

    function handleChange(evt: any) {
        const value = evt.target.value?.trim();
        setState({
            ...state,
            [evt.target.name]: value
        });
    }

    function handleClick(env: any) {
        env.preventDefault()
        setChange(!change)
    }

    function handleCheckbox(id: number) {
        PlatformService.hasApiToggle(id).then(data => {
            const updatedPlatforms = platforms.map((prev: Platform) => {
                if (prev?.id === data.id) return data;        
                return prev;        
            });
           setPlatform(updatedPlatforms);
        }).catch((err) => ErrorToast(err));
    }

    return (
        <>
            <NavLink className="w-100" to={{
                pathname: '/platform/form',
                state: {id: undefined, isEdit: false}
            }}>
                <Button variant="outline-primary"> + Add platform</Button>
            </NavLink>

            <div className="card mb-5 p-4 mt-3">
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Control className='form-control-solid' placeholder='Name' name="name" onChange={handleChange}/>
                    </Form.Group>
                </Form.Row>

                <div>
                    <Button type="submit" onClick={handleClick}>
                        Search
                    </Button>
                </div>
            </div>

            <div className="card mb-5 p-4">
                <Table hover responsive>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Icon</th>
                        <th>Name</th>
                        <th>Is enabled</th>
                        <th>Has api</th>
                        <th>Position</th>
                        <th>CPM</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {platforms.map((platform) => {
                        return (
                            <tr key={platform.id}>
                                <td>{platform.id}</td>
                                <td>
                                    {platform.path ? <img alt='' src={platform.path} style={{width: 35, height: 35}}/>
                                        : <img alt='' src="/images/placeholder.png" style={{width: 35, height: 35}}/>
                                    }
                                </td>
                                <td>{platform.name}</td>
                                <td>
                                    <div className="d-flex align-items-center ">
                                        <input className='form-check-input' type="checkbox" name="isEnabled"
                                               onChange={() => disablePlatform(platform.id ?? 0)}
                                               checked={platform.isEnabled}
                                               data-toggle="toggle"
                                        />
                                        <p className="m-0 pl-2">Enabled</p>
                                    </div>
                                </td>
                                <td>
                                    <div className="d-flex align-items-center ">
                                        <input className='form-check-input' type="checkbox" name="hasApi"
                                               onChange={(event) => {
                                                   handleCheckbox(platform.id)
                                               }}
                                               checked={platform.hasApi}
                                               data-toggle="toggle"
                                        />
                                        <p className="m-0 pl-2">Has Api</p>
                                    </div>
                                </td>
                                <td>{platform.position}</td>
                                <td>{platform.predefinedCPM}</td>
                                <td className='text-start'>
                                    <Button onClick={() => passData('/platform/form', {
                                        id: platform.id,
                                        isEdit: true
                                    })}>Edit</Button>
                                </td>
                            </tr>
                        )
                    })}
                    </tbody>
                </Table>
                <div className="w-100">
                    {totalPages <= 1 ? '' :
                        <Pagination
                            page={page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    }
                </div>
            </div>
        </>
    )
}
