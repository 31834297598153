export const BASE_URL =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_DEV_MODE
        : process.env.REACT_APP_PRO_MODE;

export const IMPERSONATE_LINK =
    process.env.NODE_ENV === 'development'
        ? process.env.REACT_APP_IV_DEV_MODE
        : process.env.REACT_APP_IV_PRO_MODE;

// Dashboard
export const DASHBOARD_URL = BASE_URL + 'admin/dashboard'

// Authentication
export const LOGIN_URL = BASE_URL + 'auth/login'
export const VERIFY_URL = BASE_URL + 'auth/2fa/verify'
export const RESET_CODE_URL = BASE_URL + 'auth/2fa/resend/code'

// Admin
export const LIST_USERS_URL = BASE_URL + 'admin/users'

// Supervisor
export const SUPERVISOR_URL = BASE_URL + 'admin/supervisor'

//Ambassador
export const ACCESS_RIGHT_URL = BASE_URL + 'admin/access-rights'

//Ambassador
export const LIST_AMBASSADORS_URL = BASE_URL + 'admin/ambassadors'

export const LIST_AMBASSADORS_INV_URL = BASE_URL + 'admin/ambassadors/invoices'

export const LIST_REFERRED_CAMP_URL = BASE_URL + 'admin/ambassadors/referred-campaigns'

// Enums
export const ENUMS_URL = BASE_URL + 'enums'

// Agency
export const LIST_AGENCY_URL = BASE_URL + 'admin/agencies'

// Blog
export const BLOG_URL = BASE_URL + 'admin/blogs'

// Campaign
export const LIST_CAMPAIGN_URL = BASE_URL + 'admin/campaigns'

export const CAMPAIGN_REVIEW_URL = BASE_URL + 'admin/campaigns/review'

export const CAMPAIGN_BUDGET_URL = BASE_URL + 'admin/campaigns/budget'

export const CAMPAIGN_BUDGET_DOWNLOAD_URL = BASE_URL + 'admin/campaigns/budget/download'

export const LIST_CAMPAIGN_EMAILING_URL = BASE_URL + 'admin/campaigns/emailing'

export const CAMPAIGN_GOALS_URL = BASE_URL + 'admin/campaigns/goal'

export const CAMPAIGN_GOAL_TOGGLE_URL = BASE_URL + 'admin/campaigns/goal/toggle'

export const CAMPAIGN_TYPES_URL = BASE_URL + 'admin/campaigns/creator-type'

export const CAMPAIGN_TYPE_TOGGLE_URL = BASE_URL + 'admin/campaigns/creator-type/toggle'

export const CAMPAIGN_BUDGET_RANGE_URL = BASE_URL + 'admin/campaigns/budget-range'

export const CAMPAIGN_IS_PICKED_URL = BASE_URL + 'admin/campaigns/toggle/picked'

export const CAMPAIGN_COMPLETE_OFFERS = BASE_URL + 'admin/campaigns/complete/offers'

export const INFLUENCERS_DOWNLOAD_URL = BASE_URL + 'admin/users/influencers/download'

// Offer
export const LIST_OFFER_URL = BASE_URL + 'admin/offers'

// Contract
export const LIST_CONTRACT_URL = BASE_URL + 'admin/contracts'

// Invoicing
export const INVOICING_URL = BASE_URL + 'admin/invoicing'

// Onboarding
export const ONBOARDING_URL = BASE_URL + 'admin/users/onboarding'

// Packages
export const PACKAGES_URL = BASE_URL + 'admin/packages'

// Override
export const OVERRIDE_URL = BASE_URL + 'admin/override'

// Export
export const EXPORT_URL = BASE_URL + 'admin/offers'

// Reporting
export const REPORTING_URL = BASE_URL + 'admin/report'

// Statistics
export const STATISTICS_URL = BASE_URL + 'admin/statistics'

// Timeline
export const TIMELINE_URL = BASE_URL + 'admin/timeline'

// Partner
export const PARTNER_URL = BASE_URL + 'admin/partners'
export const PARTNER_CAMPAIGN_URL = BASE_URL + 'admin/partner-campaigns'

// Company
export const COMPANY_URL = BASE_URL + 'admin/company'

// Platform
export const PLATFORM_URL = BASE_URL + 'admin/platform'
export const PLATFORM_ITEM_URL = BASE_URL + 'admin/platform/item'


export const SOCIAL_COMMAND_URL = BASE_URL + 'social/command'

export const AUTH_ME = BASE_URL + 'auth/me';
