import React, {useEffect, useState} from 'react';
import {Button, Card, Col, Form, Image} from 'react-bootstrap';
import {PlatformItem} from '../../../model/platform/PlatformItem';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router';
import {PlatformService} from '../PlatformService';
import {ErrorToast, SuccessToast, WarningToast} from '../../../utils/toasters';
import {Platform} from '../../../model/platform/Platform';
import {initialPlatform} from '../dto/initialPlatform';
import {multipartFormData} from '../../../utils/api.service';
import {PLATFORM_ITEM_URL} from '../../../utils/Endpoints';
import {useHistory} from 'react-router-dom';

export function PlatformItemForm() {
    const {state: {id}, state: {isEdit}}: any = useLocation();
    const [mainPlatforms, setMainPlatforms] = useState<Platform[]>([]);
    const [selectedFileLight, setSelectedFileLight] = useState(null);
    const [selectedFileDark, setSelectedFileDark] = useState(null);
    const [imagePath, setImage] = useState({
        lightTheme: '',
        darkTheme: ''
    });
    const [platform, setPlatform] = useState<PlatformItem>({
        comments: false,
        createdAt: '',
        dislikes: false,
        hasAdvertising: false,
        icon: '',
        id: 0,
        isEnabled: false,
        isLive: false,
        isLongTerm: false,
        likes: false,
        socialPlatform: initialPlatform,
        mainPlatform: initialPlatform,
        name: '',
        platform: '',
        clicks: false,
        saves: false,
        reach: false,
        stickerEngagements: false,
        swipeUpLinks: false,
        views: false,
        watchTime: false,
        position: 0,
        shares: false,
        type: '',
        updatedAt: '',
        path: '',
        title: '',
        impressions: undefined,
        mobileIdentify: '',
    });
    let history = useHistory();

    const {platformType} = useSelector((state: any) => state.enums)

    useEffect(() => {
        if (isEdit) {
            PlatformService.getPlatformItem(id)
                .then((response) => {
                    setPlatform(response);
                    response.path && setImage({
                        lightTheme: response.path,
                        darkTheme: response.darkPath ?? '',
                    })
                }).catch(err => ErrorToast(err))
        }
    }, [])

    useEffect(() => {
        PlatformService.getPlatforms()
            .then(response => {
                setMainPlatforms(response.data)
            }).catch(err => ErrorToast(err))
    }, [])

    const createPlatform = () => {
        if (selectedFileDark !== null) {
            multipartFormData(selectedFileDark, `${PLATFORM_ITEM_URL}/dark/${platform?.id}`, JSON.stringify({}), 'post')
                .then(response => response).catch((err) => ErrorToast(err))
        }
        if ((platform.position !== null) && (platform.name !== '') && (selectedFileLight !== null)) {
            multipartFormData(selectedFileLight, PLATFORM_ITEM_URL, JSON.stringify(platform), 'post')
                .then(response => {
                    SuccessToast('Successfully created platform item')
                    history.push('/platform/item')
                }).catch((err) => ErrorToast(err))
        } else {
            WarningToast('Please fill out all mandatory fields');
        }
    }

    const updatePlatform = () => {
        if (!!selectedFileDark) {
            multipartFormData(selectedFileDark, `${PLATFORM_ITEM_URL}/dark/${id}`, JSON.stringify({}), 'post')
                .then(response => response).catch(err => ErrorToast(err))
        }
        multipartFormData(selectedFileLight, `${PLATFORM_ITEM_URL}/${id}`, JSON.stringify(platform))
            .then(response => {
                SuccessToast('Successfully updated platform item')
                history.push('/platform/item')
            }).catch(err => ErrorToast(err))
    }

    function handleChange(event: any, isMainPlatforms = false, isNumber = false) {
        const value = isNumber ? +event.target.value : event.target.value;
        if (isMainPlatforms) {
            setPlatform({
                ...platform,
                mainPlatform: JSON.parse(value)
            })
        } else {
            setPlatform({
                ...platform,
                [event.target.name]: value
            });
        }
    }

    function handleCheckbox(evt: any) {
        const change = evt.target.checked;
        setPlatform({
            ...platform,
            [evt.target.name]: change
        });
    }

    function handleFileInput(evt: any, isLight = false) {
        if (evt.target.files.length > 0) {
            if (isLight) {
                setSelectedFileLight(evt.target.files[0]);
            } else {
                setSelectedFileDark(evt.target.files[0]);
            }
            setImage(prev => ({
                ...prev,
                [isLight ? 'lightTheme' : 'darkTheme']: URL.createObjectURL(evt.target.files[0])
            }))
        }
    }

    return (
        <Card className="card-custom mb-5">
            <Form as={Card.Body}>
                <h3> {isEdit ? 'Update platform item' : 'Create platform item'}</h3>
                <hr/>
                <Form.Row>
                    <Col md={8}>
                        <Form.Row>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Platform</Form.Label>
                                    <Form.Control name="platform" onChange={handleChange}
                                                    defaultValue={platform?.platform}/>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control name="name" onChange={handleChange} defaultValue={platform?.name}/>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>

                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Type</Form.Label>
                                    <Form.Control defaultValue={platform?.type} name="type" onChange={handleChange}
                                                    as="select">
                                        <option></option>
                                        {platformType?.map((type: string) => <option key={type}
                                                                                        selected={type === platform.type ? true : false}>{type}</option>)}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Main platforms</Form.Label>
                                    <Form.Control name="mainPlatform" onChange={(e) => handleChange(e, true, false)}
                                                    as="select">
                                        <option></option>
                                        {mainPlatforms.map((platforms) =>
                                            <option value={JSON.stringify(platforms)} key={platforms.id}
                                                    selected={platforms.id === platform.mainPlatform.id ? true : false}>
                                                {platforms.name}
                                            </option>)}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                        <Form.Row>

                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Position</Form.Label>
                                    <Form.Control type="number" name="position"
                                                    onChange={(event) => handleChange(event, false, true)}
                                                    value={platform?.position}/>
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label>Mobile Identify</Form.Label>
                                    <Form.Control name="mobileIdentify"
                                                    onChange={(event) => handleChange(event, false)}
                                                    value={platform?.mobileIdentify}/>
                                </Form.Group>
                            </Col>
                            <Col md={12} className="d-flex justify-content-between w-100">
                                <Form.Group className="mt-3 " id="formGridCheckbox">
                                    <Form.Check name="hasAdvertising" onChange={handleCheckbox} type="checkbox"
                                                label="Advertising" checked={platform?.hasAdvertising}/>
                                </Form.Group>
                                <Form.Group className="mt-3" id="formGridCheckbox">
                                    <Form.Check name="isLongTerm" onChange={handleCheckbox} type="checkbox"
                                                label="Long Term" checked={platform?.isLongTerm}/>
                                </Form.Group>
                                <Form.Group className="mt-3" id="formGridCheckbox">
                                    <Form.Check name="isLive" onChange={handleCheckbox} type="checkbox"
                                                label="Live" checked={platform?.isLive}/>
                                </Form.Group>
                                <Form.Group className="mt-3" id="formGridCheckbox">
                                    <Form.Check name="isEnabled" onChange={handleCheckbox} type="checkbox"
                                                label="Enabled" checked={platform?.isEnabled}/>
                                </Form.Group>
                            </Col>
                            <Col md={12} className="d-flex justify-content-between w-100">
                                <Form.Group className="mt-3" id="formGridCheckbox">
                                    <Form.Check name="likes" onChange={handleCheckbox} type="checkbox"
                                                label="Likes" checked={platform?.likes}/>
                                </Form.Group>
                                <Form.Group className="mt-3" id="formGridCheckbox">
                                    <Form.Check name="dislikes" onChange={handleCheckbox} type="checkbox"
                                                label="Dislikes" checked={platform?.dislikes}/>
                                </Form.Group>
                                <Form.Group className="mt-3" id="formGridCheckbox">
                                    <Form.Check name="comments" onChange={handleCheckbox} type="checkbox"
                                                label="Comments" checked={platform?.comments}/>
                                </Form.Group>
                                <Form.Group className="mt-3" id="formGridCheckbox">
                                    <Form.Check name="shares" onChange={handleCheckbox} type="checkbox"
                                                label="Shares" checked={platform?.shares}/>
                                </Form.Group>
                            </Col>
                            <Col md={12} className="d-flex justify-content-between w-100">
                                <Form.Group className="mt-3 " id="formGridCheckbox">
                                    <Form.Check name="saves" onChange={handleCheckbox} type="checkbox"
                                                label="Saves" checked={platform?.saves}/>
                                </Form.Group>
                                <Form.Group className="mt-3" id="formGridCheckbox">
                                    <Form.Check name="swipeUpLinks" onChange={handleCheckbox} type="checkbox"
                                                label="Link Clicks" checked={platform?.swipeUpLinks}/>
                                </Form.Group>
                                <Form.Group className="mt-3" id="formGridCheckbox">
                                    <Form.Check name="views" onChange={handleCheckbox} type="checkbox"
                                                label="Views" checked={platform?.views}/>
                                </Form.Group>
                                <Form.Group className="mt-3" id="formGridCheckbox">
                                    <Form.Check name="clicks" onChange={handleCheckbox} type="checkbox"
                                                label="Clicks" checked={platform?.clicks}/>
                                </Form.Group>
                            </Col>
                            <Col md={12} className="d-flex justify-content-between w-100">
                                <Form.Group className="mt-3 " id="formGridCheckbox">
                                    <Form.Check name="reach" onChange={handleCheckbox} type="checkbox"
                                                label="Reach" checked={platform?.reach}/>
                                </Form.Group>
                                <Form.Group className="mt-3" id="formGridCheckbox">
                                    <Form.Check name="watchTime" onChange={handleCheckbox} type="checkbox"
                                                label="Watch Time" checked={platform?.watchTime}/>
                                </Form.Group>
                                <Form.Group className="mt-3" id="formGridCheckbox">
                                    <Form.Check name="stickerEngagements" onChange={handleCheckbox} type="checkbox"
                                                label="Sticker Engagements" checked={platform?.stickerEngagements}/>
                                </Form.Group>
                                <Form.Group className="mt-3 " id="formGridCheckbox">
                                    <Form.Check name="impressions" onChange={handleCheckbox} type="checkbox"
                                                label="Impressions" checked={platform?.impressions}/>
                                </Form.Group>
                            </Col>
                        </Form.Row>
                    </Col>
                    <Col md={4}>
                        <Form.Group>
                            <Form.Label>Upload photo Light theme</Form.Label>
                            <Form.Control accept=".svg, .jpg, .png " type="file"
                                            onChange={(e) => handleFileInput(e, true)}/>
                            {imagePath.lightTheme &&
                                <Image className="mt-3" width={300} height={250} alt={imagePath?.lightTheme}
                                        src={imagePath?.lightTheme}/>
                            }
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Upload photo dark theme</Form.Label>
                            <Form.Control accept=".svg, .jpg, .png " type="file"
                                            onChange={(e) => handleFileInput(e, false)}/>
                            {imagePath.darkTheme &&
                                <Image className="mt-3 bg-dark" width={300} height={250} alt={imagePath.darkTheme}
                                        src={imagePath.darkTheme}/>
                            }
                        </Form.Group>
                    </Col>
                </Form.Row>
                <Button className="col-md-1 col-sm-12 " onClick={isEdit ? updatePlatform : createPlatform}
                        size="lg"
                        variant="primary">
                    Save
                </Button>
            </Form>
        </Card>
    )
}
