import {IPlatformFilteringParams} from './dto/IPlatformFilteringParams';
import {BaseModel} from '../../model/shared/BaseModel';
import {PlatformItem} from '../../model/platform/PlatformItem';
import {requests} from '../../utils/api.service';
import {PLATFORM_ITEM_URL, PLATFORM_URL} from '../../utils/Endpoints';
import {Platform} from '../../model/platform/Platform';

export const PlatformService = {
    getPlatformItems: (params: IPlatformFilteringParams): Promise<BaseModel<PlatformItem>> => requests.get(`${PLATFORM_ITEM_URL}/all`, params),
    getPlatformItem: (id: number): Promise<PlatformItem> => requests.get(`${PLATFORM_ITEM_URL}/${id}`),
    createPlatformItem: (body: PlatformItem) => requests.post(PLATFORM_ITEM_URL, body),
    updatePlatformItem: (id: number, body: PlatformItem) => requests.put(`${PLATFORM_ITEM_URL}/${id}`, body),
    disablePlatformItem: (id: number): Promise<PlatformItem> => requests.put(`${PLATFORM_ITEM_URL}/${id}/toggle`, {}),
    getPlatforms: (params?: IPlatformFilteringParams): Promise<BaseModel<Platform>> => requests.get(PLATFORM_URL, params),
    getPlatform: (id: number): Promise<Platform> => requests.get(`${PLATFORM_URL}/${id}`),
    disablePlatform: (id: number): Promise<Platform> => requests.put(`${PLATFORM_URL}/${id}/toggle`, {}),
    hasApiToggle: (id: number): Promise<Platform> => requests.put(`${PLATFORM_URL}/${id}/has-api/toggle`, {}),
}