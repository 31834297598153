export const EMAILING_SUB = {
    german: "Neue Kampagne: {{campaign_name}}",
    english: "New campaign: {{campaign_name}}"
};

export const EMAILING_BODY = {
    german: "wir haben tolle Neuigkeiten für dich! - eine neue Kampagne wurde von {{company_name}} hochgeladen: {{campaign_name}}\n \n" +

        "Klingt spannend, oder? \n \n" +

        "Mehr Details erfährst du im Kampagnenbriefing. Log dich auf app.influencevision.com ein um direkt zur Kampagne zu gelangen.\n \n" +

        "{{company_name}} freut sich auf deine kreativen Ideen und dein Angebot bis zum {{application_deadline}}.\n \n",

    english: "we have great news for you! - a new campaign was launched by {{company_name}}:  {{campaign_name}}\n \n" +

        "Want to hear more details about it? Log in to app.influencevision.com and learn more in the briefing.\n \n" +

        "{{company_name}} is looking forward to your creative ideas and your offer until {{application_deadline}}.\n \n"
};

export const SUCCESSFULLY = "Successfully"
export const UPDATED_USER = "Successfully updated user"
export const UPDATED_REPORT_ITEM = "Successfully updated report"
export const UPDATED_CAMPAIGN = "Successfully updated campaign"
export const DELETED_BLOG = "Successfully deleted blog"
export const ADDED_BLOG = "Successfully added blog"
export const UPDATED_BLOG = "Successfully updated blog"
export const SENT_EMAIL = "email sent successfully"
export const UPDATED_OFFER = "Successfully updated offer"
export const ADDED_USER = "Successfully added user"
export const AMBASSADOR_APPROVE = "Successfully approve ambassador"
export const AMBASSADOR_PAID = "Successfully paid ambassador"
export const AMBASSADOR_REJECT = "Successfully reject ambassador"
export const UPDATED_CONTRACT = "Successfully updated contract"

