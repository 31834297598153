import React, { useEffect, useState } from 'react';
import {
	Button,
	Col,
	Form,
	Table,
	Dropdown,
	DropdownButton,
} from 'react-bootstrap';
import { useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { Company } from '../../../model/company/Company';
import { CompanyService } from '../CompanyService';
import { LocalRoutes } from '../../../enum/LocalRoutes';
import { Pagination } from '../../../shared/components/Pagination';
import { formatDate, getCorrectEnums } from '../../../utils/Functions';
import { PackagesService } from '../../packages/PackagesService';
import { IPackages } from '../../../model/package/IPackage';
import { SuccessToast } from '../../../utils/toasters';
import ModalAdd from './ModalAdd';
import {calculateFee} from "../../../helpers/FeeCaluculation";
import { useDispatch } from 'react-redux';
import generalSlice from '../../../store/general/GeneralSlice';

export function CompanyList() {
	const dispatch = useDispatch();
	const [state, setState] = useState({
		page: 1,
		perPage: 15,
		displayNameOrId: '',
		email: '',
		explicit: true,
	});
	const history = useHistory();
	const [companies, setCompanies] = useState<Company[]>([]);
	const [packagesList, setPackagesList] = useState<IPackages[]>([]);
	const [change, setChange] = useState(false);
	const [totalPages, setTotalPages] = useState(0);
	const [modalData, setModalData] = useState({
		showModal: false,
		isClient: undefined,
		title: '',
		companyId: 0,
	});

	useEffect(() => {
		CompanyService.getCompanies(state)
			.then((data) => {
				setCompanies(data.data);
				setTotalPages(Math.ceil(data.count / state.perPage));
			})
			.catch((err) => {
				toast.error(`${err?.response?.data?.message}`);
			});

		PackagesService.getPackages({ page: 1, perPage: 99999999 })
			.then((data) => {
				setPackagesList(data.data);
			})
			.catch((err) => {
				toast.error(`${err?.response?.data?.message}`);
			});
		return () => {};
	}, [change]);

	const handlePages = (updatePage: number) => {
		setState({ ...state, page: updatePage });
		setChange(!change);
	};

	function handleClick() {
		setState({ ...state, page: 1 });
		setChange(!change);
	}

	function handleChange(evt: any) {
		const value = evt.target.value.trim();
		setState({
			...state,
			[evt.target.name]: value,
		});
	}

	const changePackage = (packageId:number, companyId: number) => {
        PackagesService.changePackage(packageId, companyId)
			.then((data) => {
				SuccessToast('Successfully added package');
				setChange(!change);
			})
			.catch((err) => {
				toast.error(`${err?.response?.data?.message}`);
			});
    };

	const deleteCompany = (companyId: number) => {
		CompanyService.deleteCompany(companyId)
		  .then((data) => {
			SuccessToast('Successfully deleted company');
			dispatch(generalSlice.actions.setConfirmModal({
				show: false,
			}));
			setChange((prev: boolean) => !prev);
		  })
		  .catch((err) => {
			toast.error(`${err.response.data.message}`);
		  });
	};

	return (
		<>
			<ModalAdd {...modalData} setModalData={setModalData}/>
			
			<Button
				variant="outline-primary"
				className="mb-4 w-20"
				onClick={() =>
					history.push(`/company/create`, { id: undefined, isEdit: false })
				}
			>
				+ Add company
			</Button>

			<div className="card card-custom mb-5 p-4 mt-3" 
				onKeyDown={(e) => {
					if (e.key === "Enter") handleClick();
				}}
			>
				<Form.Row>
					<Form.Group as={Col}>
						<Form.Control
							className="form-control-solid"
							placeholder="Display name or Company ID"
							name="displayNameOrId"
							onChange={handleChange}
						/>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Control placeholder="Packages" className='form-control-solid' name="packageId"
									  onChange={handleChange} as="select">
							<option value=''>All Packages</option>
							<option value='1'>Starter</option>
							<option value='2'>Pro</option>
							<option value='3'>Full Service</option>
							<option value='4'>Client</option>
							<option value='5'>Agency</option>
						</Form.Control>
					</Form.Group>
				</Form.Row>
				<div>
					<Button
						type="submit"
						onClick={() => handleClick()}
					>
						Search
					</Button>
				</div>
			</div>

			<div className="card card-custom mb-5 p-4">
				<Table hover responsive>
					<thead>
						<tr>
							<th>ID</th>
							<th>Date</th>
							<th>Display name</th>
							<th>Fee</th>
							<th>Package</th>
							<th>Expiration</th>
							<th className='text-right'>Action</th>
						</tr>
					</thead>
					<tbody>
						{companies.map((e) => (
							<tr key={e.id}>
								<td>{e.id}</td>
								<td>{formatDate(e.createdAt)}</td>
								<td className='word-break'>{e?.displayName}</td>
								<td>{e?.fee ? calculateFee(e?.fee) : ''}</td>
								<td>
									{!e.package && !e.packageOverride && (
										<DropdownButton title="Add" id="dropdown-menu-align-right">
											{packagesList?.map((item: IPackages, i: number) => {
												return (
													<Dropdown.Item key={i} eventKey="1" onClick={() =>changePackage(item.id, e.id)}>
														{getCorrectEnums(item?.name)}
													</Dropdown.Item>
												);
											})}
										</DropdownButton>
									)}
									{!!e.package && !e.packageOverride && (
										<DropdownButton title={getCorrectEnums(e.package?.displayName ?? '')}>
											{packagesList?.map((item: IPackages, i: number) => {
												return (
													<Dropdown.Item key={i} eventKey="1" onClick={() =>changePackage(item.id, e.id)}>
														{getCorrectEnums(item?.displayName)}
													</Dropdown.Item>
												);
											})}
											<Dropdown.Divider />
											<Dropdown.Item
												onClick={() =>
													history.push('/packages/override/' + e.id, {
														package: e?.package,
														packageOverride: e?.packageOverride,
													})
												}
												eventKey="3"
											>
												Override
											</Dropdown.Item>
										</DropdownButton>
									)}
									{!!e.package && !!e.packageOverride && (
										<Button
											className="mr-1"
											variant="primary"
											onClick={() =>
												history.push('/packages/override/' + e.id, {
													package: e?.package,
													packageOverride: e?.packageOverride,
												})
											}
										>
											Edit Override ({e?.package?.displayName})
										</Button>
									)}
								</td>
								<td>{e.packageExpiration ? formatDate(e.packageExpiration) : "-"}</td>
								<td>
									<DropdownButton title="Add" className='d-inline-block mr-1'>
										<Dropdown.Item className='mr-md-1' 
												onClick={() => setModalData((prev: any) => ({...prev, showModal: true, isClient: false, title: e.displayName, companyId: e.id})) }>
											Supplier
										</Dropdown.Item>
										<Dropdown.Item className='mr-md-1' 
												onClick={() => setModalData((prev: any) => ({...prev, showModal: true, isClient: true, title: e.displayName, companyId: e.id})) }>
											Client
										</Dropdown.Item>

									</DropdownButton>
									<DropdownButton title="Actions" className='d-inline-block mr-1'>
										<Dropdown.Item onClick={() =>
											history.push(LocalRoutes.COMPANY_EDIT, {
												id: e.id,
												isEdit: true,
											})
										}>
											Edit
										</Dropdown.Item>
										<Dropdown.Item onClick={() => history.push(`/company/users/${e.id}`)}>
											Users ({e.companyUsers?.length ?? 0})
										</Dropdown.Item>
										
										<Dropdown.Divider></Dropdown.Divider>

										<Dropdown.Item className='bg-danger-o-50' 
													onClick={() => {
														dispatch(generalSlice.actions.setConfirmModal({
															show: true,
															text: `Are you sure to delete ${e.displayName} company?`,
															action: () => deleteCompany(e.id)
														}));
													}}
										>
											Delete
										</Dropdown.Item>
									</DropdownButton>
								</td>
							</tr>
						))}
					</tbody>
					{companies.length < 15 && !!companies.length && (
						<div className='h-180px'></div>
					)}
				</Table>
				<div className="w-100">
					{totalPages > 1 && (
						<Pagination
							page={state.page}
							totalPages={totalPages}
							handlePagination={handlePages}
						/>
					)}
				</div>
			</div>
		</>
	);
}
