import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {NavLink} from 'react-router-dom';
import {Button, Table} from 'react-bootstrap';
import {Pagination} from '../../../shared/components/Pagination';
import {ICampaignsBudgetRanges} from '../../../model/campaign/ICampaignsBudgetRanges';
import {CampaignsService} from '../CampaignsService';
import {toast} from 'react-toastify';
import {ErrorToast, SuccessToast} from '../../../utils/toasters';
import {numberFormatter} from "../../../helpers/NumberFormater";


export function CampaignBudgetRange() {
    const history = useHistory();
    const [state, setState] = useState({
        page: 1,
        perPage: 20,
        title: '',
    })

    const [campaignBudget, setCampaignBudget] = useState<ICampaignsBudgetRanges[]>([])
    const [change, setChange] = useState(false)
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    const handlePages = (updatePage: number) => {
        setState({...state, page: updatePage})
        setPage(updatePage)
    };

    useEffect(() => {
        CampaignsService.getBudgetsRanges(state)
            .then((data) => {
                setCampaignBudget(data.data)
                setTotalPages(Math.ceil(data.count / state.perPage))
            })
            .catch((err) => {
                toast.error(`${err?.response?.data?.message}`)
            });
        return () => {
        };
    }, [page, change]);

    const deleteBudgetRange = (id: number) => {
        CampaignsService.deleteBudgetRange(id)
            .then((response) => {
                setChange(!change)
                SuccessToast('Successfully deleted budget range')
            }).catch(error => ErrorToast(error))
    }

    return (
        <>
            <NavLink to={{
                pathname: '/campaign/budget-range/form',
                state: {id: undefined, isEdit: false}
            }}>
                <Button variant="outline-primary">+ Add campaign budget range</Button>
            </NavLink>
            <div className="card card-custom mt-5 mb-5 p-4">
                <Table hover responsive>
                    <thead>
                    <tr>
                        <th>Platforms</th>
                        <th>Min price</th>
                        <th>Max price</th>
                        <th>Recommended min budget</th>
                        <th>Recommended max budget</th>
                        <th>Creator type</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    {campaignBudget?.map((budget) => (
                        <tr key={budget.id}>
                            <td>{budget.nbPlatforms}</td>
                            <td>{numberFormatter(budget.minPrice)}</td>
                            <td>{numberFormatter(budget.maxPrice)}</td>
                            <td>{numberFormatter(budget.recommendedMinBudget)}</td>
                            <td>{numberFormatter(budget.recommendedMaxBudget)}</td>
                            <td>{budget.creatorType.title}</td>
                            <td>
                                <Button className="mr-2" onClick={() => history.push(`/campaign/budget-range/form`, {
                                    id: budget.id,
                                    isEdit: true
                                })}>
                                    Edit
                                </Button>
                                <Button variant="danger"
                                        onClick={() => deleteBudgetRange(budget.id ?? 0)}>Delete</Button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <div className="w-100">
                    {totalPages <= 1 ? '' :
                        <Pagination
                            page={page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    }
                </div>

            </div>

        </>
    )
}
