import {requests} from '../../services';
import {LIST_CONTRACT_URL, LIST_OFFER_URL} from '../../utils/Endpoints';
import {BaseModel} from '../../model/shared/BaseModel';
import {Offer} from '../../model/offer/Offer';
import {Contract} from "../../model/contract/Contract";

export const ContractService = {
    getContracts: (params: any): Promise<BaseModel<Contract>> => requests.get(`${LIST_CONTRACT_URL}`, params),
    editContracts: (id: number, body: Contract): Promise<Contract> => requests.put(`${LIST_CONTRACT_URL}/${id}`, body),
}
