import {persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import {takeLatest} from "redux-saga/effects";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Verify: "[Verify] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
  SetUser: "[Set User] Action",
  GetUser: "[Get User] Action",
  SetUserACL: "[Set User Acl] Action"
};

const initialAuthState = {
  user: undefined,
  authToken: undefined,
  isVerified: false
};

export const reducer = persistReducer(
  { storage, key: "auth", whitelist: ["user", "authToken", 'isVerified'] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;

        return {...state, authToken };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }
      case actionTypes.Verify: {
        return { ...state, isVerified: true };
      }

      case actionTypes.Logout: {
        return initialAuthState;
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;
        return { ...state, user };
      }
      case actionTypes.GetUser: {

        return {...state}
      }

      case actionTypes.SetUser: {
        const { user } = action.payload;
        return { ...state, user };
      }
      case actionTypes.SetUserACL: {
        const { acl } = action.payload;
        return { ...state, user: {...state.user, adminAcl: acl} };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  verify: () => ({ type: actionTypes.Verify }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  getUser: () => ({
    type: actionTypes.GetUser
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
  setUser: (user) => ({ type: actionTypes.SetUser, payload: { user } }),
  setAcl: (aclArr) => ({ type: actionTypes.SetUserACL, payload: { aclArr } }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    // const authToken = yield put(actions.getUser());
    // yield put(actions.fulfillUser("admin"));
  });

  // yield takeLatest(actionTypes.Register, function* registerSaga() {
  //   yield put(actions.getUser());
  // });

  // yield takeLatest(actionTypes.UserRequested, function* userRequested() {
  //   const { data: user } = yield getUserByToken();
  //
  //   yield put(actions.fulfillUser(user));
  // });
}
