import React, {useEffect, useState} from 'react';
import {Form} from 'react-bootstrap';

interface IAutocompete {
    list: any[],
    listKey: string,
    updateState: (state: any) => void,
    labelName?: string,
    defaultValue?: string,
    className?: string,
    placeholder?: string,
}

export default function AutocompleteSelect({list, listKey, updateState, labelName, defaultValue, className, placeholder}: IAutocompete ) {
    
    const [suggestions, setSuggestions] = useState<any[]>([])
    const [state, setState] = useState('')

    useEffect(()=>{
        setState(defaultValue ?? '')
    },[defaultValue])

    const onChangeHandler = (text: string) => {
        let matches: any = []
        if (text?.length < 1){
            updateState('')
        }
        if (text?.length > 2) {
                matches = list.filter(item =>
                    !!item[listKey] && item[listKey].match(new RegExp(`${text}`, "gi"))
                )
        }
        setSuggestions(matches)
        setState(text)
    }

    const onSuggestHandler = (item: any) => {
        updateState(item.id)
        setState(item[listKey])
        setSuggestions([])
    }

    return(
        <>
            {labelName && <Form.Label>{labelName}</Form.Label>}
                <Form.Control type="text"
                              onChange={e => onChangeHandler(e.target.value)}
                              value={state}
                              placeholder={placeholder ?? ''}
                              className={className}
                />
                <div className="suggestion-wrapper">
                    {suggestions && suggestions.map((suggestion, i) =>
                        <div key={i} className="suggestion justify-content-md-center"
                             onClick={() => onSuggestHandler(suggestion)}>
                            {suggestion[listKey]}
                        </div>
                    )}
                </div>
        </>
    )
}