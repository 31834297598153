import React from 'react'
import { Card } from 'react-bootstrap'
import { FaListUl } from 'react-icons/fa'

interface IEmptyState {
    classHolder: string;
}

export default function EmptyState({classHolder}: IEmptyState) {
  return (
    <Card className={classHolder}>
        <Card.Body className='text-center'>
            <h1><FaListUl /></h1>
            <h6>Sorry, list is empty!</h6>
        </Card.Body>
    </Card>
  )
}
