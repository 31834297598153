import {Route, Switch} from "react-router-dom";
import React from "react";
import {Statistics} from "./Statistics";
import {AdminAclEnum} from "../../enum/AdminAclEnum";
import {hasAccessRight} from "../../utils/Functions";

export function StatisticsRoutes() {

    return (

        <Switch>
            {hasAccessRight(AdminAclEnum.STATISTICS) &&
                <Route exact path="/statistics" component={Statistics}/>
            }
        </Switch>

    )
}