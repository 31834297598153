import * as React from 'react';
import {NavLink} from 'react-router-dom';
import {checkIsActive} from '../../../../helpers';
import {useLocation} from 'react-router';

interface MenuItems {
    url: string,
    name: string,
    icon: React.ReactNode;
    isSubMenu?: boolean,
    children?: JSX.Element | JSX.Element[];
};

export function MenuItem({url, icon, name, isSubMenu, children}: MenuItems) {

    const location = useLocation();
    const getMenuItemActive = (url: any, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };

    return (
        <li role="menuitem" className={`menu-item ${getMenuItemActive(url, isSubMenu)}`} aria-haspopup="true" data-menu-toggle="hover">
            <NavLink className="menu-link menu-toggle" to={url}>
                <span className="svg-icon menu-icon">
                    {icon}
                </span>
                <span className="menu-text">
                    {name}
                </span>
                { isSubMenu ? <i className="menu-arrow"/> : <i className=""/> }
            </NavLink>
            {children}
        </li>
    );
};
