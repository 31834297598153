import React from 'react'
import {toast} from 'react-toastify';
import {Button, Modal} from "react-bootstrap";
import {SuccessToast} from '../../../utils/toasters';
import {AgencyService} from "../AgencyService";

interface IModalRemove{
    showModal: boolean,
    userName: string | undefined,
    setModalDeleteData: (args: any) => void,
    setChange: (args: any) => void,
    userId: number,
}
export default function InfluencerRemoveModal ({showModal, setModalDeleteData, userName, userId, setChange }:IModalRemove) {

    const removeInfluencer = () => {
		AgencyService.removeInfluencer(userId)
			.then((data) => {
				SuccessToast('Successfully removed influencer');
                setModalDeleteData((prev: any) => ({ ...prev, showModal: false }));
                setChange((prev: boolean) => !prev);
            })
			.catch((err) => {
				toast.error(`${err.response.data.message}`);
			});
	}

    return (
        <Modal
            show={showModal}
            onHide={() => {
                setModalDeleteData((prev: any) => ({ ...prev, showModal: false }))
            }}
            centered
            className='modal-dialog-centered'
            aria-labelledby="contained-modal-title-vcenter"
        >
        <Modal.Body>
            <h4>
                <b className='text-danger'>This action is permanent and will notify users!</b><br/>
                Are you sure you want to remove <b className='text-danger'>{userName ?? ''}</b> from agency?
            </h4>
        </Modal.Body>
            <Modal.Footer>
            <Button variant='danger' onClick={() =>{
                setModalDeleteData((prev: any) => ({ ...prev, showModal: false }))
            }}>
                No
          </Button>
          <Button onClick={() => removeInfluencer()}>Yes</Button>
        </Modal.Footer>
      </Modal>
    )
} 
