import {Button, Card, OverlayTrigger, Table, Tooltip} from 'react-bootstrap';
import React, {SetStateAction, useState} from 'react';
import {ICampaignReview} from '../../../model/campaign/ICampaignReview';
import {DashboardService} from '../DashboardService';
import {ErrorToast, SuccessToast} from '../../../utils/toasters';
import {ImpersonateEye} from '../../../shared/components/ImpersonateEye';
import {formatDate} from "../../../utils/Functions";
import {FiInfo} from "react-icons/all";
import {priceSplitter} from "../../../helpers/NumberFormater";
import { useDispatch } from 'react-redux';
import generalSlice from '../../../store/general/GeneralSlice';

export interface InCampaignReview {
    campaignReview: ICampaignReview[];
    setTriggerUpdate: React.Dispatch<SetStateAction<boolean>>
}

export function CampaignReview({campaignReview, setTriggerUpdate}: InCampaignReview) {
    const dispatch = useDispatch();
    const [declineReason, setDeclineReason] = useState({
        declineReason: ''
    })
    const handleSubmit = (campaignId: number, status: string) => {
        DashboardService.campaignApprovalAction(campaignId, {
            ...declineReason,
            approvalStatus: status
        }).then(response => {
            setTriggerUpdate(prev => !prev);
            SuccessToast('Success');
            dispatch(generalSlice.actions.setConfirmModal({
                show: false,
            }));
        }).catch(error => ErrorToast(error));
    }

    return (
        <Card className="p-4 mt-3">
            <h5 className='p-3'>Campaigns for review</h5>
            
            <Table hover responsive>
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Date</th>
                        <th>Brand</th>
                        <th>Email</th>
                        <th>Budget</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                {campaignReview?.sort((a, b) => b.id - a.id)?.map((campaign) => {
                    return (
                        <tr key={campaign?.id}>
                            <td>{campaign?.title}</td>
                            <td>{formatDate(campaign.publishedAt)}</td>
                            <td>
                                {campaign?.company?.profilePhoto?.thumbnail ? <img alt='' className='object-cover rounded' src={campaign?.company?.profilePhoto?.thumbnail} style={{width: 35, height: 35}}/>
                                    : <img alt='' src="/images/placeholder.png" style={{width: 35, height: 35}} />
                                }
                                <OverlayTrigger placement="top"
                                                overlay={<Tooltip
                                                    id="impressions-tooltip">
                                                    {campaign?.company?.displayName}
                                                </Tooltip>}>
                                    <FiInfo size={14} className="cursor-pointer ml-1"/>
                                </OverlayTrigger>
                            </td>
                            <td>
                                <ImpersonateEye id={campaign.user?.id ?? 0}/>
                                {campaign?.user?.email}
                            </td>
                            <td>{priceSplitter(campaign?.grossBudget)} €</td>
                            <td>
                                <span className={`status ${campaign?.status}`}>
                                    {campaign?.status === 'published' ? 'public' : campaign.status}
                                </span>
                            </td>
                            <td>
                                <Button className="mr-xl-2 mb-2 align-top"
                                        size="sm"
                                        onClick={() => {
                                            dispatch(generalSlice.actions.setConfirmModal({
                                                show: true,
                                                title: 'Campaign communication',
                                                text: <div>Are you sure that you want to approve this campaign?</div>,
                                                action: () => handleSubmit(campaign?.id, 'approved'),
                                            }));
                                        }}>Approve</Button>
                                <Button variant="danger"
                                        size="sm"
                                        onClick={() => {
                                            dispatch(generalSlice.actions.setConfirmModal({
                                                show: true,
                                                title: 'Campaign communication',
                                                text: <div>Are you sure that you want to decline this campaign?</div>,
                                                action: () => handleSubmit(campaign?.id, 'rejected'),
                                            }));
                                        }}>Decline</Button>
                            </td>
                        </tr>
                    )
                })}
                </tbody>
            </Table>
        </Card>
    )
}
