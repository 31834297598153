import {requests} from '../../services';
import {CAMPAIGN_REVIEW_URL, DASHBOARD_URL} from '../../utils/Endpoints';
import {IDashboard} from "./dto/iDashboard";
import {ICampaignReview} from "../../model/campaign/ICampaignReview";

export const DashboardService = {
    getDashboard: (): Promise<IDashboard> => requests.get(DASHBOARD_URL),
    getCampaignApproval: (): Promise<ICampaignReview> => requests.get(CAMPAIGN_REVIEW_URL),
    campaignApprovalAction: (id: number, body: { approvalStatus: string, declineReason?: string }): Promise<ICampaignReview> => requests.put(`${CAMPAIGN_REVIEW_URL}/${id}`, body),
}
