import * as React from 'react';
import {NavLink} from 'react-router-dom';
import {checkIsActive} from '../../../../helpers';
import {useLocation} from 'react-router';

interface ISubMenuItem {
    url: string,
    name: string,
};

export function SubMenuItem({url, name}: ISubMenuItem) {

    const location = useLocation();
    const getMenuItemActive = (url: any, hasSubmenu = false) => {
        return checkIsActive(location, url)
            ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open `
            : '';
    };

    return (
        <div className="menu-submenu ">
            <i className="menu-arrow"/>
            <ul role="submenubar" className="menu-subnav">
                <li role="submenuitem" className={`menu-item ${getMenuItemActive(url)}`} aria-haspopup="true">
                    <NavLink className="menu-link menu-item-submenu" to={url}>
                        <span className="menu-bullet">
                            <span className="bullet bullet-dot"></span>
                        </span>
                       
                        <span className="menu-text">{name}</span>
                    </NavLink>
                </li>
            </ul>
        </div>
    );
};
