import {Platform} from '../../../model/platform/Platform';

export const initialPlatform: Platform = {
    createdAt: '',
    icon: '',
    id: 0,
    isEnabled: false,
    hasApi: false,
    name: '',
    position: 0,
    updatedAt: '',
    path: '',
    predefinedCPM: 0,
}
