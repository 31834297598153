import React from "react";
import {Route, Switch} from "react-router-dom";
import OnboardingRequestList from "./components/OnboardingRequestList";
import {hasAccessRight} from "../../utils/Functions";
import {AdminAclEnum} from "../../enum/AdminAclEnum";

export function OnboardingRequests() {
    return (
        <Switch>
            {hasAccessRight(AdminAclEnum.ONBOARDING) &&
                <Route exact path="/onboarding-requests" component={OnboardingRequestList}/>
            }
        </Switch>
    );
}
