import {LOGIN_URL, RESET_CODE_URL, VERIFY_URL} from "../../utils/Endpoints";
import {requests} from "../../services";

export function login(username, password) {
    return requests.post(LOGIN_URL, { username, password, isAdmin: true });
}
export async function verifyAccount(id, twoFactorToken) {
    return await requests.post(`${VERIFY_URL}/${id}`, { twoFactorToken});
}
export async function resetVerifyCode(email) {
    return await requests.put(`${RESET_CODE_URL}`, { email});
}
