import React, {useEffect, useState} from 'react';
import {MdDelete} from 'react-icons/all';
import {ErrorToast, SuccessToast} from '../../../utils/toasters';
import {UserService} from '../UserService';
import {Col, Row, Table} from 'react-bootstrap';
import {ImpersonateEye} from '../../../shared/components/ImpersonateEye';
import {UserDevices} from '../../../model/user/UserDevices';
import {useLocation} from 'react-router';
import {FaDesktop, FaMobile, FaRegCopy} from 'react-icons/fa';
import {copyToClipboard} from '../../../helpers/CopyToClipboard';
import {formatDate} from "../../../utils/Functions";

export function UserDeviceList() {
    const {state: {user}}: any = useLocation();
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const [state, setState] = useState<UserDevices[]>([]);
    
    useEffect(() => {
        UserService.getUserDevices(user.id)
            .then((data) => {
                setState(data);
            })
    }, [triggerUpdate])

    const handleDeleteDevice = async (deviceId: number) => {
        !!deviceId && await UserService.deleteUserDevices(deviceId).then(response => {
            if (response) {
                SuccessToast('Successfully deleted device');
                setTriggerUpdate(prev => !prev)
            }
        }).catch(err => ErrorToast(err));
    }
    return (
        <>
            <Row className="align-items-center mb-3">
                <Col className="col-auto">
                    <h3 className="m-0">{user?.displayName ? `ID: ${user.id} Name: ${user.displayName}` : `ID: ${user.id} Name: ${user.firstName} ${user.lastName}`}</h3>
                </Col>
                <Col className="col-auto">
                    <ImpersonateEye id={user.id} style='btn-icon btn-primary'/>
                </Col>
            </Row>

            <div className="card card-custom mb-5 p-4">
                <Table responsive className="table table-row-dashed fs-7 gy-5 dataTable no-footer">
                    <thead>
                    <tr className="even">
                        <th>Device Id</th>
                        <th>Brand</th>
                        <th>Model</th>
                        <th>Type</th>
                        <th>Token</th>
                        <th>Token Created</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {state?.map((item) => (
                        <tr key={item.id}>
                            <td>{item?.id}</td>
                            <td>{item.brand ?? '-'}</td>
                            <td>{item.model ?? 'N/A'}</td>
                            <td>{['mobile', 'smartphone', 'phone'].includes(item.type) ? <FaMobile/> :
                                <FaDesktop/>}</td>
                            <td>{item.token ?? 'N/A'}</td>
                            <td>{item.token ? formatDate(String(item.createdAt)) : 'N/A'}</td>
                            <td>
                                <div className="d-flex align-items-center">
                                    {item.token && <div style={{marginLeft: '-1.5rem', zIndex: 1}}
                                                        className="cursor-pointer"
                                                        onClick={() => copyToClipboard(item.token)}>
                                        <FaRegCopy className="text-muted" size={20}/>
                                    </div>}

                                    <MdDelete className="text-danger cursor-pointer ml-1" size={25}
                                              onClick={() => handleDeleteDevice(item?.id)}/>

                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
            </div>

        </>
    );
}
