import {IPassword, User} from '../../model/user/User';
import {requests} from '../../services';
import {AUTH_ME, LIST_AGENCY_URL, LIST_USERS_URL, SOCIAL_COMMAND_URL, SUPERVISOR_URL} from '../../utils/Endpoints';
import {BaseModel} from '../../model/shared/BaseModel';
import {ISocialCommand, PlatformItem} from '../../model/platform/PlatformItem';
import {UserPlatformsLogs} from '../../model/user/UserPlatformsLogs';
import {UserDevices} from '../../model/user/UserDevices';

export const UserService = {
    getPersonalInfo: (body: any): Promise<User> => requests.post(AUTH_ME, body),
    getUsers: (params?: any): Promise<BaseModel<User>> => requests.get(`${LIST_USERS_URL}`, params),
    getInfluencers: (params?: any): Promise<BaseModel<User>> => requests.get(`${LIST_USERS_URL}/only/influencers`, params),
    disableUser: (id: number): Promise<User> => requests.put(`${LIST_USERS_URL}/${id}/disable`, {}),
    editUser: (id: number, user: Partial<User>): Promise<User> => requests.put(`${LIST_USERS_URL}/${id}`, user),
    getUser: (id: number): Promise<User> => requests.get(`${LIST_USERS_URL}/${id}`),
    getUserPlatforms: (id: number): Promise<BaseModel<PlatformItem>> => requests.get(`${LIST_USERS_URL}/platforms/${id}`),
    changeHandleUserPlatforms: (id: number, data: {
        title: string
    }) => requests.put(`${LIST_USERS_URL}/user-platform/${id}`, data),
    disablePlatform: (id: number) => requests.put(`${LIST_USERS_URL}/platforms/toggle/${id}`, {}),
    deletePlatform: (id: number) => requests.delete(`${LIST_USERS_URL}/platforms/delete/${id}`),
    getAgencies: (params?: any): Promise<BaseModel<User>> => requests.get(LIST_AGENCY_URL, params),
    changePassword: (id: number, data: IPassword) => requests.put(`${LIST_USERS_URL}/password/${id}`, data),
    postReauthorize: (data: ISocialCommand) => requests.post(`${SOCIAL_COMMAND_URL}/${data.skip}/${data.take}/${data.userId}/${data.platformId}/${data.followers}/${data.content}`),
    getUserPlatformLogs: (params?: any): Promise<BaseModel<UserPlatformsLogs>> => requests.get(`${LIST_USERS_URL}/log/platforms`, params),
    suspendUser: (id: number): Promise<User> => requests.put(`${LIST_USERS_URL}/suspend/toggle/${id}`, {}),
    onboardUser: (id: number): Promise<User> => requests.put(`${LIST_USERS_URL}/is/onboarded/toggle/${id}`, {}),
    updateSupervisor: (id: number): Promise<User> => requests.put(`${SUPERVISOR_URL}/toggle/${id}`, {}),
    getUserDevices: (id: number | undefined): Promise<UserDevices[]> => requests.get(`${LIST_USERS_URL}/devices/${id}`),
    deleteUserDevices: (id: number | undefined): Promise<UserDevices[]> => requests.delete(`${LIST_USERS_URL}/devices/${id}`),
}
