import {requests} from '../../utils/api.service';
import {LIST_USERS_URL} from '../../utils/Endpoints';

export interface AdminAclRequest {
    adminAcl: string[]
}

export interface AdminAclResponse {
    adminAcl: string[]
    id: number;
}

export const AdminService = {
    editAdminAcl: (id: number, body: AdminAclRequest): Promise<AdminAclResponse> => requests.put(`${LIST_USERS_URL}/edit/user/admin/acl/${id}`, body)
}