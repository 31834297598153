import React from "react";
import {Route, Switch} from "react-router-dom";
import ExportOffers from "./export-offers/ExportOffers";
import {hasAccessRight} from "../../utils/Functions";
import {AdminAclEnum} from "../../enum/AdminAclEnum";

export function ExportRoutes(){
    return(
        <Switch>
            {hasAccessRight(AdminAclEnum.EXPORT) &&
                <Route exact path="/export/export-offers" component={ExportOffers}/>
            }
        </Switch>
    )
}