import React, {useEffect} from 'react';
import {useDispatch} from 'react-redux';
import { BrowserRouter } from "react-router-dom";
import Index from './app/';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import enumsSlice from './store/common/EnumsSlice';
import {EnumService} from './services/EnumService';
import {UserService} from './app/user/UserService';
import {ErrorToast} from './utils/toasters';
import ModalConfirm from './shared/components/ModalConfirm';

export default function App() {
    const dispatch = useDispatch();
    const token = localStorage.getItem('token');

    useEffect(()=> {
        if (token) {
            UserService.getPersonalInfo({token: token ?? ''})
                .then((data) => {
                    localStorage.setItem("adminAcl", String(data?.adminAcl));
                }).catch(error => ErrorToast(error));
        }
    }, [])

    useEffect(()=>{
        EnumService.getEnums().then((data) => {
            dispatch(enumsSlice.actions.setEnums( data));
        });
    },[])

    return (
        <BrowserRouter>
            <Index />
            <ToastContainer/>
            <ModalConfirm />
        </BrowserRouter>
    );
}
