import React, {ReactElement} from 'react';
import {OverlayTrigger as BootstrapOverlayTrigger, OverlayTriggerProps} from 'react-bootstrap';
import {Placement} from 'react-bootstrap/Overlay';

interface CustomOverlayTriggerProps extends OverlayTriggerProps {
    flip?: boolean;
    placement?: Placement;
    overlay: ReactElement;
    children: ReactElement;
}

const CustomOverlayTrigger: React.FC<CustomOverlayTriggerProps> = ({
                                                                       flip = true,
                                                                       placement = 'top',
                                                                       overlay,
                                                                       children,
                                                                       ...props
                                                                   }) => {
    return (
        <BootstrapOverlayTrigger
            flip={flip}
            placement={placement}
            overlay={overlay}
            {...props}
        >
            {children}
        </BootstrapOverlayTrigger>
    );
};

export default CustomOverlayTrigger;
