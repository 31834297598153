import React from 'react'
import { Button, Modal } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux';
import generalSlice from '../../store/general/GeneralSlice';

export default function ModalConfirm() {
  const dispatch = useDispatch();
  const {show, title, text, action} = useSelector((state: any) => state.general.confirmModal);

  return (
    <Modal
        animation={false}
        show={show}
        onHide={() => {
            dispatch(generalSlice.actions.setConfirmModal({
				show: false,
			}));
        }}
        className='confirm-modal-add'
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            {title ?? 'Confirm action' }
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
            {text}
        </Modal.Body>

        <Modal.Footer>
            <Button className='m-0' variant='danger' 
                    onClick={() => {
                        dispatch(generalSlice.actions.setConfirmModal({
                            show: false,
                        }));
            }}>
                No
            </Button>
            <Button className='m-0 ml-2' onClick={action}>Yes</Button>
        </Modal.Footer>
    </Modal>
    )
}
