import {Route, Switch} from "react-router-dom";
import React from "react";
import {Campaigns} from "./components/Campaigns";
import {CampaignGoals} from "./components/CampaignGoals";
import {CampaignGoalForm} from "./components/CampaignGoalForm";
import {CampaignType} from "./components/CampaignType";
import {CampaignTypeForm} from "./components/CampaignTypeForm";
import {CampaignBudgetRange} from "./components/CampaignBudgetRange";
import {CampaignBudgetRangeForm} from "./components/CampaignBudgetRangeForm";
import {BudgetOverview} from "./budget-overview/BudgetOverview";
import {CampaignEmailing} from "./campaign-emailing/CampaignEmailing";
import {CampaignEmailingForm} from "./campaign-emailing/CampaignEmailingForm";
import {CampaignUpdateForm} from "./components/CampaignUpdateForm";
import {hasAccessRight} from "../../utils/Functions";
import {AdminAclEnum} from "../../enum/AdminAclEnum";
import CampaignTransfer from "./components/CampaignTransfer";

export function CampaignRoutes() {
    return (
        <Switch>
            {hasAccessRight(AdminAclEnum.CAMPAIGNS) &&
                <>
                    <Route path="/campaign/update/:id" component={CampaignUpdateForm}/>
                    <Route path="/campaign/transfer/:id" component={CampaignTransfer}/>
                    <Route path="/campaign/list" component={Campaigns}/>
                    <Route path="/campaign/budget" component={BudgetOverview}/>
                    <Route exact path="/campaign/emailing" component={CampaignEmailing}/>
                    <Route path="/campaign/emailing/preview" component={CampaignEmailingForm}/>
                    <Route exact path="/campaign/goals" component={CampaignGoals}/>
                    <Route path="/campaign/goals/form" component={CampaignGoalForm}/>
                    <Route exact path="/campaign/types" component={CampaignType}/>
                    <Route path="/campaign/types/form" component={CampaignTypeForm}/>
                    <Route exact path="/campaign/budget-range" component={CampaignBudgetRange}/>
                    <Route path="/campaign/budget-range/form" component={CampaignBudgetRangeForm}/>
                </>
            }
        </Switch>
    );
}
