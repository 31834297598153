/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import {Link, Switch} from "react-router-dom";
import "../assets/sass/pages/login/classic/login-1.scss";
import {toAbsoluteUrl} from "../helpers";
import {ContentRoute} from "../shared/layout";
import Login from "./Login";
import {VerificationPage} from "./VerificationPage";
import {shallowEqual, useSelector} from "react-redux";

export function AuthPage() {
    const {isAuthorized, isVerified} = useSelector(
        ({auth}) => ({
            isAuthorized: auth.user != null,
        }),
        shallowEqual
    );

    return (
        <>
            <div className="d-flex flex-column flex-root">
                {/*begin::Login*/}
                <div
                    className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
                    id="kt_login"
                >
                    {/*begin::Aside*/}
                    <div
                        className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10 "
                        style={{
                            // backgroundImage: `url(${toAbsoluteUrl("/media/icons/login.jpg")})`,
                            background: "gray"
                        }}
                    >
                        {/*begin: Aside Container*/}
                        <div className="d-flex flex-row-fluid flex-column justify-content-between">
                            {/* start:: Aside header */}
                            <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                                <img
                                    alt="Logo"
                                    className="max-h-70px"
                                    src={toAbsoluteUrl("/images/logo.jpeg")}
                                />
                            </Link>
                            {/* end:: Aside header */}

                            {/* start:: Aside content */}
                            <div
                                className="flex-column-fluid d-flex flex-column justify-content-center text-lg-center ">
                                {/*<div style = {{background:"lightgray"}} className="p-2 mb-5">*/}
                                <h3 className="font-size-h1 text-black mb-5">
                                    Welcome to Influence vision !
                                </h3>
                                {/*</div>*/}
                                <p className="font-weight-normal text-black opacity-80">
                                    Take influencer marketing to a higher level with our visionary influencer marketing
                                    platform.
                                </p>
                            </div>
                            {/* end:: Aside content */}

                        </div>
                        {/*end: Aside Container*/}
                    </div>
                    {/*begin::Aside*/}

                    {/*begin::Content*/}
                    <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
                        {/* begin::Content body */}
                        <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
                            <Switch>
                                {isAuthorized &&
                                    <ContentRoute path="/verification-page/:id?" component={VerificationPage}/>
                                }
                                <ContentRoute path="/" component={Login}/>
                            </Switch>
                        </div>
                        {/*end::Content body*/}

                    </div>
                    {/*end::Content*/}
                </div>
                {/*end::Login*/}
            </div>
        </>
    );
}
