import {OfferList} from "./components/OfferList";
import {Route, Switch} from "react-router-dom";
import React from "react";
import {OfferEdit} from "./components/OfferEdit";
import {hasAccessRight} from "../../utils/Functions";
import {AdminAclEnum} from "../../enum/AdminAclEnum";

export function OfferRoutes() {
    return (
        <Switch>
            {hasAccessRight(AdminAclEnum.OFFERS) &&
                <>
                    <Route exact path="/offers" component={OfferList}/>
                    <Route path="/offers/edit" component={OfferEdit}/>
                </>
            }
        </Switch>
    )
}
