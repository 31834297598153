import React, {useEffect, useState} from 'react';
import Autocomplete from 'react-autocomplete';
import {BiTransfer} from 'react-icons/all';
import {useHistory, useLocation} from 'react-router';
import {useParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {Company} from '../../../model/company/Company';
import {SuccessToast} from '../../../utils/toasters';
import {CompanyService} from '../../company/CompanyService';
import {CampaignsService} from '../CampaignsService';

interface Props {
    companyName: string;
}

const CampaignTransfer = () => {
    const history = useHistory();
    const {state: {campaign}}: any = useLocation();
    const {id} = useParams<any>();
    const [companies, setCompanies] = useState<Company[]>([])
    const [selectedCompany, setSelectedCompany] = useState<Company>()
    const [filterParams, setFilterParams] = useState({
        page: 1,
        perPage: 10,
        displayName: '',
    });

    const changeFilterHandler = (event: any) => {
        const {value} = event.target;
        setSelectedCompany((prev: any) => ({...prev, displayName: value}));
        setFilterParams(prev => ({...prev, displayName: value}))
    };

    const changeAutoCompleteHandler = (valueToUpdate: Company) => {
        setSelectedCompany(valueToUpdate)
    }

    const submitHandler = async () => {
        await CampaignsService.transferCampaign(Number(id), Number(selectedCompany?.id)).then(response => {
            if (response === '') {
                history.goBack();
                SuccessToast(`You have successfully changed company for campaign ID: ${id}`)
            }
        }).catch(error => toast.error(`${error.response.data.message}`))
    }

    useEffect(() => {
        CompanyService.getCompanies(filterParams)
            .then((data) => {
                setCompanies(data.data);
            }).catch(error => toast.error(`${error.response.data.message}`))
    }, [filterParams]);
    
    return (
        <div className="row">
            <div className="col-12">
                <h3>{campaign.title}</h3>
            </div>
            <div className="col-md">
                <div className="card">
                    <div className="card-body">
                        <div className="form-group">
                            <label htmlFor="fromCampaign">Current Company</label>
                            <select className="form-control" disabled>
                                <option selected>
                                    {(history.location?.state as unknown as Props)?.companyName ?? '-'}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-md-1 d-flex align-items-center justify-content-center flex-column">
                <BiTransfer size={30}/>
            </div>
            <div className="col-md">
                <div className="card">
                    <div className="card-body">
                        <div className="form-group">
                            <label className='form-label' htmlFor="fromCampaign">Select new Company</label>
                            <div className="autocomplete-input zindex-5 position-relative">
                                <Autocomplete
                                    getItemValue={(item) => item?.displayName ?? '-'}
                                    items={companies}
                                    value={selectedCompany?.displayName}
                                    renderItem={(item) =>
                                        <div key={item.id} className='autocomplete-item'>
                                            {item.displayName ?? '-'}
                                        </div>
                                    }
                                    onChange={(e) => changeFilterHandler(e)}
                                    onSelect={(_, company) => changeAutoCompleteHandler(company)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-12 mt-3 d-flex justify-content-end">
                <button className="btn btn-secondary mr-2" onClick={() => history.goBack()}>Cancel</button>
                <button className="btn btn-primary" disabled={!selectedCompany?.id} onClick={submitHandler}>Update
                </button>
            </div>
        </div>
    );
};

export default CampaignTransfer;
