import React, {useState, useEffect} from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { AccessRightService } from '../AccessRightService';
import { Button, Card, Form, Row } from 'react-bootstrap';
import { IAccessRights } from '../../../model/access-right/IAccessRight';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { getCorrectEnums } from '../../../utils/Functions';
import { SuccessToast } from '../../../utils/toasters';

export default function FormAccessRight() {
  const history = useHistory();
  const {id}: {id: string | undefined} = useParams();
  const {accessRights, SharingTypeEnum} = useSelector((state: any) => state.enums)

  const [accessRight, setAccessRight] = useState<Partial<IAccessRights>>({
    name: 'undefined',
    rights: []
  });

  useEffect(() => {
    if(!!id) {
      AccessRightService.getAccessRight(+id)
        .then((data) => {
            setAccessRight(data);  
        })
        .catch((err) => {
            toast.error(`${err?.response?.data?.message}`)
        });
    }
  }, [id])

  const handleChange = (e:any) => {
    const {name, value} = e.target;
    setAccessRight((prev) => {
      if (name === 'rights') {
        return {
          ...prev,
          [name]: !!e.target.checked ? [...accessRight.rights!, value ] : accessRight?.rights?.filter(item => item !== value) 
        }
      }
      return {
        ...prev,
        [name]: value === 'undefined' ? undefined : value
      }
    })
  }

  const saveAccessRight = () => {
    const service = !!id ? AccessRightService.putAccessRight(+id, accessRight) :  AccessRightService.postAccessRight(accessRight);
    
    service.then((data) => {
        setAccessRight(data);
        SuccessToast(`Successfully ${!!id ? 'updated' : 'created'} access right`);
        history.push('/access-right')   
    })
    .catch((err) => {
        toast.error(`${err?.response?.data?.message}`)
    });
  }

  return (
    <Card>
      <Card.Body>
        <h3> {!!id ? 'Update Access Right' : 'Create Access Right'}</h3>
        <hr/>
        <Row className='mt-5'>
          <Form.Group className='col-4'>
            <Form.Control as="select" name="name" value={accessRight?.name ?? 'undefined'} onChange={handleChange}>
                  <option value={'undefined'}>Select a user type</option>
                  {!!SharingTypeEnum && !!SharingTypeEnum.length && SharingTypeEnum?.map((role: string) => {
                    return (
                      <option key={role} value={role}>{getCorrectEnums(role)}</option>
                    )
                  })}
            </Form.Control>
          </Form.Group>
        </Row>
        <Row className='row'>
          {!!accessRights && !!accessRights.length && accessRights?.map((item: string, index: number) => {
            return (
              <Form.Group key={index} className='col-6'>
                  <Form.Check label={getCorrectEnums(item)} type='checkbox' value={item} name='rights'
                              onChange={(e:any) => handleChange(e)}
                              checked={accessRight?.rights?.includes(item)}
                              />
              </Form.Group>
            )
          })}
        </Row>

        <div className='mt-5'>
          <Button onClick={() => saveAccessRight()}>Save</Button>
        </div>
      </Card.Body>
    </Card>
  )
}
