import React, { useState, useEffect, useMemo } from 'react';
import {Button, Card, Col, Form, Table} from 'react-bootstrap';
import { Pagination } from '../../../shared/components/Pagination';
import { InvoiceService } from '../InvoicesService';
import { toast } from 'react-toastify';
import { IInvoice } from '../../../model/invoice/Invoices';
import { downloadFileRequest } from '../../../services';
import { INVOICING_URL } from '../../../utils/Endpoints';
import { CampaignsService } from '../../campaigns/CampaignsService';
import { Campaign } from '../../../model/campaign/Campaign';
import { formatDate } from '../../../utils/Functions';
import {ImpersonateEye} from "../../../shared/components/ImpersonateEye";
import {User} from "../../../model/user/User";
import {UserService} from "../../user/UserService";
import Autocomplete from 'react-autocomplete';
import { debounce } from 'lodash';

export default function InvoicesList() {
	const [pagination, setPagination] = useState({
		page: 1,
		perPage: 15,
	});
	const [totalPages, setTotalPages] = useState(0);
	const [invoiceList, setInvoiceList] = useState<IInvoice[]>([]);
	const [triggerSearch, setTriggerSearch] = useState<boolean>(false);

	const [campaigns, setCampaigns] = useState<Campaign[]>([]);
	const [campaignId, setCampaignId] = useState('');

	const [users, setUsers] = useState<User[]>([]);
	const [userId, setUserId] = useState('');
    const [campaignsParams, setCampaignsParams] = useState('');
    const [usersParams, setUsersParams] = useState('');

	const getCampaigns = (title: string) => {
		CampaignsService.getCampaigns({ perPage: 500, title: title })
			.then((data) => {
				setCampaigns(data.data);
			})
			.catch((err) => {
				toast.error(`${err.response.data.message}`);
			});
	}
	
	const getUsers = (displayName: string) => {
		UserService.getUsers({ perPage: 500, userType: 'influencer', displayName: displayName })
			.then((data) => {
				setUsers(data.data);
			})
			.catch((err) => {
				toast.error(`${err.response.data.message}`);
			});
	}

	const campaignsDebounce = useMemo(() => debounce((value: string) => {
		getCampaigns(value);
	}, 300), []);

	const usersDebounce = useMemo(() => debounce((value: string) => {
        getUsers(value);
	}, 300), []);

	useEffect(() => {
		InvoiceService.getInvoices({ ...pagination, campaignId: campaignId, userId: userId })
			.then((data) => {
				setInvoiceList(data.data);
				setTotalPages(Math.ceil(data.count / pagination.perPage));
			})
			.catch((err) => {
				toast.error(`${err?.response?.data?.message}`);
			});
	}, [pagination, triggerSearch, userId, campaignId]);

	const handlePages = (updatePage: number) => {
		setPagination({ ...pagination, page: updatePage });
	};

	const doDownload = async (id: number) => {
		try {
			downloadFileRequest(`${INVOICING_URL}/download/${id}`);
		} catch (error) {
			toast.error(`${error}`);
		}
	};

	useEffect(() => {
		getCampaigns(campaignsParams);
		getUsers(usersParams);
	}, []);

	function handleCheckbox(id: number) {
		InvoiceService.togglePaid(id);
		const data = invoiceList.map((e) => {
			if(e.id === id) return {...e, isPaid: !e.isPaid}
			return e;
		});
		setInvoiceList(data);
	}

	function handleChange(evt: any) {
		const value = evt.target.value;
		setPagination({
			...pagination,
			[evt.target.name]: value,
		});
	}

	return (
		<div>
			<div className="card card-custom mb-5 p-4 mt-3" 
				 onKeyDown={(e) => {
					if (e.key === "Enter") setTriggerSearch(!triggerSearch);
				 }}
			>
				<Form.Row>
					<Form.Group as={Col} className='col-4 mb-0 autocomplete-input form-control-solid zindex-5 position-relative'>
						<Autocomplete
							items={campaigns}
							inputProps={{placeholder: 'Campaign title'}}
							getItemValue={(item) => item?.title ?? '-'}
							value={campaignsParams ?? ''}
							renderItem={(item) =>
								<div className='autocomplete-item' key={item.id}>
									{item?.title ?? '-'}
								</div>
							}
							onChange={(e) => {
								campaignsDebounce(e.target.value);
								setCampaignsParams(e.target.value)
								!e.target.value && setCampaignId('');
							}}
							onSelect={(_, campaign) => {
								setCampaignId(campaign?.id);
								setCampaignsParams(_);
								usersDebounce(_);
							}}
						/>
					</Form.Group>
					<Form.Group as={Col} className='mb-0 autocomplete-input form-control-solid zindex-5 position-relative'>
						<Autocomplete
							items={users}
							inputProps={{placeholder: 'Displayname'}}
							getItemValue={(item) => item?.displayName ?? '-'}
							value={usersParams ?? ''}
							renderItem={(item) =>
								<div className='autocomplete-item' key={item.id}>
									{item?.displayName ?? '-'}
								</div>
							}
							onChange={(e) => {
								usersDebounce(e.target.value);
								setUsersParams(e.target.value)
								!e.target.value && setUserId('');
							}}
							onSelect={(_, campaign) => {
								setUserId(campaign?.id);
								setUsersParams(_);
								usersDebounce(_);
							}}
						/>
					</Form.Group>
					<Form.Group as={Col}>
						<Form.Control placeholder="Paid" className="form-control-solid" name="isPaid" onChange={handleChange} as="select">
							<option value="undefined">is Paid?</option>
							<option value="true">Paid</option>
							<option value="false">Not Paid</option>
						</Form.Control>
					</Form.Group>
				</Form.Row>
				<div>
					<Button
						type="submit"
						onClick={() => setTriggerSearch(!triggerSearch)}
					>
						Search
					</Button>
				</div>
			</div>
			<Card className="mb-5 p-4 mt-5">
				<Table hover responsive>
					<thead>
						<tr>
							<th>Offer ID</th>
							<th>User</th>
							<th>Displayname</th>
							<th>Campaign ID</th>
							<th>Campaign name</th>
							<th>Date</th>
							<th>Action</th>
							<th>Paid</th>
						</tr>
					</thead>
					<tbody>
						{invoiceList.map((item) => (
							<tr key={item.id}>
								<td>{item?.campaign?.offers[0]?.id}</td>
								<td>
									<ImpersonateEye id={item.user.id ?? 0}/>{item.user.firstName} {item.user.lastName}
								</td>
								<td>{item.user.displayName}</td>
								<td>{item.campaign.id}</td>
								<td>{item.campaign.title}</td>
								<td>{item.date ? formatDate(item.date) : '-'}</td>
								<td className="text-left">
									<Button
										className="btn btn-primary text-white"
										onClick={() => doDownload(item.id)}
									>
										Download
									</Button>
								</td>
								<td>
									<div className="d-flex align-items-center">
										<input className='form-check-input' type="checkbox" name="isPaid" onChange={(event) => {
											handleCheckbox(item.id!)
										}}
											   checked={item.isPaid}
											   data-toggle="toggle"
										/>
										<p className="m-0 pl-2"></p>
									</div>
								</td>
							</tr>
						))}
					</tbody>
				</Table>
				<div className="w-100">
					{totalPages > 1 && (
						<Pagination
							page={pagination.page}
							totalPages={totalPages}
							handlePagination={handlePages}
						/>
					)}
				</div>
			</Card>
		</div>
	);
}
