import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router';
import {Platform} from '../../../model/platform/Platform';
import {initialPlatform} from '../dto/initialPlatform';
import {PlatformService} from '../PlatformService';
import {ErrorToast, SuccessToast, WarningToast} from '../../../utils/toasters';
import {Button, Card, Col, Form, Image} from 'react-bootstrap';
import {multipartFormData} from '../../../utils/api.service';
import {PLATFORM_URL} from '../../../utils/Endpoints';
import {useHistory} from 'react-router-dom';

export function PlatformForm() {
    const {state: {id}, state: {isEdit}}: any = useLocation();
    const [selectedFileLight, setSelectedFileLight] = useState(null);
    const [selectedFileDark, setSelectedFileDark] = useState(null);
    const [imagePath, setImage] = useState({
        lightPath: '',
        darkPath: ''
    });
    const [platform, setPlatform] = useState<Platform>(initialPlatform);
    let history = useHistory();

    useEffect(() => {
        if (isEdit) {
            PlatformService.getPlatform(id)
                .then((response) => {
                    setPlatform(response);
                    response.path && setImage({
                        lightPath: response.path,
                        darkPath: response?.darkPath ?? ''
                    });
                }).catch(err => ErrorToast(err))
        }
    }, [])

    const updatePlatform = (e: any) => {
        if (selectedFileDark) {
            multipartFormData(selectedFileDark, `${PLATFORM_URL}/dark/${id}`, JSON.stringify({}), 'post')
                .then(response => response).catch(err => ErrorToast(err))
        }
        multipartFormData(selectedFileLight, `${PLATFORM_URL}/${id}`, JSON.stringify(platform))
            .then(response => {
                SuccessToast('Successfully updated platform')
                history.push('/platform/platforms')
            }).catch(err => ErrorToast(err))
    }

    const createPlatform = (e: any) => {
        if (selectedFileDark !== null) {
            multipartFormData(selectedFileDark, `${PLATFORM_URL}/dark/${id}`, JSON.stringify({}), 'post')
                .then(response => response).catch((err) => ErrorToast(err))
        }
        if ((platform.position !== null) && (platform.name !== '') && (selectedFileLight !== null)) {
            multipartFormData(selectedFileLight, PLATFORM_URL, JSON.stringify(platform), 'post')
                .then(response => {
                    SuccessToast('Successfully created platform')
                    history.push('/platform/platforms')
                }).catch((err) => ErrorToast(err))
        } else {
            WarningToast('Please fill out all mandatory fields');
        }
    }

    function handleChange(event: any, isNumber = false) {
        const value = isNumber ? +event.target.value : event.target.value;
        setPlatform({
            ...platform,
            [event.target.name]: value
        });
    }

    function handleCheckbox(evt: any) {
        const change = evt.target.checked;
        setPlatform({
            ...platform,
            [evt.target.name]: change
        });
    }

    function handleFileInput(evt: any, isLight = false) {
        if (evt.target.files.length > 0) {
            if (isLight) {
                setSelectedFileLight(evt.target.files[0]);
            } else {
                setSelectedFileDark(evt.target.files[0]);
            }
            setImage(prev => ({
                ...prev,
                [isLight ? 'lightPath' : 'darkPath']: URL.createObjectURL(evt.target.files[0])
            }))
        }
    }

    return (
        <Card className="card-custom mb-5">
           <Form as={Card.Body}>
                <h3> {isEdit ? 'Update platform' : 'Create platform'}</h3>
                <hr/>                
                <Form.Row>
                    <Col md={8}>
                        <Form.Group>
                            <Form.Label>Position</Form.Label>
                            <Form.Control type="number" name="position"
                                            onChange={(event) => handleChange(event, true)}
                                            value={platform?.position}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Mobile identify</Form.Label>
                            <Form.Control name="mobileIdentify"
                                            onChange={(event) => handleChange(event)}
                                            value={platform?.mobileIdentify}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control name="name" onChange={handleChange} defaultValue={platform?.name}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>CPM</Form.Label>
                            <Form.Control name="predefinedCPM" onChange={handleChange} value={platform?.predefinedCPM}/>
                        </Form.Group>
                        <Col md={5} className="d-flex justify-content-between w-100">
                            <Form.Group className="mt-3 " id="formGridCheckbox">
                                <Form.Check className="d-flex pl-0 gap-2" name="isEnabled" onChange={handleCheckbox}
                                            type="checkbox"
                                            label="Enabled" checked={platform?.isEnabled}/>
                            </Form.Group>
                            <Form.Group className="mt-3 " id="formGridCheckbox">
                                <Form.Check className="d-flex pl-0 gap-2" name="hasApi" onChange={handleCheckbox}
                                            type="checkbox"
                                            label="API" checked={platform?.hasApi}/>
                            </Form.Group>
                        </Col>
                        <Col md={10} className="d-flex justify-content-between w-100">
                            <Form.Group className="mt-3 " id="formGridCheckbox">
                                <Form.Check className="d-flex pl-0 gap-2" name="averageLikes" onChange={handleCheckbox}
                                            type="checkbox"
                                            label="Avg Likes" checked={platform?.averageLikes}/>
                            </Form.Group>
                            <Form.Group className="mt-3 " id="formGridCheckbox">
                                <Form.Check className="d-flex pl-0 gap-2" name="averageViews" onChange={handleCheckbox}
                                            type="checkbox"
                                            label="Avg Views" checked={platform?.averageViews}/>
                            </Form.Group>
                            <Form.Group className="mt-3 " id="formGridCheckbox">
                                <Form.Check className="d-flex pl-0 gap-2" name="averageComments"
                                            onChange={handleCheckbox}
                                            type="checkbox"
                                            label="Avg Comments" checked={platform?.averageComments}/>
                            </Form.Group>
                            <Form.Group className="mt-3 " id="formGridCheckbox">
                                <Form.Check className="d-flex pl-0 gap-2" name="averageReelPlays"
                                            onChange={handleCheckbox}
                                            type="checkbox"
                                            label="Avg Reel Plays" checked={platform?.averageReelPlays}/>
                            </Form.Group>
                        </Col>
                        <Col md={10} className="d-flex justify-content-between w-100">
                            <Form.Group className="mt-3 " id="formGridCheckbox">
                                <Form.Check className="d-flex pl-0 gap-2" name="totalPosts" onChange={handleCheckbox}
                                            type="checkbox"
                                            label="Total Posts" checked={platform?.totalPosts}/>
                            </Form.Group>
                            <Form.Group className="mt-3 " id="formGridCheckbox">
                                <Form.Check className="d-flex pl-0 gap-2" name="followers" onChange={handleCheckbox}
                                            type="checkbox"
                                            label="Followers" checked={platform?.followers}/>
                            </Form.Group>
                            <Form.Group className="mt-3 " id="formGridCheckbox">
                                <Form.Check className="d-flex pl-0 gap-2" name="subscribers" onChange={handleCheckbox}
                                            type="checkbox"
                                            label="Subscribers" checked={platform?.subscribers}/>
                            </Form.Group>
                            <Form.Group className="mt-3 " id="formGridCheckbox">
                                <Form.Check className="d-flex pl-0 gap-2" name="totalWatchTime"
                                            onChange={handleCheckbox}
                                            type="checkbox"
                                            label="Total Watch Time" checked={platform?.totalWatchTime}/>
                            </Form.Group>
                        </Col>
                        <Col md={10} className="d-flex justify-content-between w-100">
                            <Form.Group className="mt-3 " id="formGridCheckbox">
                                <Form.Check className="d-flex pl-0 gap-2" name="uniqueVisitors"
                                            onChange={handleCheckbox}
                                            type="checkbox"
                                            label="Unique Visitors" checked={platform?.uniqueVisitors}/>
                            </Form.Group>
                            <Form.Group className="mt-3 " id="formGridCheckbox">
                                <Form.Check className="d-flex pl-0 gap-2" name="totalPageViews"
                                            onChange={handleCheckbox}
                                            type="checkbox"
                                            label="Total Page Views" checked={platform?.totalPageViews}/>
                            </Form.Group>
                            <Form.Group className="mt-3 " id="formGridCheckbox">
                                <Form.Check className="d-flex pl-0 gap-2" name="newsletterSubscribers"
                                            onChange={handleCheckbox}
                                            type="checkbox"
                                            label="Newsletter Subscribers" checked={platform?.newsletterSubscribers}/>
                            </Form.Group>
                            <Form.Group className="mt-3 " id="formGridCheckbox">
                                <Form.Check className="d-flex pl-0 gap-2" name="averageDownloads"
                                            onChange={handleCheckbox}
                                            type="checkbox"
                                            label="Average Downloads" checked={platform?.averageDownloads}/>
                            </Form.Group>
                        </Col>
                        <Col md={10} className="d-flex justify-content-between w-100">
                            <Form.Group className="mt-3 " id="formGridCheckbox">
                                <Form.Check className="d-flex pl-0 gap-2" name="averagePlays" onChange={handleCheckbox}
                                            type="checkbox"
                                            label="Average Plays" checked={platform?.averagePlays}/>
                            </Form.Group>
                            <Form.Group className="mt-3 " id="formGridCheckbox">
                                <Form.Check className="d-flex pl-0 gap-2" name="totalEpisodes" onChange={handleCheckbox}
                                            type="checkbox"
                                            label="Total Episodes" checked={platform?.totalEpisodes}/>
                            </Form.Group>
                            <Form.Group className="mt-3 " id="formGridCheckbox">
                                <Form.Check className="d-flex pl-0 gap-2" name="averageConcurrentViewers"
                                            onChange={handleCheckbox}
                                            type="checkbox"
                                            label="Avg Concurrent Viewers"
                                            checked={platform?.averageConcurrentViewers}/>
                            </Form.Group>
                            <Form.Group className="mt-3 " id="formGridCheckbox">
                                <Form.Check className="d-flex pl-0 gap-2" name="viewMinutes" onChange={handleCheckbox}
                                            type="checkbox"
                                            label="View Minutes" checked={platform?.viewMinutes}/>
                            </Form.Group>
                        </Col>
                        <Col md={10} className="d-flex justify-content-between w-100">
                            <Form.Group className="mt-3 " id="formGridCheckbox">
                                <Form.Check className="d-flex pl-0 gap-2" name="averagePostEngagements"
                                            onChange={handleCheckbox}
                                            type="checkbox"
                                            label="Avg Post Engagements" checked={platform?.averagePostEngagements}/>
                            </Form.Group>
                            <Form.Group className="mt-3 " id="formGridCheckbox">
                                <Form.Check className="d-flex pl-0 gap-2" name="averagePostImpressions"
                                            onChange={handleCheckbox}
                                            type="checkbox"
                                            label="Avg Post Impressions" checked={platform?.averagePostImpressions}/>
                            </Form.Group>
                            <Form.Group className="mt-3 " id="formGridCheckbox">
                                <Form.Check className="d-flex pl-0 gap-2" name="allPlatforms" onChange={handleCheckbox}
                                            type="checkbox"
                                            label="Platforms" checked={platform?.allPlatforms}/>
                            </Form.Group>
                            <Form.Group className="mt-3 " id="formGridCheckbox">
                                <Form.Check className="d-flex pl-0 gap-2" name="engagementRate"
                                            onChange={handleCheckbox}
                                            type="checkbox"
                                            label="Engagement Rate" checked={platform?.engagementRate}/>
                            </Form.Group>
                        </Col>
                        <Col md={10} className="d-flex justify-content-between w-100">
                            <Form.Group className="mt-3 " id="formGridCheckbox">
                                <Form.Check className="d-flex pl-0 gap-2" name="liveHoursPerMonth"
                                            onChange={handleCheckbox}
                                            type="checkbox"
                                            label="Live hours per month" checked={platform?.liveHoursPerMonth}/>
                            </Form.Group>
                        </Col>
                    </Col>
                    <Col md={4}>
                        <Form.Group>
                            <Form.Label>Upload photo Light theme</Form.Label>
                            <Form.Control accept=".svg, .jpg, .png " type="file"
                                            onChange={(e) => handleFileInput(e, true)}/>
                            {imagePath.lightPath &&
                                <Image className="mt-3" width={300} height={250} alt={imagePath.lightPath}
                                        src={imagePath.lightPath}/>
                            }
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Upload photo dark theme</Form.Label>
                            <Form.Control accept=".svg, .jpg, .png " type="file"
                                            onChange={(e) => handleFileInput(e, false)}/>
                            {imagePath.darkPath &&
                                <Image className="mt-3 bg-dark" width={300} height={250} alt={imagePath.darkPath}
                                        src={imagePath.darkPath}/>
                            }
                        </Form.Group>
                    </Col>
                </Form.Row>

                <Button onClick={isEdit ? updatePlatform : createPlatform} className="col-md-1 col-sm-12 "
                        size="lg"
                        variant="primary">
                    Save
                </Button>
           </Form>
        </Card>
    )
}
