import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";
import {ReportsService} from "../ReportsService";
import {NavLink, useLocation} from "react-router-dom";
import {Button, Card, Carousel, Col, Form, Row, Tooltip} from "react-bootstrap";
import {IReportItem} from "../../../model/report/Report-item";
import {FaArrowAltCircleLeft, FaArrowAltCircleRight, FaArrowLeft, FaArrowRight, FiInfo} from "react-icons/all";
import {ImpersonateEye} from "../../../shared/components/ImpersonateEye";
import {formatDate, getCorrectEnums} from "../../../utils/Functions";
import {CustomImageHandler} from "../../../helpers/CustomImageHandler";
import {UPDATED_REPORT_ITEM} from "../../../enum/Messages";
import CustomOverlayTrigger from "../../../shared/components/CustomOverlayTrigger";

export const initialReport: IReportItem = {
    id: 0,
    additionalPost: false,
    isVerified: false,
    createdAt: '',
    updatedAt: '',
    reach: 0,
    likes: 0,
    dislikes: 0,
    views: 0,
    watchTime: 0,
    shares: 0,
    saves: 0,
    impressions: 0,
    swipeUpLinks: 0,
    stickerEngagements: 0,
    reportImages: [],
    event: {},
    offer: {},
    socialPlatform: {},
    nextItem: 0,
    previousItem: 0,
};

export const reportingFields = [
    'views', 'reach', 'impressions', 'likes', 'comments', 'clicks',
    'stickerEngagements', 'swipeUpLinks', 'shares', 'saves', 'watchTime',
    'dislikes', 'nbPublishedPosts'
];

export function ReportsForm() {
    const [report, setReport] = useState<IReportItem>(initialReport);
    const [activeIndex, setActiveIndex] = useState<number>(0);
    const {state: {id}}: any = useLocation();

    useEffect(() => {        
        ReportsService.getReportItem(id)
            .then((data) => {
                setReport(data);
                setActiveIndex(0);
            })
            .catch((err) => toast.error(`${err.message}`));
    }, [id]);

    const updateReport = () => {
        ReportsService.updateReport(id, report).then((data) => {
            setReport(data)
            toast(UPDATED_REPORT_ITEM)
        }).catch(err => {
            toast.error(`${err.response.data.message}`)
        })
    };

    function handleChange(event: any) {
        const value = +event.target.value;
        setReport({
            ...report,
            [event.target.name]: value
        });
    }

    return (
        <div>
            <div className="d-flex justify-content-between mb-4 align-items-center">
                {report?.previousItem ? (
                    <NavLink className="col-2 font-size-h4" 
                        to={{
                            pathname: '/reported/edit',
                            state: {id: report.previousItem}
                        }}
                    >
                        <FaArrowAltCircleLeft/> 
                        <span className="align-middle pl-2">
                            Previous {report?.previousItem}
                        </span>
                    </NavLink> 
                ) : (
                    <span className='col-2'/>
                )}
                <h3 className='col-8 text-center mb-0'>
                    {report?.socialPlatform ? (
                        <img alt='' 
                            src={report?.socialPlatform?.path} style={{width: 40, height: 40}}
                            className="rounded"/>
                    ) : ( 
                        <img alt='' 
                            src="/images/placeholder.png" style={{width: 40, height: 40}}
                            className="rounded"/>
                    )} |
                    Campaign: {report?.offer?.campaign?.id}
                    <CustomOverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="campaign-tooltip">
                            {report?.offer?.campaign?.title}
                        </Tooltip>}
                    >
                        <FiInfo size={14} className="cursor-pointer"/>
                    </CustomOverlayTrigger> |
                    Offer: {report?.offer?.id} |
                    User: {report?.offer?.user?.id}
                    <CustomOverlayTrigger
                        placement="top"
                        overlay={<Tooltip id="user-tooltip">
                            {report?.offer?.user?.displayName}
                        </Tooltip>}
                    >
                        <FiInfo size={14} className="cursor-pointer"/>
                    </CustomOverlayTrigger>
                    <ImpersonateEye id={report?.offer?.user?.id}/>
                </h3>
                {report?.nextItem ? (
                    <NavLink className="text-right col-2 font-size-h4" to={{
                        pathname: '/reported/edit',
                        state: {id: report.nextItem}
                    }}>
                        <span className="align-middle pr-2">
                            Next {report?.nextItem} 
                        </span>
                        <FaArrowAltCircleRight/>
                    </NavLink> 
                ) : (
                    <span className='col-2'/>
                )}
            </div>

            <Card className="mb-5">
                <Form as={Card.Body}>
                    <Row className="d-flex justify-content-between">
                        <Col md={4}>
                            <Row>
                                {reportingFields?.filter(field => report?.socialPlatform?.[field as keyof IReportItem])?.map((field) => (
                                    <Col md={6} sm={8} xs={12} key={field}>
                                        <Form.Group>
                                            <Form.Label>{getCorrectEnums(field)}</Form.Label>
                                            <Form.Control
                                                name={field}
                                                onChange={handleChange}
                                                value={Number(report?.[field as keyof IReportItem])}
                                                type='number'
                                            />
                                        </Form.Group>
                                    </Col>
                                ))}
                                <Button onClick={updateReport} className="col-md-12 col-sm-12 mt-3" size='lg' variant="primary">
                                    Update
                                </Button>
                                {report?.verifiedBy && (
                                    <div className="mt-10 m-4 d-flex align-items-center col-12" key={report.id}>
                                        <div className='col-3 text-left'>Verified by:</div>
                                        <CustomImageHandler
                                            altTag={''}
                                            classes="rounded-circle col-0"
                                            thumbnailPath={report?.verifiedBy?.profilePhoto?.thumbnail ?? ''}
                                            photoPath={report?.verifiedBy?.profilePhoto?.path ?? ''}
                                            style={{width: 50, height: 50}}
                                        />
                                        <span className='col-4 text-left'>{report?.verifiedBy?.firstName}</span>
                                        <span className='col-3 text-right'>{formatDate(report?.verifyDate)}</span>
                                    </div>
                                )}
                            </Row>
                        </Col>
                        <Col md={8} className="d-flex justify-content-center align-items-center">
                            <Carousel className='custom-carousel-indicators'
                                      controls={report?.reportImages?.length > 1}
                                      indicators={report?.reportImages?.length > 1}
                                      activeIndex={activeIndex}
                                      onSelect={(e) => setActiveIndex(e)}
                                      interval={null}
                                      nextIcon={
                                          <div className="icon-circle">
                                              <FaArrowRight style={{ fontSize: '2rem' }} />
                                          </div>
                                      }
                                      prevIcon={
                                          <div className="icon-circle">
                                              <FaArrowLeft style={{ fontSize: '2rem' }} />
                                          </div>
                                      }
                            >
                            {report?.reportImages?.length ? (
                                    report?.reportImages.map((item) => (
                                        <Carousel.Item key={item.reportPath+''+item.id}>
                                            <img
                                                alt=''
                                                src={item?.reportPath}
                                                style={{ maxWidth: '100%', height: 'auto', maxHeight: 400 }}
                                                className="rounded"
                                            />
                                        </Carousel.Item>
                                    ))
                                ) : (
                                    <img
                                        alt=''
                                        src="/images/placeholder.png"
                                        style={{ maxWidth: '100%', height: 'auto' }}
                                        className="rounded"
                                    />
                                )}
                            </Carousel>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </div>
    );
}
