import React from 'react'
import {Button, Modal} from "react-bootstrap";

interface IConfirmModal {
    showModal: boolean;
    questionModal?: string;
    itemData: any;
    setShowModal: (...args: any) => void;
    func: (...args: any) => void;
}

export default function ConfirmModal({showModal, setShowModal, func, itemData, questionModal = 'Are you sure you want to do this?'}: IConfirmModal) {
    return (
        <Modal
            animation={false}
            show={showModal}
            onHide={() => {
                setShowModal((prev: any) => ({ ...prev, showModal: false }))
            }}
            centered
            className='modal-dialog-centered'
            aria-labelledby="contained-modal-title-vcenter"
        >
        <Modal.Body>
          <h4>
            {questionModal}
          </h4>
        </Modal.Body>
        <Modal.Footer>
          <Button variant='danger' onClick={() =>{
                setShowModal((prev: any) => ({ ...prev, showModal: false }))
            }}>
                No
          </Button>
          <Button onClick={() => func(itemData?.id)}>Yes</Button>
        </Modal.Footer>
      </Modal>
    )
}
