import React, {useMemo} from "react";
import {AsideMenuList} from "./AsideMenuList";
import {useHtmlClassService} from "../../core/MetronicLayout";

interface IAsideMenu {
    isActive: boolean
}

export function AsideMenu({isActive}: IAsideMenu) {
    const uiService = useHtmlClassService();
    const layoutProps = useMemo(() => {
        return {
            layoutConfig: uiService.config,
            asideMenuAttr: uiService.getAttributes("aside_menu"),
            ulClasses: uiService.getClasses("aside_menu_nav", true),
            asideClassesFromConfig: uiService.getClasses("aside_menu", true),
        };
    }, [uiService]);

    return (
        <div className={`tab-pane fade ${isActive && "show active"}`}>
            <div className="aside-menu-wrapper flex-column-fluid pl-10 pr-0 pt-3">
                <div
                    id="kt_aside_menu"
                    data-menu-vertical="1"
                    className={`aside-menu  min-h-lg-800px ${layoutProps.asideClassesFromConfig}`}
                    {...layoutProps.asideMenuAttr}
                >
                    <AsideMenuList layoutProps={layoutProps}/>
                </div>
            </div>
        </div>
    );
}
