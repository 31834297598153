import React from "react";
import {Route, Switch} from "react-router-dom";
import SupervisorsList from "./components/SupervisorsList";
import {AdminAclEnum} from "../../enum/AdminAclEnum";
import {hasAccessRight} from "../../utils/Functions";

export function SupervisorRoutes() {
    return (
        <Switch>
            {hasAccessRight(AdminAclEnum.SUPERVISORS) &&
                <Route exact path="/supervisors" component={SupervisorsList}/>
            }
        </Switch>
    );
}
