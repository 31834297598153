import React, { useEffect, useState } from "react";
import { Button, Col, Dropdown, DropdownButton, Form, Modal, Table } from "react-bootstrap";
import { Campaign } from "../../../model/campaign/Campaign";
import { CampaignsService } from "../CampaignsService";
import { Pagination } from "../../../shared/components/Pagination";
import { ErrorToast, SuccessToast } from "../../../utils/toasters";
import { useHistory } from "react-router";
import { formatDate } from "../../../utils/Functions";
import { ImpersonateEye } from "../../../shared/components/ImpersonateEye";
import { BsTrash } from "react-icons/bs";
import { User } from "../../../model/user/User";
import Autocomplete from "react-autocomplete";
import {calculateFee} from "../../../helpers/FeeCaluculation";
import { useDispatch } from "react-redux";
import generalSlice from "../../../store/general/GeneralSlice";

interface IShowModal {
  show: boolean;
  id: number | undefined;
  title: string;
  showSupervisor: boolean;
  showClient: boolean;
  assignedSupervisors: User[];
  assignedClients: User[];
}

export function Campaigns() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [state, setState] = useState({
    page: 1,
    perPage: 15,
  });
  const [filterParams, setFilterParams] = useState({
    id: undefined,
    title: undefined,
    isPicked: undefined,
    isCharity: undefined,
  });

  const [campaign, setCampaign] = useState<Campaign[]>([]);
  const [supervisors, setSupervisors] = useState<User[]>([]);
  const [supervisorsBackup, setSupervisorsBackup] = useState<User[]>([]);
  const [change, setChange] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<IShowModal>({
    show: false,
    id: undefined,
    title: "",
    showSupervisor: false,
    showClient: false,
    assignedSupervisors: [],
    assignedClients: [],
  });
  const [totalPages, setTotalPages] = useState<number>(0);
  const [supervisorId, setSupervisorId] = useState<number>(0);
  const [triggerSupervisor, setTriggerSupervisor] = useState<boolean>(false);
  const [filterSupervisor, setFilterSupervisor] = useState('');

  useEffect(() => {
    CampaignsService.getCampaigns({
      ...state,
      ...filterParams,
      isPicked:
        filterParams?.isPicked === "undefined"
          ? undefined
          : filterParams?.isPicked,
      isCharity:
        filterParams?.isCharity === "undefined"
          ? undefined
          : filterParams?.isCharity,
    })
      .then((data) => {
        setCampaign(data.data);
        setTotalPages(Math.ceil(data.count / state.perPage));
      })
      .catch((err) => ErrorToast(err));
    return () => {};
  }, [change]);

  useEffect(() => {
    CampaignsService.getSupervisor({perPage: 1000000})
      .then((res) => {
        setSupervisors(res.data);
        const result = res.data?.map((item: User) => {
          return {...item, displayName: `${item.firstName} ${item.lastName}`}
          }
        )
        setSupervisorsBackup(result);
      })
      .catch((err) => {
        ErrorToast(err);
      });
  }, [triggerSupervisor]);

  useEffect(() => {
    const result = supervisorsBackup.filter((user) => user.displayName?.toLowerCase().includes(filterSupervisor?.toLowerCase()));

    let arr = result;
    let length = result.length < showModal.assignedSupervisors.length ? showModal.assignedSupervisors.length : result.length;
      for (let i = 0; i < length; i++) {
        if (
          showModal.assignedSupervisors[i] &&
          arr.filter(
            (item) => item.id! !== showModal.assignedSupervisors[i].id!
          )
        ) {
          arr = arr.filter(
            (item) => item.id! !== showModal.assignedSupervisors[i].id!
          );
        }
      }
    setSupervisors(arr)
  }, [filterSupervisor])
  
  function editCheckbox(id: number, api: string) {
    (CampaignsService as any)
      ?.[api](id)
      .then((data: any) => {
        SuccessToast("Successfully action");
      })
      .catch((err: any) => {
        ErrorToast(err);
      });
  }

  function handleClick() {
    setChange(!change);
    setState({
      ...state,
      page: 1,
    });
  }

  function handleChange(evt: any) {
    const value = evt.target.value.trim();
    setFilterParams({
      ...filterParams,
      [evt.target.name]: value,
    });
  }

  const handlePages = (updatePage: number) => {
    setState({ ...state, page: updatePage });
    setChange(!change);
  };

  const completeCampaign = () => {
    CampaignsService.completeCampaign(showModal?.id!)
      .then((data) => {
        setShowModal({ ...showModal, show: false });
        SuccessToast("Successfully completed");
        setChange(!change);
      })
      .catch((err) => ErrorToast(err));
  };

  useEffect(() => {
    if (!!supervisorId) {
      CampaignsService.assignSupervisor(supervisorId, showModal?.id!)
        .then((data) => {
          SuccessToast("Successfully action");
          setFilterSupervisor('');
          setChange(!change);
          setSupervisorId(0);
          const supervisor = supervisors.find(
            (item) => item.id === supervisorId
          );
          dispatch(generalSlice.actions.setConfirmModal({
              show: false,
          }));
          setShowModal({
            ...showModal,
            assignedSupervisors: [
              ...(showModal?.assignedSupervisors as any),
              supervisor,
            ],
          });
        })
        .catch((err) => ErrorToast(err));
    }
  }, [supervisorId]);

  useEffect(() => {
    if (showModal.showSupervisor) {
      let arr = supervisors;
      for (let i = 0; i < supervisors.length; i++) {
        if (
          showModal.assignedSupervisors[i] &&
          arr.filter(
            (item) => item.id! !== showModal.assignedSupervisors[i].id!
          )
        ) {
          arr = arr.filter(
            (item) => item.id! !== showModal.assignedSupervisors[i].id!
          );
        }
      }      
      setSupervisors(arr);
    } else {
      setTriggerSupervisor(!triggerSupervisor);
    }
  }, [showModal.showSupervisor, showModal.assignedSupervisors]);

  const removeSupervisor = (idUser: number, user: any) => {
    CampaignsService.deleteSupervisor(idUser, showModal?.id!)
      .then((data) => {
        const updatedSupervisors = showModal?.assignedSupervisors?.filter(
          (item) => item.id !== idUser
        );
        setShowModal({ ...showModal, assignedSupervisors: updatedSupervisors });
        SuccessToast("Successfully action");
        setChange(!change);
        setSupervisors([...supervisors, user]);
        dispatch(generalSlice.actions.setConfirmModal({
            show: false,
        }));
      })
      .catch((err) => ErrorToast(err));
  };

  const rollBackExpired = (campaignId: number) => {
    CampaignsService.rollbackExpiredOffers(campaignId)
        .then((data) => {
          setChange(!change);
          SuccessToast("Expired offers changed to active status.");
        })
        .catch((err) => ErrorToast(err));
  };

  const removeClient = (idUser: number, user: any) => {
    CampaignsService.deleteClient(idUser, showModal?.id!)
        .then((data) => {
          const updatedClients = showModal?.assignedClients?.filter(
              (item) => item.id !== idUser
          );
          setShowModal({ ...showModal, assignedClients: updatedClients });
          SuccessToast("Successfully action");
          setChange(!change);
        })
        .catch((err) => ErrorToast(err));
  };

  return (
    <>
      <Modal
        show={showModal.show}
        onHide={() => setShowModal({ ...showModal, show: false })}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Campaign communication
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            Do you want to complete all offers for this {showModal?.title}{" "}
            campaign?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setShowModal({ ...showModal, show: false })}>
            No
          </Button>
          <Button onClick={() => completeCampaign()}>Yes</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        animation={false}
        show={showModal.showSupervisor}
        onHide={() => setShowModal({ ...showModal, showSupervisor: false })}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Edit Supervisor
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>Choose supervisor for {showModal?.title} campaign?</div>
          <div className="form-group col-md-6 col-sm-12 p-0 mt-2">
            <div className="autocomplete-input zindex-5 position-relative">
              <Autocomplete
                  getItemValue={(item) => item?.firstName ?? '-'}
                  value={filterSupervisor}
                  items={supervisors}
                  renderItem={(item) =>
                      <div key={item.id} className='autocomplete-item'>
                          {item.firstName ? item.firstName : '-'} {item.lastName ? item.lastName : '-'}
                      </div>
                  }
                  onChange={(e) => setFilterSupervisor(e.target.value)}
                  onSelect={(_, user) => {
                    dispatch(generalSlice.actions.setConfirmModal({
                        show: true,
                        title: 'Campaign communication',
                        text: <div>Are you sure you want to add this <span className="text-primary">{user.firstName} {user.lastName}</span> as a supervisor?</div>,
                        action: () => setSupervisorId(user.id),
                    }));
                  }}
              />
            </div>
          </div>
          <div>
            {showModal?.assignedSupervisors?.map((item: User) => {
              return (
                <div
                  key={item?.email}
                  className="d-flex shadow p-2 rounded mb-4"
                >
                  <div className="col-auto">
                    <div className="fw-bolder">
                      {item?.firstName} {item?.lastName}
                    </div>
                    <div>{item?.email}</div>
                  </div>
                  <div className="col">
                    <Button
                      className="float-right"
                      onClick={() => {
                        dispatch(generalSlice.actions.setConfirmModal({
                            show: true,
                            title: 'Campaign communication',
                            text: <div>Are you sure you want to remove this <span className="text-primary">{item.firstName} {item.lastName}</span> as a supervisor?</div>,
                            action: () => removeSupervisor(item.id!, item),
                        }));
                      }}
                    >
                      <BsTrash />
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() =>
              setShowModal({ ...showModal, showSupervisor: false })
            }
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
          animation={false}
          show={showModal.showClient}
          onHide={() => setShowModal({ ...showModal, showClient: false })}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Remove Clients from {showModal?.title} campaign
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {showModal?.assignedClients?.map((item: User) => {
              return (
                  <div
                      key={item?.email}
                      className="d-flex shadow p-2 rounded mb-4"
                  >
                    <div className="col-auto">
                      <div className="fw-bolder">
                        {item?.firstName} {item?.lastName}
                      </div>
                      <div>{item?.email}</div>
                    </div>
                    <div className="col">
                      <Button
                          className="float-right"
                          onClick={() => removeClient(item.id!, item)}
                      >
                        <BsTrash />
                      </Button>
                    </div>
                  </div>
              );
            })}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
              onClick={() =>
                  setShowModal({ ...showModal, showClient: false })
              }
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div
        className="card card-custom mb-5 p-4 mt-3"
        onKeyDown={(e) => {
          if (e.key === "Enter") handleClick();
        }}
      >
        <Form.Row>
          <Form.Group as={Col}>
            <Form.Control
              className="form-control form-control-solid mb-lg-0"
              placeholder="Campaign ID"
              name="id"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <Form.Control
              className="form-control form-control-solid mb-lg-0"
              placeholder="Title"
              name="title"
              onChange={handleChange}
            />
          </Form.Group>
          <Form.Group as={Col}>
            <select
              name="isPicked"
              className="form-control form-control-solid"
              onChange={handleChange}
            >
              <option value="undefined">Is Picked?</option>
              <option value="true">Picked</option>
              <option value="null">Not Picked</option>
            </select>
          </Form.Group>
          <Form.Group as={Col}>
            <select
              name="isCharity"
              className="form-control form-control-solid"
              onChange={handleChange}
            >
              <option value="undefined">Is Barter?</option>
              <option value="true">Barter</option>
              <option value="null">Not Barter</option>
            </select>
          </Form.Group>
            <Form.Group as={Col}>
                <select
                    name="isLongTerm"
                    className="form-control form-control-solid"
                    onChange={handleChange}
                >
                    <option value="undefined">Is Long term?</option>
                    <option value="true">Long term</option>
                    <option value="false">Not Long term</option>
                </select>
            </Form.Group>
        </Form.Row>
        <Button className="col-md-1 col-sm-12" onClick={() => handleClick()}>
          Search
        </Button>
      </div>

      <div className="card mb-5 p-4">
        <Table hover responsive>
          <thead>
            <tr>
              <th>ID</th>
              <th>Title</th>
              <th>Email</th>
              <th>Created</th>
              <th>Fee</th>
              <th>Status</th>
              <th>Type</th>
              <th>Is Barter?</th>
              <th>Is Picked?</th>
              <th>Supervisor</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {campaign?.map((item) => (
              <tr key={item?.id}>
                <td>{item?.id}</td>
                <td className="word-break max-w-120px">{item?.title}</td>
                <td className="word-break">
                  <ImpersonateEye id={item?.user?.id ?? 0} /> {item?.user?.email}
                </td>
                <td>{formatDate(item?.createdAt)}</td>
                <td>{item?.feePercentage ? calculateFee(item?.feePercentage) : ''}</td>
                <td><span className={`status ${item?.status}`}>{item?.status === 'published' ? 'public' : item.status}</span></td>
                <td><span className={`status ${item.isLongTerm ? 'long' : item.parent ? 'linked' : 'standard'}`}>{item.isLongTerm ? 'Long' : item.parent ? 'Linked' : 'Standard'}</span></td>
                <td>
                  {!item.isDeleted && (<div className="d-flex">
                      <input
                      type="checkbox"
                      className="form-check-input"
                      defaultChecked={item.isCharity}
                      data-toggle="toggle"
                      onChange={(event) => {
                        editCheckbox(item.id, "editCharity");
                      }}
                    />
                    <label htmlFor="checkbox"></label>
                  </div>
                  )}
                </td>
                <td>
                  {!item.isDeleted && (<div className="d-flex">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      defaultChecked={item.isPicked}
                      data-toggle="toggle"
                      onChange={(event) => {
                        editCheckbox(item.id, "setIsPicked");
                      }}
                    />
                    <label htmlFor="checkbox"></label>
                  </div>
                  )}
                </td>
                <td>
                  {!item.isDeleted && (
                    <Button className="min-w-max-cont "
                      onClick={() =>
                        setShowModal({
                          ...showModal,
                          showSupervisor: true,
                          id: item?.id,
                          title: item?.title,
                          assignedSupervisors: item?.assignedSupervisors,
                        })
                      }
                    >
                      Edit ({item?.assignedSupervisors.length})
                    </Button>
                  )}
                </td>
                <td>
                  {!item.isDeleted ? (
                    <DropdownButton title="Actions" id="dropdown-menu-align-right">
                      <Dropdown.Item eventKey="1" onClick={() =>
                          history.push(`/campaign/update/${item?.id}`, {...item})
                        }>
                          Edit
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() =>
                                        history.push(`/campaign/transfer/${item?.id}`, {
                                          companyName: item?.company?.displayName,
                                          campaign: item,
                                        })
                                     }
                                     eventKey="2">
                          Transfer
                      </Dropdown.Item>
                      {item?.assignedClients?.length > 0 ? (<Dropdown.Item  onClick={() =>
                                      setShowModal({
                                        ...showModal,
                                        showClient: true,
                                        id: item?.id,
                                        title: item?.title,
                                        assignedClients: item?.assignedClients,
                                      })
                                  }
                          >
                         Clients ({item?.assignedClients.length})
                      </Dropdown.Item>) : null }
                      {item.hasExpiredOffers && <Dropdown.Item eventKey="1" onClick={() =>
                          rollBackExpired(item.id)
                      }>
                        Rollback Expired Offers
                      </Dropdown.Item>}
                      <Dropdown.Divider/>
                      {!item?.isCompleted ? (
                        <Dropdown.Item onClick={() =>
                                        setShowModal({
                                          ...showModal,
                                          show: true,
                                          id: item?.id,
                                          title: item?.title,
                                        })
                                      }
                                      eventKey="3">
                          Complete
                        </Dropdown.Item>
                      ) : (
                        <Dropdown.Item eventKey="4" className="cursor-default bg-primary-o-50 text-primary">
                          Completed
                        </Dropdown.Item>
                      )}
                  </DropdownButton>
                  ) : (
                    <h6 className="text-danger">Deleted</h6>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
          {campaign.length < 15 && !!campaign.length && (
            <div className='h-180px'></div>
          )}
        </Table>
        <div className="w-100">
          {totalPages > 1 && (
            <Pagination
              page={state.page}
              totalPages={totalPages}
              handlePagination={handlePages}
            />
          )}
        </div>
      </div>
    </>
  );
}
