import {Route, Switch} from "react-router-dom";
import React from "react";
import AdminList from "./components/AdminList";
import {hasAccessRight} from "../../utils/Functions";
import {AdminAclEnum} from "../../enum/AdminAclEnum";

export function AdminRoutes() {
    return (
        <Switch>
            {hasAccessRight(AdminAclEnum.ADMIN) &&
                <Route exact path="/admins" component={AdminList}/>
            }
        </Switch>
    )
}