import React, {useEffect, useState} from 'react';
import {DashboardService} from '../DashboardService';
import {Col, Row} from 'react-bootstrap';
import {LatestCampaignsList} from './LatestCampaignsList';
import {LatestUsersList} from './LatestUsersList';
import {
    FaCalendar,
    FaChartPie,
    FaUsers
} from 'react-icons/fa';
import {ICampaignReview} from "../../../model/campaign/ICampaignReview";
import {ErrorToast} from "../../../utils/toasters";
import {CampaignReview} from "./CampaignReview";
import { IDashboard } from '../dto/iDashboard';
import {DashboardStatisticCard} from "./DashboardStatisticCard";
import {useSelector} from "react-redux";

export function Dashboard() {
    const [statistics, setStatistics] = useState<IDashboard>();
    const [campaignReview, setCampaignReview] = useState<ICampaignReview[]>([]);
    const [triggerUpdate, setTriggerUpdate] = useState(false);
    const {
        user,
    } = useSelector((states: any) => states.auth);

    useEffect(() => {
        DashboardService.getDashboard()
            .then((data) => {
                setStatistics(data)
            }).catch(err => ErrorToast(err));
    }, [])

    useEffect(() => {
        DashboardService.getCampaignApproval()
            .then((response) => {
                setCampaignReview(response as any)
            }).catch(err => ErrorToast(err));
    }, [triggerUpdate])

    return (
        <>
            <h2 className="mb-4">Hi, {user.firstName ? user.firstName :'Influence'}!</h2>
            <p>Welcome to influence.vision dashboard</p>
            <Row>
                <DashboardStatisticCard
                    cardName="Offers"
                    icon={<FaChartPie/>}
                    baseColor="primary"
                    items={statistics?.offers}
                    total={statistics?.offersTotal!}
                    styleCard="h-100"
                />
                <DashboardStatisticCard
                    cardName="Campaigns"
                    icon={<FaCalendar/>}
                    baseColor="danger"
                    items={statistics?.campaigns}
                    total={statistics?.campaignsTotal!}
                    styleCard="h-100"
                />
                <DashboardStatisticCard
                    cardName="Registrations"
                    icon={<FaUsers/>}
                    baseColor="primary"
                    items={statistics?.mau}
                    total={statistics?.mauTotal!}
                    styleCard="h-100"
                />
            </Row>

            {!!campaignReview?.length &&
                <Row className='pt-3'>
                    <Col md={12}>
                        <CampaignReview
                            campaignReview={campaignReview as ICampaignReview[]}
                            setTriggerUpdate={setTriggerUpdate}/>
                    </Col>
                </Row>
            }

            <Row className="p-3 mb-5 justify-content-between">
                <LatestCampaignsList campaigns={statistics?.lastCampaigns!}/>
                <LatestUsersList users={statistics?.lastUsers!}/>
            </Row>
        </>
    )
}
