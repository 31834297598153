import React, {useEffect, useState} from 'react';
import {Button, ButtonGroup, Col, Form, Table} from 'react-bootstrap';
import {NavLink} from 'react-router-dom';
import {Pagination} from '../../../shared/components/Pagination';
import {Blog} from '../../../model/blog/Blog';
import {toast} from 'react-toastify';
import {BlogService} from '../BlogService';
import {DELETED_BLOG} from '../../../enum/Messages';
import {LocalRoutes} from '../../../enum/LocalRoutes';
import {useHistory} from 'react-router';

export function BlogList() {
    const history = useHistory();
    const [state, setState] = useState({
        page: 1,
        perPage: 15,
        title: ''
    })
    const [blog, setBlog] = useState<Blog[]>([])
    const [change, setChange] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        BlogService.getBlogs(state)
            .then((data) => {
                setBlog(data.data);
                setTotalPages(Math.ceil(data.count / state.perPage))
            })
            .catch((err) => {
                toast.error(`${err.response.data.message}`)
            });
        return () => {
        };
    }, [change, page]);

    function deleteBlog(id: number) {
        BlogService.deleteBlog(id).then(() => {
                setChange(!change)
                toast(DELETED_BLOG)
            }
        ).catch((err) => {
            toast.error(`${err.response.data.message}`)
        });
    }

    function handleClick(env: any) {
        env.preventDefault()
        setChange(!change)
    }

    function handleChange(evt: any) {
        const value = evt.target.value;
        setState({
            ...state,
            [evt.target.name]: value
        });
    }

    const handlePages = (updatePage: number) => {
        setState({...state, page: updatePage})
        setPage(updatePage)
    };

    return (
        <>
            <Button className="mb-4 col-md-auto col-sm-12">
                <NavLink className="w-100 text-white mr-1" to={{
                    pathname: '/blog/create',
                    state: {id: undefined, isEdit: false}
                }}>Add blog post
                </NavLink>
            </Button>

            <div className="card card-custom mb-5 p-4">
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Title</Form.Label>
                        <Form.Control name="title" onChange={handleChange}/>
                    </Form.Group>
                </Form.Row>

                <Button className="col-md-1 col-sm-12" type="submit" onClick={handleClick}>
                    Search
                </Button>
            </div>

            <div className="card card-custom mb-5 p-4">
                <Table className='table-head-bg' hover responsive>
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Date</th>
                            <th>Title</th>
                            <th>Views</th>
                            <th>Unique views</th>
                            <th>Role</th>
                            <th>Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                    {blog?.map((e) => (
                        <tr key={e.id}>
                            <td>{e.id}</td>
                            <td>{e.createdAt}</td>
                            <td>{e.title}</td>
                            <td>{e.viewCountPlural}</td>
                            <td>{e.viewCountSingular}</td>
                            <td>{e.category}</td>
                            <td>{e.isEnabled ? 'Published' : 'Draft'}</td>
                            <td>
                                <ButtonGroup size="sm">
                                    <Button className="mr-1" variant="primary" href={e.video}
                                            target="_blank">Preview</Button>
                                    <Button className="mr-1" variant="primary"
                                            onClick={() => history.push(LocalRoutes.BLOG_EDIT, {
                                                id: e.id, isEdit: true
                                            })}>
                                        Edit
                                    </Button>
                                    <Button variant="danger" onClick={() => deleteBlog(e.id ?? 0)}>Delete</Button>
                                </ButtonGroup>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <div className="w-100">
                    {totalPages <= 1 ? '' :
                        <Pagination
                            page={page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    }
                </div>
            </div>
        </>
    )
}

