import React from 'react';
import {Card, Table} from 'react-bootstrap';
import {Campaign} from '../../../model/campaign/Campaign';
import {formatDate} from "../../../utils/Functions";

export function LatestCampaignsList({campaigns}: {campaigns: Campaign[]}) {
    return (
        <Card className="p-4 mt-3 col-md-5-5 col-sm-12">
            <h5 className='p-3'>Latest Campaigns</h5>
            <Table hover responsive>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th className="text-right">Created</th>
                    </tr>
                </thead>
                <tbody>
                {campaigns?.map((e)=>(
                    <tr key={e.id}>
                        <td>{e.id}</td>
                        <td>{e.title}</td>
                        <td className="text-right">{formatDate(e.createdAt)}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
        </Card>
    )
}
