import React, { ReactElement } from 'react';
import { Card, Col, Row } from 'react-bootstrap';

interface IDashboardStatisticCard {
    cardName: string;
    icon: ReactElement;
    baseColor: string;
    items: {
        name: string;
        value: string | number;
    }[];
    total: number;
    styleCard: string;
}

export function DashboardStatisticCard({cardName, icon, baseColor, items, total, styleCard}: IDashboardStatisticCard) {
    return (
        <div className="col-xl-4 col-lg-6">
            <Card className={`card-stats mb-4 mb-xl-0 ${styleCard}`}>
                <Card.Body>
                    <Row>
                        <Col>
                            <h5 className="card-title text-muted mb-3">{cardName}</h5>
                            <span className="h3 font-weight-bold mb-1">{total ?? 0}</span>
                        </Col>
                        <Col className="col-auto">
                            <div className={`symbol symbol-50px w-50px bg-${baseColor}`}>
                               <div className="px-4 py-2 text-white text-center font-size-h3">{icon}</div>
                            </div>
                        </Col>
                    </Row>
                    <div className="mt-3 mb-0 text-muted text-sm">
                        {items?.map((item:any, index: number)=>(
                        <div key={index}>
                            <span className="text-success mr-2"><i className="fa fa-arrow-up"></i> {item.name}:</span>
                            <span className="text-nowrap">{item.value}</span><br/>
                        </div>
                    ))}
                    </div>
                </Card.Body>
            </Card>
        </div>
    );
}
