import {all} from "redux-saga/effects";
import {combineReducers} from '@reduxjs/toolkit';

import * as auth from "./authentication/AuthSlice";
import enumsSlice from "./common/EnumsSlice";
import generalSlice from "./general/GeneralSlice";

export const rootReducer = combineReducers({
    auth: auth.reducer,
    enums: enumsSlice.reducer,
    general: generalSlice.reducer,
});

export function* rootSaga() {
    yield all([auth.saga()]);
}
