import React from "react";
import {Switch, Route} from "react-router-dom";
import {ContentRoute} from "../shared/layout";
import {CompanyRoutes} from './company/routes';
import {UserRoutes} from "./user/routes";
import {AgencyRoutes} from "./agency/routes";
import {BlogRoutes} from "./blog/routes";
import {CampaignRoutes} from "./campaigns/routes";
import {OfferRoutes} from "./offer/routes";
import {StatisticsRoutes} from "./statistics/routes";
import {Dashboard} from "./dashboard/components/Dashboard";
import {AmbassadorRoutes} from "./ambassador/routes";
import {PartnerRoutes} from "./partner/routes";
import {PlatformRoutes} from "./platform/routes";
import {ReportingRoutes} from "./reporting/routes";
import {ExportRoutes} from "./export/routes";
import PackagesRoutes from "./packages/routes";
import {InvoiceRoutes} from "./invoices/routes";
import {SupervisorRoutes} from "./supervisors/routes";
import {OnboardingRequests} from "./onboarding-requests/routes";
import { AccessRightRoutes } from "./access-right/routes";
import {AdminRoutes} from "./admins/routes";
import {ReportsRoutes} from "./reports/routes";
import {ContractRoutes} from "./contract/routes";

export default function Routes() {
    return (
        <Switch>

            <ContentRoute exact path="/" component={Dashboard}/>

            <Route path='/user'>
                <UserRoutes/>
            </Route>

            <Route path='/agency'>
                <AgencyRoutes/>
            </Route>

            <Route path='/campaign'>
                <CampaignRoutes/>
            </Route>

            <Route path='/offers'>
                <OfferRoutes/>
            </Route>

            <Route path='/blog'>
                <BlogRoutes/>
            </Route>

            <Route path='/report'>
                <ReportingRoutes/>
            </Route>

            <Route path="/reported">
                <ReportsRoutes/>
            </Route>

            <Route path='/statistics'>
                <StatisticsRoutes/>
            </Route>

            <Route path="/ambassador">
                <AmbassadorRoutes/>
            </Route>

            <Route path="/company">
                <CompanyRoutes/>
            </Route>

            <Route path="/platform">
                <PlatformRoutes/>
            </Route>

            <Route path="/export">
                <ExportRoutes/>
            </Route>

            <Route path="/packages">
                <PackagesRoutes/>
            </Route>

            <Route path="/invoices">
                <InvoiceRoutes/>
            </Route>

            <Route path="/onboarding-requests">
                <OnboardingRequests/>
            </Route>

            <Route path="/supervisors">
                <SupervisorRoutes/>
            </Route>

            <Route path="/admins">
                <AdminRoutes/>
            </Route>

            <Route path="/access-right">
                <AccessRightRoutes/>
            </Route>

            <Route path='/contracts'>
                <ContractRoutes/>
            </Route>

            {/*<Route>*/}
            {/*    <PartnerRoutes/>*/}
            {/*</Route>*/}



        </Switch>
    );
}
