import React, {useEffect, useState} from 'react';
import {Card, Table} from 'react-bootstrap';
import {Pagination} from '../../../shared/components/Pagination';
import {IPackageLog} from '../../../model/package/IPackage';
import {PackagesService} from '../PackagesService';
import {toast} from 'react-toastify';
import {formatDate} from "../../../utils/Functions";

export default function PackagesLog() {
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 15,
    })
    const [totalPages, setTotalPages] = useState(0);
    const [packagesList, setPackagesList] = useState<Partial <IPackageLog>[]>([])

    useEffect(() => {
        PackagesService.getPackagesLog(pagination)
            .then((data) => {
                setPackagesList(data.data);
                setTotalPages(Math.ceil(data.count / pagination.perPage))
            })
            .catch((err) => {
                toast.error(`${err?.response?.data?.message}`)
            });
    }, [pagination])

    const handlePages = (updatePage: number) => {
        setPagination({...pagination, page: updatePage})
    };

  return (
    <div>
        <Card className="mb-5 p-4 mt-5">
            <Table hover responsive>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Date</th>
                        <th>Package</th>
                        <th>Company</th>
                        <th>Changed by</th>
                    </tr>
                </thead>
                <tbody>
                {packagesList.map((item) => (
                    <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{formatDate(item?.createdAt as string)}</td>
                        <td>{item.package?.displayName}</td>
                        <td>{item.company?.displayName}</td>
                        <td className="text-left">{item.changedBy?.email}</td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <div className="w-100">
                {totalPages <= 1 ? '' :
                    <Pagination
                        page={pagination.page}
                        totalPages={totalPages}
                        handlePagination={handlePages}
                    />
                }
            </div>
        </Card>
    </div>
  )
}
