import React, {SetStateAction, useEffect, useState} from 'react';
import {Button, Card, Form, Row} from 'react-bootstrap';
import { toast } from 'react-toastify';
import Autocomplete from 'react-autocomplete';
import { CampaignsService } from '../../campaigns/CampaignsService';
import { Campaign } from '../../../model/campaign/Campaign';

interface IReportingFilters {
    setPagination: React.Dispatch<SetStateAction<any>>
    setReportingTrigger: (...args: any) => void;
}

const ReportingFilters = ({setPagination, setReportingTrigger}: IReportingFilters) => {
    const [campaigns, setCompaigns] = useState<Campaign[]>([]);
    const [filterCampaigns, setFilterCampaigns] = useState('');

    useEffect(() => {
        CampaignsService.getCampaigns({page: 1, perPage: 10, title: filterCampaigns})
            .then((data) => {
                setCompaigns(data.data);
            })
            .catch((err) => {
                toast.error(`${err.response.data.message}`);
            });
    }, [filterCampaigns])

    return (
        <Card className="mb-5"  
            onKeyDown={(e: any) => {
                if (e.key === "Enter") setReportingTrigger((prev: boolean) => !prev);
            }}>
            <Card.Body>
                <Row>
                    <Form.Group className='m-0 col-4'>
                        <div className="autocomplete-input zindex-5 position-relative">
                            <Autocomplete
                                getItemValue={(item) => item?.title ?? '-'}
                                menuStyle={{
                                    position:'absolute', 
                                    top: 'auto',
                                    left: '0px',
                                    minWidth: '100%',
                                    background: 'rgba(255, 255, 255, 0.9)', 
                                    borderRadius: '3px', 
                                    padding: '4px 0px',
                                    boxShadow: 'rgba(0, 0, 0, 0.1) 0px 2px 12px',
                                }}
                                inputProps={{placeholder: 'Campaign'}}
                                value={filterCampaigns}
                                items={campaigns}
                                renderItem={(item) =>
                                    <div key={item.id} className='autocomplete-item'>
                                        {item.title ? item.title : '-'}
                                    </div>
                                }
                                onChange={(e) => {
                                    setFilterCampaigns(e.target.value)
                                    if(!e.target.value) setPagination((prev: any) => ({...prev, campaignId: undefined}))
                                }}
                                onSelect={(_, campaign) => {
                                    setPagination((prev: any) => ({...prev, campaignId: campaign?.id}))
                                    setFilterCampaigns(campaign?.title)
                                }}
                            />
                        </div>
                    </Form.Group>

                    <Form.Group className='m-0 col-auto'>
                        <div className="autocomplete-input zindex-5 position-relative">
                            <Button onClick={()=> setReportingTrigger((prev: boolean) => !prev)}>Search</Button>
                        </div>
                    </Form.Group>
                </Row>
            </Card.Body>
        </Card>
    );
};

export default ReportingFilters;
