import React, { useState } from 'react'
import { Button, Col, Row, Spinner } from 'react-bootstrap'
import { INFLUENCERS_DOWNLOAD_URL } from '../../../../utils/Endpoints';
import { downloadFileRequestLoading } from '../../../../services';

interface IInfluencerTabs{
    table: string;
    setTable: (...args: any) => void;
    state: any;
}

export default function InfluencerTabs({ table, setTable, state }: IInfluencerTabs) {
    const [loader, setLoader] = useState(false);

    const downloadFile = async () => {
        setLoader(true);
        try {
            const {loading} = await downloadFileRequestLoading(INFLUENCERS_DOWNLOAD_URL, '', '', {...state, page: undefined, perPage: state.count})
            setLoader(loading);
        } catch (error) {
            setLoader(false);
        }
    }

  return (
    <Row className="justify-content-between mb-4 px-3">
        <Col xs="auto">
            <Button className={`${table === 'general' ? 'btn' : 'btn-outline-primary'} mr-4`} onClick={() => setTable('general')}>
                General
            </Button>
            <Button className={table !== 'general' ? 'btn' : 'btn-outline-primary'} onClick={() => setTable('onboarding')}>
                Onboarding
            </Button>
        </Col>
        <Col xs='auto'>
            <Button disabled={!!loader} onClick={() => downloadFile()}>
                {!!loader ? (
                    <Spinner animation="border" />
                ) : (
                    'Export'
                )}
            </Button>
        </Col>
    </Row>
  )
}
