import React from "react";
import {Route, Switch} from "react-router-dom";
import {PlatformItemList} from "./components/PlatformItemList";
import {PlatformForm} from "./components/PlatformForm";
import {PlatformItemForm} from "./components/PlatformItemForm";
import {PlatformList} from "./components/PlatformList";
import {AdminAclEnum} from "../../enum/AdminAclEnum";
import {hasAccessRight} from "../../utils/Functions";

export function PlatformRoutes() {
    return (
        <Switch>
            {hasAccessRight(AdminAclEnum.PLATFORM) &&
                <>
                    <Route exact path="/platform/item" component={PlatformItemList}/>
                    <Route path="/platform/item/form" component={PlatformItemForm}/>
                    <Route path="/platform/platforms" component={PlatformList}/>
                    <Route path="/platform/form" component={PlatformForm}/>
                </>
            }
        </Switch>
    )
}