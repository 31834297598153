import {CAMPAIGN_BUDGET_URL} from '../../../utils/Endpoints';
import {BaseModel} from '../../../model/shared/BaseModel';
import {requests} from '../../../services';
import {IBudget} from '../../statistics/dto/iBudget';

export const BudgetOverviewService = {

    getBudgetOverview:(id?: string, params?: any): Promise<BaseModel<IBudget>> => requests.get(`${CAMPAIGN_BUDGET_URL}?&id=${id}`,params),

}
