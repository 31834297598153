import React from 'react';
import {Button, Form, Row} from 'react-bootstrap';

export function AdminFilter({handleChanges, handleClicks}: any) {
    return (
        <Form>
            <Row style={{rowGap: '18px'}}>
                <div className="col-md-2 col-6">
                    <Form.Control placeholder="User id" className="form-control-solid" name="id"
                                  onChange={handleChanges}/>
                </div>
                <div className="col-md-2 col-6">
                    <Form.Control placeholder="name,email" className="form-control-solid" name="search"
                                  onChange={handleChanges}/>
                </div>
            </Row>
            <Button className="mt-4" variant="primary" onClick={() => handleClicks()}>
                Search
            </Button>
        </Form>
    );
}
