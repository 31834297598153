import React from "react";
import UserList from "./components/UserList";
import {Route, Switch} from "react-router-dom";
import {UserForm} from "./components/UserForm";
import {UserPlatforms} from "./components/UserPlatforms";
import {UserPlatformLogsList} from "./components/UserPlatformLogsList";
import {UserDeviceList} from "./components/UserDeviceList";
import {InfluencerList} from "./components/influencers/InfluencerList";
import {hasAccessRight} from "../../utils/Functions";
import {AdminAclEnum} from "../../enum/AdminAclEnum";

export function UserRoutes() {
    return (
        <Switch>
            {hasAccessRight(AdminAclEnum.INFLUENCERS) &&
                <Route path="/user/influencers" component={InfluencerList}/>
            }
            <Route exact path="/user/devices" component={UserDeviceList}/>
            {hasAccessRight(AdminAclEnum.USER_PLATFORM_LOGS) &&
                <Route path="/user/platform/logs" component={UserPlatformLogsList}/>
            }
            {hasAccessRight(AdminAclEnum.USERS) &&
                <>
                    <Route exact path="/user" component={UserList}/>
                    <Route path="/user/edit/:id/:isUserList?" component={UserForm}/>
                    <Route path="/user/platforms" component={UserPlatforms}/>
                </>

            }

        </Switch>
    );
}
