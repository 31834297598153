import React from 'react'
import {Route, Switch} from 'react-router'
import PackagesList from './components/PackagesList'
import PackagesForm from './components/PackagesForm'
import OverrideForm from './components/OverrideForm'
import PackagesLog from "./components/PackagesLog";
import {AdminAclEnum} from "../../enum/AdminAclEnum";
import {hasAccessRight} from "../../utils/Functions";

export default function PackagesRoutes() {
    return (
        <Switch>
            {hasAccessRight(AdminAclEnum.PACKAGES) &&
                <>
                    <Route exact path="/packages" component={PackagesList}/>
                    <Route exact path="/packages/create" component={PackagesForm}/>
                    <Route exact path="/packages/override" component={OverrideForm}/>
                    <Route exact path="/packages/override/:id" component={OverrideForm}/>
                    <Route exact path="/packages/log" component={PackagesLog}/>
                </>
            }
        </Switch>
    )
}
