import React from "react";
import {shallowEqual, useSelector} from "react-redux";
import {Route} from "react-router-dom";
import {AuthPage} from "./AuthPage";

export const ProtectedRoute = ({children}: any) => {
    const {isAuthorized, isVerified} = useSelector(
        ({auth}: any) => ({
            isAuthorized: auth.user != null,
            isVerified: auth.isVerified
        }),
        shallowEqual
    );

    if (!isAuthorized || !isVerified) {
        return (
            <Route>
                <AuthPage/>
            </Route>
        );
    }
    return children
};
