import React, {useEffect, useState} from 'react';
import {User} from '../../../model/user/User';
import {Dropdown, DropdownButton, Table} from 'react-bootstrap';
import {FaUser} from 'react-icons/fa';
import {NavLink, useParams} from 'react-router-dom';
import {CompanyService} from '../CompanyService';
import {ErrorToast} from '../../../utils/toasters';
import {getCorrectEnums} from "../../../utils/Functions";
import {ImpersonateEye} from "../../../shared/components/ImpersonateEye";
import {CustomImageHandler} from "../../../helpers/CustomImageHandler";

export function CompanyUsers () {
    const [users, setUsers] = useState<User[]>([]);
    const {id}: any = useParams();

    useEffect(()=>{
        CompanyService.getCompanyUsers(id).then(data=>{
            setUsers(data)
        }).catch((err) => {
            ErrorToast(err)
        });
    }, [])

    return (
        <div className="card card-custom mb-5 p-4">
            <Table hover responsive>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Photo</th>
                        <th>Display name</th>
                        <th>Type</th>
                        <th>Email</th>
                        <th>Managing</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                {users.map((e)=>(
                    <tr key={e.id}>
                        <td>{e.id}</td>
                        <td>
                            <CustomImageHandler
                                altTag={''}
                                classes="rounded-circle"
                                thumbnailPath={e?.profilePhoto?.thumbnail ?? ''}
                                photoPath={e?.profilePhoto?.thumbnail ?? ''}
                                style={{width: 50, height: 50}}
                            />
                        </td>
                        <td>{e.displayName}{!!e.displayName && <br/>}{e.firstName} {e.lastName}</td>
                        <td>{getCorrectEnums(e.userType ?? '')}</td>
                        <td><ImpersonateEye id={e.id ?? 0}/>{e.email}</td>
                        {/*<td>{e.lastLogin}<br/> IP:{e.lastLoginIp}</td>*/}
                        <td>{e?.assignedCampaigns?.length || 0}</td>
                        <td>
                            <DropdownButton alignRight title={<FaUser/>} id="dropdown-menu-align-right">
                                <Dropdown.Item eventKey="1" className=''>
                                    <NavLink className="w-100" to={{
                                        pathname: `/user/edit/${e.id}`,
                                    }}>Edit</NavLink>
                                </Dropdown.Item>
                                <Dropdown.Item eventKey="3">
                                    {e.isEnabled ? 'Disable' : 'Enable'}
                                </Dropdown.Item>
                            </DropdownButton>
                        </td>
                    </tr>
                ))}
                </tbody>
                {users.length < 15 && !!users.length && (
                    <div className='h-180px'></div>
                )}
            </Table>
        </div>
    )
}
