import { Route, Switch} from "react-router-dom";
import React from "react";
import {BlogList} from "./components/BlogList";
import {BlogForm} from "./components/BlogForm";

export function BlogRoutes() {

    return (
        <Switch>
            <Route exact path="/blog" component={BlogList}/>
            <Route path="/blog/edit" component={BlogForm}/>
            <Route path="/blog/create" component={BlogForm}/>
        </Switch>
    );
}
