import React, {useEffect, useState} from 'react';
import {ITabs} from './AmbassadorList';
import {TabSwitch} from '../../../shared/components/TabSwitch';
import {Button, Table} from 'react-bootstrap';
import {Pagination} from '../../../shared/components/Pagination';
import {AmbassadorService} from '../AmbassadorService';
import {toast} from 'react-toastify';
import {AMBASSADOR_PAID, AMBASSADOR_REJECT} from '../../../enum/Messages';
import {Invoices} from '../../../model/invoice/Invoices';

export function AmbassadorInvoice() {
    const [ambassador, setAmbassador] = useState<Invoices[]>([])
    const [activeTab, setActiveTab] = useState("tab1")
    const [totalPages, setTotalPages] = useState(0);
    const [page, setPage] = useState(1);

    const [state, setState] = useState({
        page:1,
        perPage: 15,
        status: "invoiced"
    })

    const tabs: ITabs = {
        tab1: 'invoiced',
        tab2: 'paid',
        tab3: 'rejected',
    };

    useEffect(()=>{
        setState({...state, status: tabs[activeTab]})
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[activeTab])

    useEffect(()=>{
        AmbassadorService.getInvoices(state)
            .then((data)=>{
                setAmbassador(data.data)
                setTotalPages(Math.ceil(data.count / state.perPage))
            }).catch((err) => {
            toast.error(`${err.response.data.message}`)
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    },[JSON.stringify(state)])

    function updateInvoice(id: number, type: string) {
        AmbassadorService.updateInvoices(id, type)
            .then(data=>{
                type === "rejected" ? toast(AMBASSADOR_REJECT) : toast(AMBASSADOR_PAID)
            }).catch(err=>{
            toast.error(`${err.response.data.message}`)
        })
    }

    const handlePages = (updatePage: number) => {
        setState({...state, page: updatePage})
        setPage(updatePage)
    };

    return(

        <>
            <div className="card card-custom mb-5 p-4 mt-3">
                <TabSwitch
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    tabName={'Invoiced'}
                    tabName2={"Paid"}
                    tabName3={"Rejected"}
                />

                <Table hover striped responsive>
                    <thead>
                    <tr>
                        <th>#</th>
                        <th>Ambassador</th>
                        <th>Status</th>
                        <th>Amount</th>
                        <th>Date</th>
                        <th>Actions</th>
                    </tr>
                    </thead>
                    <tbody>
                    {ambassador?.map((e)=>(
                        <tr key={e.id}>
                            <td>{e.number}</td>
                            <td>{e.user.displayName}</td>
                            <td>{e.status}</td>
                            <td>{e.earnings}</td>
                            <td>{e.createdAt}</td>
                            <td>
                                { activeTab === "tab1" ?
                                    <div>
                                        <Button className="mr-1" onClick={() => updateInvoice(e.id ?? 0, "paid")}>
                                            Paid
                                        </Button>
                                        <Button variant="danger" onClick={() => updateInvoice(e.id ?? 0, "rejected")}>
                                            Reject
                                        </Button>
                                    </div> : ""
                                }
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                <div className="w-100">
                    { totalPages <= 1 ? '' :
                        <Pagination
                            page={page}
                            totalPages={totalPages}
                            handlePagination={handlePages}
                        />
                    }
                </div>
            </div>

        </>
    )
}
