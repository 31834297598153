import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Table, Row, Spinner} from 'react-bootstrap';
import {EMAILING_BODY, EMAILING_SUB, SENT_EMAIL} from '../../../enum/Messages';
import {useHistory, useLocation} from 'react-router';
import {CampaignEmailingService} from './CampaignEmailingService';
import {EmailRequest} from '../../../model/email/EmailRequest';
import {toast} from 'react-toastify';
import {ICampaignEmailing} from "../../../model/campaign/ICampaignEmailing";
import {ImpersonateEye} from "../../../shared/components/ImpersonateEye";
import {formatDate} from "../../../utils/Functions";

export function CampaignEmailingForm() {
    const {state: {id}}: any = useLocation();
    const [user, setUser] = useState<ICampaignEmailing>({de:[] ,en:[], sentLanguages:[], sentData:[]});
    const [disableBtn, setDisableBtn] = useState<boolean>(false);
    const [arrLanguages, setArrLanguages] = useState<string[]>([]);

    const history = useHistory();
    const [email, setEmail] = useState<EmailRequest>( {
        text: EMAILING_BODY.english,
        title: EMAILING_SUB.english,
        locale: 'en',
    })
    const [conditionEmail, setConditionEmail] = useState<{disabled: boolean, text: string, locale: string, loading: boolean}>({
        disabled: false,
        text: 'Send',
        locale: '',
        loading: false,
    });

    useEffect(() => {
        CampaignEmailingService.getCampaignEmailing(id)
            .then((data)=>{
                setUser(data);
                setArrLanguages(data.sentLanguages);
        }).catch((err)=>{

        })
    },[email])

    function doSendEmail(){
        setDisableBtn(true);
        setConditionEmail((prev) => ({...prev, locale: email.locale, disabled: true, loading: true, text: "Sending"}));
        CampaignEmailingService.sendEmails(id, email)
            .then((data)=>{
                toast(SENT_EMAIL);
                setConditionEmail((prev) => ({...prev, loading: false, text: "Sent"}));
                history.push('/campaign/emailing');
            }).catch(err=>{
            toast.error(`${err.data.message}`)
        })
    }

    const handleChanges = (evt: any) => {
        const value = evt.target.value;
        setEmail({
            ...email,
            [evt.target.name]: value
        })
    }

    const localeIndex = user.sentData.map(data => data.locale).indexOf(email.locale);

    return (
        <>
            <Row>
                <Col>
                    <Button className={`w-100 btn-text ${email.text !== EMAILING_BODY.english ? 'btn-outline-primary' : 'btn-primary'}`} 
                            onClick={() => {
                                setEmail({title: EMAILING_SUB.english, text: EMAILING_BODY.english, locale: 'en'});
                                // setConditionEmail((prev) => ({...prev, loading:false}))
                            }}>
                        English
                    </Button>
                </Col>
                <Col>
                    <Button className={`w-100 btn-text ${email.text !== EMAILING_BODY.german ? 'btn-outline-primary' : 'btn-primary'}`} 
                            onClick={() => {
                                setEmail({title: EMAILING_SUB.german, text: EMAILING_BODY.german, locale: 'de'})
                            }}>
                        German
                    </Button>
                </Col>
            </Row>
            <div className="card card-custom mb-5 p-4 mt-3">
                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Subject</Form.Label>
                        <Form.Control onChange={handleChanges} className="text-black-50" name="title" value={email.title} />
                    </Form.Group>
                </Form.Row>

                <Form.Row>
                    <Form.Group as={Col}>
                        <Form.Label>Body</Form.Label>
                        <Form.Control onChange={handleChanges} className="text-black-50" name="text" as="textarea" rows={20} value={email.text} />
                    </Form.Group>
                </Form.Row>
                <span className='text-muted'>sent at: {localeIndex >= 0 ? formatDate(user?.sentData[localeIndex]?.createdAt) : 'not yet sent'}</span>

              <div className="d-flex align-items-center">
                <Button disabled={disableBtn ? true : (arrLanguages.includes(email.locale))} onClick={doSendEmail} size='lg' variant="primary" type="submit" className="col-md-2 mb-3 mt-3 d-flex align-items-center justify-content-center" >
                    {conditionEmail.loading && (
                        <Spinner className='mr-2' animation="border" variant="light" size='sm' />
                    )}
                    {arrLanguages.includes(email.locale) && !conditionEmail.loading ? "Sent" : conditionEmail.text} ({user[email.locale].length ?? 0 })
                </Button>
                {!!conditionEmail.loading && conditionEmail.locale !== email.locale && (
                    <div className='ml-3'>
                        {email.locale === 'de' ? 'Sending English emails... Please wait.' : 'Sending German emails... Please wait.'}
                    </div>
                )}
              </div>

                <Table hover responsive >
                    <thead>
                        <tr>
                            <th>ID</th>
                            <th>Display name</th>
                            <th>Email</th>
                        </tr>
                    </thead>
                    <tbody>
                    {user[email.locale].map((e)=>(
                        <tr key={e.id}>
                            <td>{e.id}</td>
                            <td>{e.displayName}</td>
                            <td><ImpersonateEye id={e.id ?? 0}/>{e.email}</td>
                        </tr>
                    ))}
                    </tbody>
                </Table>

            </div>
        </>
    )
}
