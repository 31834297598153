import React from 'react';

export interface Tabs {
    tabName: string;
    tabName2: string,
    tabName3: string,
    setActiveTab: (tabs: string) => void,
    activeTab: string
}

export const TabSwitch: React.FC<Tabs> = ({
        tabName= "",
        tabName2="",
        tabName3="",
        setActiveTab,
        activeTab

}) => {

     const tabs = {
        tab1: "tab1",
        tab2: "tab2",
        tab3: "tab3",
    };


    return (
        <div className="card-toolbar mb-3">
            <ul className="nav nav-pills nav-pills-sm nav-dark-75">
                <li className="nav-item">
                    <span
                        className={`nav-link py-2 px-4 ${activeTab === tabs.tab1 &&
                        "active"}`}
                        data-toggle="tab"
                        onClick={() => setActiveTab(tabs.tab1)}
                    >
                        {tabName}
                    </span>
                </li>
                <li className="nav-item">
                    <span
                        className={`nav-link py-2 px-4 ${activeTab === tabs.tab2 &&
                        "active"}`}
                        data-toggle="tab"
                        onClick={() => setActiveTab(tabs.tab2)}
                    >
                        {tabName2}
                    </span>
                </li>
                <li className="nav-item">
                    <span
                        className={`nav-link py-2 px-4 ${activeTab === tabs.tab3 &&
                        "active"}`}
                        data-toggle="tab"
                        onClick={() => setActiveTab(tabs.tab3)}
                    >
                        {tabName3}
                    </span>
                </li>
            </ul>
        </div>
    )
}