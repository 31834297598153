import React, {useEffect, useState} from 'react';
import {Button, Col, Form, Row} from 'react-bootstrap';
import {useSelector} from 'react-redux';
import { getCorrectEnums } from '../../../utils/Functions';
import Autocomplete from "react-autocomplete";
import {UserService} from "../UserService";
import {toast} from "react-toastify";
import {User} from "../../../model/user/User";
import {getCountryName} from "../../../helpers/CorrectCountryNames";

export function UserFilter({handleChanges, handleClicks, allUsers}: any) {
    const {userType, country, gender, campaignCategory} = useSelector((state: any) => state.enums)
    const [agenciesParams, setAgenciesParams] = useState('');
    const [agencies, setAgencies] = useState<User[]>([]);

    useEffect(() => {
        UserService.getAgencies({displayName: agenciesParams, perPage: 10000}).then((data) => {
            setAgencies(data.data);
        }).catch((err) => {
            toast.error(`${err.message}`);
        });
    }, [agenciesParams])

    return (
        <Form>
            <Row style={{rowGap: '18px'}}>
                <div className="col-md-2 col-6">
                    <Form.Control placeholder="User id" className="form-control-solid" name="id"
                                  onChange={handleChanges}/>
                </div>
                <div className="col-md-2 col-6">
                    <Form.Control placeholder="name,email,handle" className="form-control-solid" name="search"
                                  onChange={handleChanges}/>
                </div>
                {/*<div className="col-md-2 col-6">*/}
                {/*    <Form.Control placeholder="Display name" className="form-control-solid" name="displayName"*/}
                {/*                  onChange={handleChanges}/>*/}
                {/*</div>*/}
                {/*<div className="col-md-2 col-6">*/}
                {/*    <Form.Control placeholder="Email" className="form-control-solid" name="email"*/}
                {/*                  onChange={handleChanges}/>*/}
                {/*</div>*/}
                {/*<div className="col-md-2 col-6">*/}
                {/*    <Form.Control placeholder="First name" className="form-control-solid" name="firstName"*/}
                {/*                  onChange={handleChanges}/>*/}
                {/*</div>*/}
                {/*<div className="col-md-2 col-6">*/}
                {/*    <Form.Control placeholder="Last name" className="form-control-solid" name="lastName"*/}
                {/*                  onChange={handleChanges}/>*/}
                {/*</div>*/}
                {/*<div className="col-md-2 col-6">*/}
                {/*    <Form.Control placeholder="Handle" className="form-control-solid" name="handle"*/}
                {/*                  onChange={handleChanges}/>*/}
                {/*</div>*/}
                {allUsers && <div className="col-md-2 col-6">
                    <Form.Control placeholder="Type" className="form-control-solid" name="userType"
                                  onChange={handleChanges} as="select">
                        <option value="">All types</option>
                        {userType.map((type: string) => <option key={type}
                                                                value={type}>{getCorrectEnums(type)}</option>)}
                    </Form.Control>
                </div>}
                <div className="col-md-2 col-6">
                    <Form.Control placeholder="Type" className="form-control-solid" name="isEnabled"
                                  onChange={(e) => handleChanges(e, true)} as="select">
                        <option value="null">is Enabled?</option>
                        <option value="enable">Enabled</option>
                        <option value="disable">Disabled</option>
                    </Form.Control>
                </div>
                <div className="col-md-2 col-6">
                    <Form.Control placeholder="Type" className="form-control-solid" name="isSuspended"
                                  onChange={(e) => handleChanges(e, true)} as="select">
                        <option value="null">is Suspended?</option>
                        <option value="enable">Suspended</option>
                        <option value="disable">Without suspension</option>
                    </Form.Control>
                </div>
                {allUsers && <div className="col-md-2 col-6">
                    <Form.Control placeholder="Type" className="form-control-solid" name="isSupervisor"
                                  onChange={(e) => handleChanges(e, true)} as="select">
                        <option value="null">is Supervisor?</option>
                        <option value="enable">Yes</option>
                        <option value="disable">No</option>
                    </Form.Control>
                </div>}
                <div className="col-md-2 col-6">
                    <Form.Control placeholder="Type" className="form-control-solid" name="country"
                                  onChange={handleChanges} as="select">
                        <option value="">All countries</option>
                        {country.map((type: string) => <option key={type}
                                                               value={type}>{getCountryName(type)}</option>)}
                    </Form.Control>
                </div>
                <div className="col-md-2 col-6">
                    <Form.Control placeholder="Type" className="form-control-solid" name="isOnboarded"
                                  onChange={(e) => handleChanges(e, true)} as="select">
                        <option value="null">is Onboarded?</option>
                        <option value="enable">Onboarded</option>
                        <option value="disable">NOT Onboarded</option>
                    </Form.Control>
                </div>
                <div className="col-md-2 col-6">
                    <Form.Control placeholder="Type" className="form-control-solid" name="gender"
                                  onChange={handleChanges} as="select">
                        <option value="">All genders</option>
                        {gender.map((type: string) => <option key={type}
                                                              value={type}>{getCorrectEnums(type)}</option>)}
                    </Form.Control>
                </div>
                <div className="col-md-2 col-6">
                    <Form.Control placeholder="Type" className="form-control-solid" name="campaignCategory"
                                  onChange={handleChanges} as="select">
                        <option value="">All Categories</option>
                        {campaignCategory.map((type: string) => <option key={type}
                                                                        value={type}>{getCorrectEnums(type)}</option>)}
                    </Form.Control>
                </div>
                <Form.Group as={Col} controlId="formGridState" className='col-4 mb-0 autocomplete-input form-control-solid zindex-5 position-relative d-flex flex-column'>
                    <Autocomplete
                        items={agencies}
                        inputProps={{placeholder: 'Agency'}}
                        getItemValue={(item) => item?.displayName ?? '-'}
                        value={agenciesParams ?? ''}
                        renderItem={(item) =>
                            <div className='autocomplete-item' key={item.id}>
                                {item.displayName ?? '-'}
                            </div>
                        }
                        onChange={(e) => {
                            setAgenciesParams(e.target.value);
                            !e.target.value && handleChanges({target: {name: 'agencyCompanyId', value: undefined}});
                            
                        }}
                        onSelect={(_, agency) => {
                            handleChanges({target: {name: 'agencyCompanyId', value: ''+agency?.id}})
                            setAgenciesParams(_)
                        }}
                    />
                </Form.Group>
                {!allUsers && <Form.Group as={Col} className="col-md-2 col-6 mb-0 ">
                    <Form.Control
                        placeholder="Type"
                        className="form-control-solid"
                        name="sortCriteria"
                        onChange={handleChanges} as="select"
                    >
                        <option value='createdAt'>
                            Sort by Date created
                        </option>
                        <option value='lastLogin'>
                            Sort by Last login
                        </option>
                        {/*<option value='price'>*/}
                        {/*    Sort by Price*/}
                        {/*</option>*/}
                        <option value='allOffers'>
                            Sort by All Offers
                        </option>
                        <option value='campaigns'>
                            Sort by Campaigns
                        </option>
                    </Form.Control>
                </Form.Group>}
                {!allUsers && <Form.Group as={Col} className="col-md-2 col-6 mb-0 ">
                <Form.Control
                        placeholder="Type"
                        className="form-control-solid"
                        name="sortType"
                        onChange={handleChanges} as="select"
                    >
                        <option value='DESC'>
                            DESC
                        </option>
                        <option value='ASC'>
                            ASC
                        </option>
                    </Form.Control>
                </Form.Group>}
                {allUsers && <div className="col-md-2 col-6">
                    <Form.Control placeholder="Type" className="form-control-solid" name="hasMobileLogin"
                                  onChange={(e) => handleChanges(e, true)} as="select">
                        <option value="null">Has Mobile login?</option>
                        <option value="enable">Yes</option>
                        <option value="disable">No</option>
                    </Form.Control>
                </div>}
            </Row>
            <Button className="mt-4" variant="primary" onClick={() => handleClicks()}>
                Search
            </Button>
        </Form>
    );
}
