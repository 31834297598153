import {Route, Switch} from "react-router-dom";
import React from "react";
import {Reporting} from "./Reporting";
import {AdminAclEnum} from "../../enum/AdminAclEnum";
import {hasAccessRight} from "../../utils/Functions";

export function ReportingRoutes() {

    return (

        <Switch>
            {hasAccessRight(AdminAclEnum.REPORTING) &&
                <Route exact path="/report" component={Reporting}/>
            }
        </Switch>

    )
}