export function numberFormatter(num: number) {
    if (num >= 1000000000) {
        return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G';
    }
    if (num >= 1000000) {
        return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
        return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return Math.round(num);
}

export function priceSplitter(number: number) {
    return number && number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.');
}
