import React, { useState, useEffect } from "react";
import {useHistory} from 'react-router';
import { Button, Card, Table } from "react-bootstrap";
import { Pagination } from "../../../shared/components/Pagination";
import { NavLink } from "react-router-dom";
import { AccessRightService } from "../AccessRightService";
import { toast } from "react-toastify";
import { IAccessRights } from "../../../model/access-right/IAccessRight";
import { FaPlus } from "react-icons/fa";
import { getCorrectEnums } from "../../../utils/Functions";
import { SuccessToast } from "../../../utils/toasters";

export default function AccessRight() {
    const history = useHistory();
    const [totalPages, setTotalPages] = useState<number>(0);
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 15,
    })
    const [accessRightsList, setAccessRightsList] = useState<IAccessRights[]>([]);
    
    const handlePages = (updatePage: number) => {
        setPagination({...pagination, page: updatePage})
    };

    useEffect(() => {
        AccessRightService.getAccessRights(pagination)
        .then((data) => {
            setAccessRightsList(data.data);
            setTotalPages(Math.ceil(data.count / pagination.perPage))
        })
        .catch((err) => {
            toast.error(`${err?.response?.data?.message}`)
        });
    }, [pagination])

    const deleteAccessRight = (id: number) => {
        AccessRightService.deleteAccessRight(id)
            .then((data) => {
                SuccessToast('Successfully deleted');
                setPagination((prev) => ({...prev, page:1}));
            })
            .catch((err) => {
                toast.error(`${err?.response?.data?.message}`)
            });
    }
    
  return (
    <div>
        <Button className="d-flex align-items-center" variant="outline-primary" onClick={() => history.push('/access-right/form')}>
            <FaPlus className="mr-1"/> 
            <div>Create Access Right</div>
        </Button>
    
        <Card className="mb-5 p-4 mt-5">
            <Table hover responsive>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th className="text-right">Action</th>
                    </tr>
                </thead>
                <tbody>
                {accessRightsList.map((item) => (
                    <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{getCorrectEnums(item.name)}</td>
                        <td>
                            <NavLink className="btn btn-primary mr-3" to={{
                                    pathname: '/access-right/form/'+item.id,
                                }}>Edit
                            </NavLink>
                            <Button variant="danger" onClick={() => deleteAccessRight(item.id)}>Delete</Button>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <div className="w-100">
                {totalPages > 1 &&
                    <Pagination
                        page={pagination.page}
                        totalPages={totalPages}
                        handlePagination={handlePages}
                    />
                }
            </div>
        </Card>
    </div>
    );
}
