import {Route, Switch} from "react-router-dom";
import React from "react";
import {CompanyForm} from './components/CompanyForm';
import {CompanyList} from './components/CompanyList';
import {CompanyUsers} from "./components/CompanyUsers";
import {hasAccessRight} from "../../utils/Functions";
import {AdminAclEnum} from "../../enum/AdminAclEnum";

export function CompanyRoutes() {

    return (
        <Switch>
            {hasAccessRight(AdminAclEnum.COMPANY) &&
                <>
                    <Route exact path="/company" component={CompanyList}/>
                    <Route path="/company/edit" component={CompanyForm}/>
                    <Route path="/company/create" component={CompanyForm}/>
                    <Route path="/company/users/:id" component={CompanyUsers}/>
                </>
            }
        </Switch>
    );
}
