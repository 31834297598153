import { createSlice } from '@reduxjs/toolkit'

export interface IConfirmModal {
    show: boolean,
    title: string,
    text: string | HTMLDivElement,
    action: () => void,
}

interface IGeneralSlice {
    confirmModal: IConfirmModal,
}

const initialGeneral: IGeneralSlice = {
    confirmModal: {
        show: false,
        title: '',
        text: '',
        action: () => {},
    }
}

const generalSlice = createSlice({
    name: 'general',
    initialState: initialGeneral,

    reducers: {
        setConfirmModal: (state, action) => {
            state.confirmModal = action.payload
            return state
        },
    },
});

export default generalSlice
