import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router';
import {Button, Card, Table} from 'react-bootstrap';
import { Pagination } from '../../../shared/components/Pagination';
import { NavLink } from 'react-router-dom';
import { IPackages } from '../../../model/package/IPackage';
import { PackagesService } from '../PackagesService';
import {toast} from 'react-toastify';

export default function PackagesList() {
    const history = useHistory();
    const [pagination, setPagination] = useState({
        page: 1,
        perPage: 15,
    })
    const [totalPages, setTotalPages] = useState(0);
    const [packagesList, setPackagesList] = useState<Partial <IPackages>[]>([])

    useEffect(() => {
        PackagesService.getPackages(pagination)
            .then((data) => {
                setPackagesList(data.data);
                setTotalPages(Math.ceil(data.count / pagination.perPage))
            })
            .catch((err) => {
                toast.error(`${err?.response?.data?.message}`)
            });
    }, [pagination])

    const handlePages = (updatePage: number) => {
        setPagination({...pagination, page: updatePage})
    };

    function handleActiveCheckbox(id: number) {
        PackagesService.togglePackage(id);
        const data = packagesList.map((e) => {
            if(e.id === id) return {...e, isActive: !e.isActive}
            return e
        });
        setPackagesList(data);
    }

    function handleShownCheckbox(id: number) {
        PackagesService.togglePackageShow(id);
        const data = packagesList.map((e) => {
            if(e.id === id) return {...e, isShownToUser: !e.isShownToUser}
            return e
        });
        setPackagesList(data);
    }

  return (
    <div>
        <Button variant="outline-primary" onClick={() => history.push(`/packages/create`, {id: undefined, isEdit: false})}>
            + Add package
        </Button>

        <Card className="mb-5 p-4 mt-5">
            <Table hover responsive>
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Name</th>
                        <th>Key</th>
                        <th>Monthly Price</th>
                        <th>Annual Price</th>
                        <th>Position</th>
                        <th>Active</th>
                        <th>Shown</th>
                        <th>Image</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                {packagesList.map((item) => (
                    <tr key={item.id}>
                        <td>{item.id}</td>
                        <td>{item.displayName}</td>
                        <td>{item.name}</td>
                        <td>{item.monthlyPrice}</td>
                        <td>{item.annualPrice}</td>
                        <td>{item.position}</td>
                        <td>
                            <div className="d-flex align-items-center">
                                <input className='form-check-input' type="checkbox" name="isActive"
                                       onChange={(event) => {
                                           handleActiveCheckbox(item.id!)
                                       }}
                                       checked={item.isActive}
                                       data-toggle="toggle"
                                />
                                <p className="m-0 pl-2">{item.isActive ? 'Active' : 'Inactive'}</p>
                            </div>
                        </td>
                        <td>
                            <div className="d-flex align-items-center">
                                <input className='form-check-input' type="checkbox" name="isShownToUser"
                                       onChange={(event) => {
                                           handleShownCheckbox(item.id!)
                                       }}
                                       checked={item.isShownToUser}
                                       data-toggle="toggle"
                                />
                                <p className="m-0 pl-2">{item.isShownToUser ? 'Shown' : 'Hidden'}</p>
                            </div>
                        </td>
                        <td>
                            <img alt='' src={item.image ? item?.image?.thumbnail : "/images/placeholder.png"}
                                 style={{width: 70, height: 60}} className="rounded"/>
                        </td>
                        <td>
                            <NavLink className="btn btn-primary w-100 text-white" to={{
                                pathname: '/packages/create',
                                state: {id: item.id, isEdit: true}
                            }}>Edit
                            </NavLink>
                        </td>
                    </tr>
                ))}
                </tbody>
            </Table>
            <div className="w-100">
                {totalPages <= 1 ? '' :
                    <Pagination
                        page={pagination.page}
                        totalPages={totalPages}
                        handlePagination={handlePages}
                    />
                }
            </div>
        </Card>
    </div>
  )
}
