import React, { ReactElement } from 'react'
import { Pagination } from '../../components/Pagination';
import EmptyState from '../../components/EmptyState';
import { Card } from 'react-bootstrap';

interface ITableLayout {
    children: ReactElement;
    pagination: {
        page: number;
        totalPages: number;
    };
    handlePages: (args: number) => void;
    listLength: number;
}

export default function TableLayout({children, pagination, handlePages, listLength}: ITableLayout) {
  return (
    <Card className="mb-5 mt-5">
        <Card.Body>
            {!!listLength && children}

            {!listLength && (
                <EmptyState classHolder='shadow-none' />
            )}
            
            {pagination.totalPages > 1 && (
                <Pagination
                    page={pagination.page}
                    totalPages={pagination.totalPages}
                    handlePagination={handlePages}
                />
            )}
        </Card.Body>
    </Card>
  )
}
