import {Route, Switch} from "react-router-dom";
import React from "react";
import {AmbassadorList} from "./components/AmbassadorList";
import {AmbassadorInvoice} from "./components/AmbassadorInvoice";
import {ReferredCampaign} from "./components/ReferredCampaign";
import {FormReferredCampaign} from "./components/FormReferredCampaign";

export function AmbassadorRoutes() {
    return (
        <Switch>
            <Route exact path="/ambassador/ambassadors" component={AmbassadorList}/>
            <Route path="/ambassador/invoices" component={AmbassadorInvoice} />
            <Route path="/ambassador/campaign" component={ReferredCampaign} />
            <Route path="/ambassador/campaigns/create" component={FormReferredCampaign} />
        </Switch>
    );
}
