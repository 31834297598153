import {Route, Switch} from "react-router-dom";
import React from "react";
import {hasAccessRight} from "../../utils/Functions";
import {AdminAclEnum} from "../../enum/AdminAclEnum";
import {ContractList} from "./components/ContractList";
import {ContractEdit} from "./components/ContractEdit";

export function ContractRoutes() {
    return (
        <Switch>
            {hasAccessRight(AdminAclEnum.CONTRACTS) &&
                <>
                    <Route exact path="/contracts" component={ContractList}/>
                    <Route path="/contracts/edit" component={ContractEdit}/>
                </>
            }
        </Switch>
    )
}
